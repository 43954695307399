<template>
    <form class="form" @submit.prevent="handleSubmit">
        <div class="form__group">
            <div class="form-control">
                <input
                    type="text"
                    class="form-control__control"
                    data-action="form-control"
                    autocomplete="off"
                    title="Введите Имя"
                    :class="{
                        error:
                            v$.userData.name.$invalid &&
                            v$.userData.name.$dirty,
                        'is-valued': !!userData.name
                    }"
                    v-model="userData.name"
                />
                <div class="form-control__label">Имя</div>
                <div
                    v-for="error of v$.userData.name.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
        </div>
        <div class="form__group">
            <div class="form-control">
                <input
                    type="text"
                    class="form-control__control"
                    data-action="form-control"
                    autocomplete="off"
                    title="Введите Фамилию"
                    :class="{
                        error:
                            v$.userData.surname.$invalid &&
                            v$.userData.surname.$dirty,
                        'is-valued': !!userData.surname
                    }"
                    v-model="userData.surname"
                />
                <div class="form-control__label">Фамилия</div>
                <div
                    v-for="error of v$.userData.surname.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
        </div>
        <div class="form__group">
            <div class="form-control">
                <input
                    type="text"
                    class="form-control__control"
                    data-action="form-control"
                    :class="{
                        error:
                            v$.userData.email.$invalid &&
                            v$.userData.email.$dirty,
                        'is-valued': !!userData.email
                    }"
                    autocomplete="off"
                    :disabled="showEmail"
                    v-model="userData.email"
                />
                <div class="form-control__label">Email</div>
                <div
                    v-for="error of v$.userData.email.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
        </div>
        <div class="form__group">
            <div class="form-control" v-if="!!userData.city?.full_name">
                <input
                    type="text"
                    name="city"
                    disabled
                    class="form-control__control disabled"
                    :class="{ 'is-valued': !!userData.city?.full_name }"
                    data-action="form-control"
                    autocomplete="off"
                    :value="userData.city?.full_name"
                    title="Укажите город"
                />
                <div class="form-control__label">Местоположение</div>
            </div>
            <div
                class="form-control"
                v-else
                :class="{
                    error:
                        v$.userData.city?.$invalid && v$.userData.city?.$dirty
                }"
            >
                <city-input v-model="userData.city"></city-input>
                <div
                    v-for="error of v$.userData.city.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
        </div>
        <div class="form__group">
            <div class="form-control">
                <VueTelInput
                    v-model="userData.phone"
                    :inputOptions="{
                        showDialCode: true
                    }"
                    :dropdownOptions="{
                        showSearchBox: true,
                        showFlags: true
                    }"
                    styleClasses="phone-input"
                />
                <div
                    v-for="error of v$.userData.phone.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
            <div class="form__extra-text">
                Телефон необходим для обратной связи с вами при заказе услуг и
                товаров на LAPKI.CLUB.
            </div>
        </div>
        <div class="form__group">
            <div class="form-control">
                <input
                    name="birth_date"
                    type="text"
                    v-model="userData.birth_date"
                    v-maska="date"
                    data-maska="##.##.####"
                    class="form-control__control"
                    :class="{
                        'is-valued': !!date.unmasked || !!userData.birth_date
                    }"
                    data-action="form-control"
                    autocomplete="off"
                    title="Дата рождения"
                />
                <!--                <imask-input-->
                <!--                    :value="userData.birth_date"-->
                <!--                    :mask="Date"-->
                <!--                    :lazy="false"-->
                <!--                    @accept:masked="onAcceptDate"-->
                <!--                    :class="{-->
                <!--                        error:-->
                <!--                            v$.userData.birth_date.$invalid &&-->
                <!--                            v$.userData.birth_date.$dirty,-->
                <!--                        'is-valued': !!userData.birth_date-->
                <!--                    }"-->
                <!--                    class="form-control__control"-->
                <!--                />-->

                <div class="form-control__label">Дата рождения</div>
                <div
                    v-for="error of v$.userData.birth_date.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
            <div class="form__extra-text">
                В день вашего рождения мы сможем вас приятно удивить.
            </div>
        </div>
        <div class="form__group">
            <div class="form-control">
                <textarea
                    class="form-control__control"
                    :class="{
                        'is-valued': !!userData.about_me,
                        error:
                            v$.userData.about_me.$invalid &&
                            v$.userData.about_me.$dirty
                    }"
                    data-action="form-control"
                    name="about_me"
                    v-model="userData.about_me"
                ></textarea>
                <div class="form-control__label">Обо мне</div>
                <div
                    v-for="error of v$.userData.about_me.$errors"
                    :key="error.$uid"
                    class="error"
                >
                    {{ error.$message }}
                </div>
            </div>
        </div>
        <div class="form__footer">
            <div class="form__controls">
                <button
                    type="submit"
                    class="button"
                    :class="{ 'is-wait': loading }"
                >
                    Сохранить
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@/utils/i18n-validators'
import _ from 'lodash'
import VueMultiselect from 'vue-multiselect'
import CityInput from '@/components/Search/CityInput.vue'
import { vMaska } from 'maska'
import { reactive } from 'vue'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

export default {
    name: 'EditProfile',

    directives: { maska: vMaska },

    setup() {
        const phone = reactive({
            masked: '',
            unmasked: '',
            completed: false
        })
        const phoneOptions = reactive({
            postProcess: (val) => {
                val = val.replace(/^\+ 8 \(/g, '+ 7 (')
                return val.replace(/^\+ 9 \(/g, '+ 7 (9')
            },
            eager: true
        })
        const date = reactive({
            masked: '',
            unmasked: '',
            completed: false
        })

        return { v$: useVuelidate(), phone, phoneOptions, date }
    },

    props: {
        user: {
            required: true
        }
    },

    validations() {
        return {
            userData: {
                name: { required },
                email: { required },
                surname: { required },
                phone: { maxLength: maxLength(25) },
                city: { required },
                birth_date: { maxLength: maxLength(25) },
                about_me: { maxLength: maxLength(1000) }
            }
        }
    },

    data() {
        return {
            numberModel: '',
            userData: Object.assign({}, JSON.parse(this.user)),
            city_loading: false,
            cities: [],
            vuelidateExternalResults: {
                userData: {
                    phone: [],
                    birth_date: [],
                    about_me: []
                }
            },
            loading: false
        }
    },

    computed: {
        showEmail() {
            let initialUser = Object.assign({}, JSON.parse(this.user))
            return !!initialUser.email
        }
    },

    components: {
        VueMultiselect,
        CityInput,
        VueTelInput
    },

    methods: {
        onPhoneInput({ detail }) {
            console.log('onPhoneInput', detail)
            this.userData.phone = detail.unmasked
        },
        onBirthDateInput({ detail }) {
            this.userData.birth_date = detail.unmasked
        },
        // handlePhoneInput(number, phoneObject) {
        //     this.userData.phone = phoneObject.number
        // },
        async handleSubmit() {
            this.v$.$clearExternalResults()
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return
            }
            try {
                this.loading = true
                let params = {
                    name: this.userData.name,
                    email: this.userData.email,
                    surname: this.userData.surname,
                    birth_date: this.userData.birth_date,
                    phone: this.userData.phone,
                    about_me: this.userData.about_me
                }
                if (!this.userData.city?.full_name) {
                    params.city_id = this.userData.city.id
                }
                const { data } = await axios.post('/api/v1/profile', params)
                // console.log('data', data)
                this.$notify({
                    type: 'success',
                    title: 'Профиль успешно обновлен'
                })
                if (data.success) {
                    // document.location.reload()
                }
            } catch (err) {
                console.log('err', err)
                const errors = err.response?.data?.errors
                console.log('errors', errors)
                this.vuelidateExternalResults.userData = errors
            } finally {
                this.loading = false
            }
        },
        fetchCities(query) {
            this.city_loading = true
            axios
                .get('/api/v1/search/cities', {
                    params: query
                })
                .then((res) => {
                    this.cities = res.data?.data ?? []
                })
                .catch((err) => {
                    console.log('err', err)
                })
                .finally(() => {
                    this.city_loading = false
                })
        },
        handleSearch: _.debounce(function (query) {
            if (!query) {
                query = { popular: true }
            } else {
                query = { city: query }
            }
            this.fetchCities(query)
        }, 500)
    }
}
</script>

<style>
.phone-input {
    outline: none;
    box-shadow: none !important;
    border-radius: 6px;
    font-family: var(--font-main);
    font-weight: 500;
    line-height: 1.25rem;
    color: var(--Gray85);
    border: 1px solid var(--Gray20);
    position: relative;
}

.phone-input .vti__dropdown {
    padding: 19px 8px;
    border-radius: 6px 0 0 6px;
    position: unset;
}
.phone-input .vti__input {
    border-radius: 0 6px 6px 0;
    font-weight: 500;
}
.phone-input .vti__dropdown-list {
    top: 100%;
    margin-top: 4px;
    left: 0;
    width: 100%;
    border-radius: 6px;
}
.phone-input .vti__input {
    width: calc(100% - 4px);
    border-radius: 6px;
}
.phone-input.vue-tel-input:focus-within {
    border-color: var(--Base25);
}
</style>
