<template>

</template>

<script>
import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";

export default {
    name: "FirebaseCloudMessaging",

    methods: {
        requestPermission() {
            console.log('Requesting permission...');
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            })
        }
    },

    mounted() {
        console.log('Mounted FirebaseCloudMessaging')

        const firebaseConfig = {
            apiKey: "AIzaSyAX2QFZ-fw1elujeBtf8GzlOyMIlx2wFIU",
            authDomain: "lapki-club.firebaseapp.com",
            projectId: "lapki-club",
            storageBucket: "lapki-club.appspot.com",
            messagingSenderId: "946554576978",
            appId: "1:946554576978:web:1f8c623e3792eb2b05bf8b",
            measurementId: "G-9YF5ZS6NYZ"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);

        // Initialize Firebase Cloud Messaging and get a reference to the service
        const messaging = getMessaging(app);


// Add the public key generated from the console here.
        getToken(messaging, {vapidKey: "BJVm-Un0IOuX2YabeR_0ETkT7w4FHpn91duGz2BdogS5i0ceGaFg8gQNh73P1bjiDLY4b3yb_5lXX2zwSx-rU8k"}).then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log('currentToken:', currentToken);
                // ...
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
        });

        this.requestPermission()
    }
}
</script>
