<template>
    <div class="form__group">
        <Multiselect
            :model-value="modelValue"
            :options="options"
            @select="handleSelect"
            placeholder="День"
            :show-labels="false"
        />
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import Multiselect from 'vue-multiselect'

const props = defineProps({
    modelValue: {
        type: Number,
        required: false
    },
    options: {
        type: Array,
        default: () => {
            return Array.from({ length: 31 }, (v, k) => k + 1)
        }
    }
})

const { options, modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const handleSelect = (value) => {
    emit('update:modelValue', value)
}
</script>
