<template>
    <div :data-qnt="thumbs.length">
        <div
            class="org-gallery__slide swiper-slide"
            v-for="(photo, index) in thumbs"
            @click="showLightbox(index)"
            :key="photo.id"
        >
            <div class="org-gallery__pic">
                <img :src="photo.url" alt="" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import _ from 'lodash'

const props = defineProps({
    photos: {
        type: Array,
        default: () => []
    },
    video: {
        type: String
    }
})

const thumbs = computed(() => {
    return _.slice(props.photos, 0, 4)
})

const lightboxElements = computed(() => {
    const photos = props.photos.map((photo) => {
        return {
            href: photo.url,
            type: 'image'
        }
    })
    if (props.video) {
        return [
            {
                href: props.video,
                type: 'video',
                source: 'youtube' //vimeo, youtube or local
            },
            ...photos
        ]
    }
    return photos
})

const lightbox = GLightbox({
    elements: lightboxElements.value,
    autoplayVideos: true
})

const showLightbox = (index) => {
    lightbox.openAt(index + 1)
}
</script>

<style>
.org-gallery__wrapper {
    max-height: 580px;
}
</style>
