<template>
    <div class="chat">
        <div class="chat__inner" :class="{ 'chat-selected': roomSelected }">
            <div class="chat__side">
                <div class="chat__header">
                    <div class="chat__headline">Сообщения</div>
                </div>
                <div class="chat__tab">
                    <div class="tab-nav">
                        <button
                            class="tab-nav__button"
                            :class="{
                                'tab-nav__button_active':
                                    store.roomType === 'user'
                            }"
                            type="button"
                            @click="handleRoomTypeChange('user')"
                        >
                            Личные
                        </button>
                        <button
                            class="tab-nav__button"
                            :class="{
                                'tab-nav__button_active':
                                    store.roomType === 'business'
                            }"
                            type="button"
                            @click="handleRoomTypeChange('business')"
                        >
                            Заказы
                        </button>
                    </div>
                </div>
                <div class="chat__users">
                    <template v-if="!loading">
                        <template v-if="store.rooms.length">
                            <ChatRoomItem
                                :auth-id="props.authId"
                                v-for="room in store.rooms"
                                :key="room.id"
                                :room="room"
                            />
                        </template>
                        <div
                            v-else
                            class="specialty-item__text"
                            style="padding: 15px"
                        >
                            Сообщений пока нет
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="chat-user"
                            v-for="room in [1, 2, 3, 4, 5]"
                            :key="room"
                        >
                            <div class="chat-user__pic">
                                <Skeletor circle class="chat-user__img" />
                            </div>
                            <div class="chat-user__body">
                                <div
                                    class="chat-user__info"
                                    style="width: 100%"
                                >
                                    <div
                                        class="chat-user__name"
                                        style="width: 100%"
                                    >
                                        <Skeletor width="50%" />
                                    </div>
                                </div>
                                <div class="chat-user__text">
                                    <Skeletor width="100%" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="chat__main">
                <ChatRoom v-if="roomSelected" :auth-id="props.authId" />

                <div class="specialty-item" v-else>
                    <div class="specialty-item__pic">
                        <img
                            :src="'/img/special-cat4.svg'"
                            loading="eager"
                            alt="chat"
                        />
                    </div>
                    <div class="specialty-item__text">
                        Выберите чат для беседы
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ChatRoomItem from './ChatRoomItem.vue'
import ChatRoom from './ChatRoom.vue'
import { Skeletor } from 'vue-skeletor'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useChatStore } from '../../stores/ChatStore'

const props = defineProps({
    authId: {
        required: true
    },
    userId: {
        required: false,
        default: null
    },
    rooms: {
        type: String,
        default: '[]'
    }
})

const store = useChatStore()

const loading = ref(false)

const roomSelected = computed(() => {
    return !!props.userId || !!store.activeRoomId
})

const handleRoomTypeChange = (type = 'user') => {
    loading.value = true
    store.setRoomType(type).then(() => {
        loading.value = false
    })
}

onBeforeMount(() => {
    store.setRooms(JSON.parse(props.rooms))
})

const setActiveRoom = async () => {
    if (!props.userId) {
        return null
    }
    const current_room = store.rooms.find((room) =>
        room.user_ids?.includes(parseInt(props.userId))
    )

    if (!current_room) {
        const url = new URL(document.location.href)
        await store
            .createRoom(
                props.userId,
                url.searchParams.get('type') ?? store.roomType
            )
            .then((res) => {
                store.setActiveRoomId(res.id)
            })
    } else {
        await store.setActiveRoomId(current_room.id)
    }
}

onMounted(async () => {
    await setActiveRoom()
})
</script>
