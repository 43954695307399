<template>
    <div class="constructor__footer constructor__footer_no-sticky">
        <div class="constructor__controls">
            <div class="constructor__control">
                <button
                    type="button"
                    class="button button_bg_gray button_size_sm"
                    :disabled="props.loading"
                    @click="emit('onCancel')"
                >
                    Отменить
                </button>
            </div>
            <div class="constructor__control">
                <button
                    type="button"
                    class="button button_size_sm"
                    :disabled="props.loading"
                    @click="emit('onSubmit')"
                >
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['onSubmit', 'onCancel'])
const props = defineProps(['loading'])
</script>

<style>
.constructor__controls {
    gap: 16px;
}
</style>
