<template>
    <div v-if="notificationsData.length">
        <div class="notification-title">Новые заказы</div>
        <div class="notification-group">
            <div
                class="notification"
                v-for="notification in notificationsData"
                :key="notification.id"
            >
                <div class="notification__pic">
                    <a :href="`/users/${notification.sender.id}`">
                        <img
                            :src="
                                notification.sender.avatar ??
                                '/img/no-photo.png'
                            "
                            alt=""
                            class="notification__img-alt"
                        />
                    </a>
                </div>
                <div class="notification__body">
                    <div class="notification__info" v-if="notification.sender">
                        <a
                            :href="`/users/${notification.sender.id}`"
                            class="notification__name"
                            >{{ notification.sender.full_name }}</a
                        >
                        <div class="notification__text">
                            {{ notification.sender.city }}
                        </div>
                    </div>
                </div>
                <div class="notification__footer">
                    <a
                        :href="`/business/${notification.order.business_id}/edit/orders`"
                        class="button button_size_sm"
                        :class="{ 'is-wait': loading }"
                        @click="handleRead(notification.id)"
                    >
                        Чат
                    </a>
                    <button
                        type="button"
                        @click="handleDelete(notification.id)"
                        class="button button_bg_secondary button_circle notification__hide"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { notificationsMixin } from '@/components/Notifications/NotificationsMixin.js'

export default {
    name: 'OrderNotifications',

    mixins: [notificationsMixin]
}
</script>

<style scoped>
.notification-group {
    overflow: auto;
    max-height: 200px;
}
</style>
