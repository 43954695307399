export const notificationsMixin = {
    props: ['notifications'],

    data() {
        return {
            loading: false,
            notificationsData: this.notifications
        }
    },

    watch: {
        notifications: {
            deep: true,
            handler(val) {
                console.log('data val', val)
                this.notificationsData = val
            }
        }
    },

    methods: {
        handleDelete(notification_id) {
            if (this.loading) {
                return
            }
            this.loading = true
            axios
                .post('/api/v1/notifications/destroy', {
                    id: notification_id
                })
                .then((res) => {
                    if (res.data?.success) {
                        this.$emit('update')
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleRead(notification_id) {
            if (this.loading) {
                return
            }
            this.loading = true
            axios
                .post(`/api/v1/notifications/${notification_id}`)
                .then((res) => {
                    if (res.data?.success) {
                        this.$emit('update')
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleClose(notification) {
            if (!notification) {
                return
            }
            if (notification.read_at) {
                console.log('dd')
                // this.handleDelete(notification.id)
            } else {
                this.handleRead(notification.id)
            }
        }
    }
}
