<template>
    <VueFinalModal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="modal-container modal_short"
        content-class="modal__content"
        :click-to-close="false"
        :esc-to-close="true"
    >
        <div class="modal__bar">
            <button type="button" class="modal__close" @click="close">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal__header">
            <div class="modal__headline">Заказать услугу</div>
        </div>
        <div class="modal__body">
            <form class="service-booking">
                <div class="booking-card">
                    <!--                    <div class="booking-card__main">-->
                    <!--                        <div class="booking-card__pic">-->
                    <!--                            <img src="static/uploads/pet-up1.png" alt="" class="booking-card__img"/>-->
                    <!--                            <div class="booking-card__feature booking-card__feature_bg_orange">-->
                    <!--                                <i class="icon-grooming"></i>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                        <div class="booking-card__info">-->
                    <!--                            <div class="booking-card__name">Наименование</div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="booking-card__content">
                        <div class="booking-card__text">
                            Заказ услуги недоступен так как вы являетесь
                            владельцем данного бизнеса.
                        </div>
                        <div class="booking-card__contacts">
                            <div class="booking-card__value">
                                {{ props.phone }}
                            </div>
                        </div>
                        <div class="booking-card__information">
                            <div class="booking-card__title">
                                Или сделать это онлайн
                            </div>
                            <!--                            <div class="booking-card__description">-->
                            <!--                                <p>-->
                            <!--                                    <i class="icon-checked"></i>Получайте дополнительные балы в свой профиль-->
                            <!--                                </p>-->
                            <!--                                <p>-->
                            <!--                                    <i class="icon-checked"></i>Выберите мастера и удобное время - а мы покажем-->
                            <!--                                    доступные даты-->
                            <!--                                </p>-->
                            <!--                                <p>-->
                            <!--                                    <i class="icon-checked"></i>Напомним о предстоящем посещении мастера-->
                            <!--                                </p>-->
                            <!--                            </div>-->
                            <div class="button disabled">Заказать онлайн</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'
const props = defineProps(['phone', 'id'])
</script>

<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
</style>
