<template>
    <div v-if="notificationsData.length">
        <div class="notification-title">Запросы на добавления потомства</div>
        <div class="notification-group">
            <div
                class="notification"
                v-for="notification in notificationsData"
                :key="notification.id"
            >
                <div class="notification__pic notification__pic_wide">
                    <a
                        :href="`/users/${notification.data['pet_owner_id']}`"
                        v-if="notification.data['pet_owner_avatar']"
                    >
                        <img
                            :src="
                                notification.data['pet_owner_avatar'] ??
                                '/img/no-photo.png'
                            "
                            alt=""
                            class="notification__img-alt"
                        />
                    </a>
                    <a :href="`/pet/${notification.data['pet_id']}`">
                        <img
                            :src="notification.data['pet_avatar']"
                            alt=""
                            class="notification__img"
                        />
                    </a>
                </div>
                <div class="notification__body">
                    <div class="notification__info">
                        <a
                            :href="`/pet/${notification.data['pet_id']}`"
                            class="notification__name"
                            >{{ notification.data['pet_nickname'] }}</a
                        >
                        <div class="notification__text">
                            {{ notification.data['pet_age'] }}
                            <i
                                :class="`icon-sex_${notification.data['pet_sex']}`"
                            ></i>
                        </div>
                        <a
                            :href="`/users/${notification.data['pet_owner_id']}`"
                            class="notification__host"
                            v-if="notification.data['pet_owner_full_name']"
                        >
                            {{ notification.data['pet_owner_full_name'] }}
                        </a>
                    </div>
                </div>
                <div class="notification__extra">
                    <div class="notification__title">Потомство от</div>
                    <div class="notification__pet">
                        <a
                            :href="`/pet/${notification.data['parent_id']}`"
                            class="notification__pic notification__pic_wide"
                        >
                            <img
                                :src="notification.data['parent_avatar']"
                                v-if="notification.data['parent_avatar']"
                                alt=""
                                class="notification__img"
                            />
                        </a>
                        <div class="notification__info">
                            <a
                                :href="`/pet/${notification.data['parent_id']}`"
                                v-if="notification.data['parent_nickname']"
                                class="notification__name"
                                >{{ notification.data['parent_nickname'] }}
                            </a>
                            <div
                                v-if="notification.data['parent_age']"
                                class="notification__text"
                            >
                                {{ notification.data['parent_age'] }}
                                <i
                                    v-if="notification.data['parent_sex']"
                                    :class="`icon-sex_${notification.data['parent_sex']}`"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="notification__footer">
                    <template v-if="notification.data['status'] === 'accepted'">
                        <div class="notification__text">Запрос принят</div>
                        <button
                            type="button"
                            @click="handleDelete(notification.id)"
                            class="button button_bg_secondary button_circle notification__hide"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </template>
                    <template
                        v-else-if="notification.data['status'] === 'denied'"
                    >
                        <div class="notification__cancel">Запрос отклонен</div>
                        <button
                            type="button"
                            @click="handleDelete(notification.id)"
                            class="button button_bg_secondary button_circle notification__hide"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </template>

                    <template v-else>
                        <button
                            type="button"
                            class="button button_size_sm button_bg_gray confirm__parentship"
                            :class="{ 'is-wait': loading }"
                            @click="react('deny', notification)"
                        >
                            Отклонить
                        </button>
                        <button
                            type="button"
                            class="button button_size_sm confirm__parentship"
                            :class="{ 'is-wait': loading }"
                            @click="react('accept', notification)"
                        >
                            Подтвердить
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { notificationsMixin } from '@/components/Notifications/NotificationsMixin.js'

export default {
    name: 'ParentsNotifications',

    mixins: [notificationsMixin],

    methods: {
        react(reaction, notification) {
            axios
                .post(`/api/v1/pets/parentship/${reaction}`, {
                    notification_id: notification.id,
                    pet_id: notification.data['pet_id'],
                    pet_parent_id: notification.data['parent_id']
                })
                .then((res) => {
                    if (res.data?.success) {
                        this.$emit('update')
                    }
                })
        }
    }
}
</script>
