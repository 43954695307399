<template>
    <button type="button" class="button-link" @click="show">
        <i class="icon-trash"></i>Удалить достижение
    </button>
</template>

<script setup>
import {$vfm} from "vue-final-modal";
import ModalDeleteAchievement from "./ModalDeleteAchievement.vue";

const emit = defineEmits(['onDelete'])

const show = () => {
    $vfm.show({
        component: ModalDeleteAchievement,
        bind: {
            name: 'ModalDeleteAchievement',
        },
        on: {
            close() {
                $vfm.hide('ModalDeleteAchievement')
            },
            submit() {
                $vfm.hide('ModalDeleteAchievement')
                emit('onDelete')
            }
        }
    })
}
</script>
