<template>
    <div class="form__group">
        <div
            v-if="loading"
            class="form__status form__status_gray form__status_wait"
            style="background-color: var(--Gray10)"
        >
            <i class="icon-checked"></i>
        </div>
        <div class="form-control" v-if="!props.loading">
            <input
                type="text"
                class="form-control__control"
                :class="{
                    'is-valued': !!props.modelValue,
                    error: props.errorMessage
                }"
                @focus="activate"
                v-if="!active"
                :value="cityResource.label"
            />
            <input
                type="text"
                ref="searchInput"
                class="form-control__control"
                v-else
                @blur.prevent="deactivate"
                @keyup.esc="deactivate"
                @input="handleSearch($event.target.value)"
                @keydown="loading = true"
                v-model="query"
            />
            <ul class="autocomplete-result" v-if="query && !loading">
                <li
                    class="autocomplete-result__item"
                    v-for="item in options"
                    @click.stop="select(item)"
                    @mousedown.stop="select(item)"
                    :key="item.id"
                >
                    {{ item.name }}
                </li>
                <li
                    class="autocomplete-result__item autocomplete-result-info"
                    v-if="query.length && !options.length && !loading"
                >
                    <span class="autocomplete-result-info__meta"
                        >Ничего не найдено</span
                    >
                </li>
            </ul>
            <div class="form-control__label">{{ props.label }}</div>
            <div
                class="form__alert form__alert_error"
                v-if="props.errorMessage"
            >
                <i class="icon-details"></i> {{ props.errorMessage }}
            </div>
            <div
                class="form__extra-text"
                v-if="props.extraText"
                v-html="props.extraText"
            ></div>
        </div>
        <Skeletor v-else class="rounded" height="60" as="div"></Skeletor>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'
import { debounce } from 'lodash'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { Skeletor } from 'vue-skeletor'

const props = defineProps({
    modelValue: {
        type: [String, Number]
    },
    initialCity: {
        required: false
    },
    errorMessage: {
        type: String
    },
    label: {
        type: String
    },
    loading: {
        type: Boolean
    }
})

const cityResource = ref({
    id: null,
    label: ''
})

const query = ref('')
const options = ref([])

onMounted(() => {
    if (props.initialCity?.id && props.initialCity?.name) {
        cityResource.value.id = props.initialCity.id
        cityResource.value.label =
            props.initialCity.full_name ?? props.initialCity.name
        options.value.push(props.initialCity)
    }
})

const active = ref(false)
const searchInput = ref(null)
const activate = () => {
    active.value = true
    nextTick(() => {
        searchInput.value.focus()
    })
}
const deactivate = () => {
    nextTick(() => {
        active.value = false
        query.value = ''
        options.value = []
    })
}

const loading = ref(false)

const handleSearch = debounce(async (q) => {
    try {
        loading.value = true
        const {
            data: { data }
        } = await axios.get('/api/v1/search/cities', {
            params: {
                city: q
            }
        })
        options.value = data
        loading.value = false
    } catch (e) {
        notify({
            type: 'error',
            text: e.message
        })
    }
}, 400)

const emit = defineEmits(['update:modelValue'])
const select = (city) => {
    cityResource.value.id = city.id
    cityResource.value.label = city.name
    emit('update:modelValue', city.id)
}
</script>
