<template>
    <div class="constructor">
        <div class="constructor__side pt-90">
            <div class="constructor__scroll" data-action="burger-scroll">
                <div class="constructor-nav">
                    <nav class="navigation">
                        <div
                            class="navigation__item"
                            v-for="step in steps"
                            :key="step.id"
                        >
                            <a
                                :href="`/business/${store.initialValues?.id}/edit/${step.key}`"
                                class="constructor-nav__link"
                                :class="{ active: step.key === props.section }"
                            >
                                <span class="constructor-nav__name">{{
                                    step.label
                                }}</span>
                                <span class="constructor-nav__label">
                                    <i class="icon-checked"></i>
                                </span>
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="constructor__mobilebar">
            <div class="constructor__headline">Редактировать бизнес</div>
            <button
                type="button"
                class="constructor__toggle"
                data-action="burger-open"
            >
                <i class="icon-menu2"></i>
            </button>
        </div>
        <div class="constructor__main">
            <div class="constructor__inner">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useBusinessStore } from '../../../stores/BusinessStore'

const props = defineProps({
    section: {
        type: String,
        default: ''
    }
})

const store = useBusinessStore()

const steps = ref([
    {
        id: 1,
        key: 'about',
        label: 'Основная информация'
    },
    {
        id: 2,
        key: 'contacts',
        label: 'Контакты'
    },
    {
        id: 3,
        key: 'working_hours',
        label: 'Режим работы'
    },
    {
        id: 4,
        key: 'photo',
        label: 'Фото'
    },
    {
        id: 5,
        key: 'service_types',
        label: 'Виды деятельности'
    },
    {
        id: 6,
        key: 'services',
        label: 'Список услуг'
    }
    // {
    //     id: 7,
    //     label: 'Сотрудники'
    // },
    // {
    //     id: 8,
    //     label: 'Программа лояльности'
    // },
])
</script>

<style>
.pt-90 {
    padding-top: 90px;
}

.active {
    color: var(--Gray85);
}
</style>
