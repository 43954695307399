<template>
    <div class="form__group" v-if="!props.loading">
        <Multiselect
            ref="multiselect"
            :model-value="props.modelValue"
            :options="props.options.map((item) => item.id)"
            :custom-label="(option) => getLabel(option)"
            :close-on-select="props.closeOnSelect"
            :searchable="props.searchable"
            :class="{ checkboxes: props.checkboxes }"
            @select="handleInput"
            @remove="handleInput"
            :show-labels="false"
            :multiple="props.multiple"
            :placeholder="label"
        >
            <template #tag="{ option, remove }">
                <div
                    class="multiple__tag"
                    style="margin-right: 8px; margin-bottom: 8px"
                >
                    {{ getLabel(option) }}
                    <button
                        type="button"
                        class="multiple__remove"
                        @click.prevent="
                            props.removeConfirmation
                                ? handleRemove(option)
                                : remove(option)
                        "
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
            </template>
            <template #option="{ option }" v-if="props.checkboxes">
                <div class="multiple__item">
                    <label class="checkbox">
                        <span type="checkbox" class="checkbox__input"></span>
                        <span class="checkbox__mark"></span>
                        <span class="checkbox__text">{{
                            getLabel(option)
                        }}</span>
                    </label>
                </div>
            </template>
        </Multiselect>
        <div class="form__alert form__alert_error" v-if="props.errorMessage">
            <i class="icon-details"></i> {{ props.errorMessage }}
        </div>
        <div class="form__extra-text" v-if="extraText" v-html="extraText"></div>
    </div>
    <Skeletor v-else height="60" as="div"></Skeletor>
</template>

<script setup>
import Multiselect from 'vue-multiselect'
import { Skeletor } from 'vue-skeletor'
import { ref } from 'vue'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String
    },
    options: {
        type: Array,
        required: true,
        default: () => []
    },
    optionLabel: {
        type: String,
        default: 'name'
    },
    optionValue: {
        type: String,
        default: 'id'
    },
    multiple: {
        type: Boolean,
        default: true
    },
    checkboxes: {
        type: Boolean,
        default: false
    },
    closeOnSelect: {
        type: Boolean,
        default: true
    },
    searchable: {
        type: Boolean,
        default: true
    },
    extraText: {
        type: String
    },
    modelValue: {
        type: [String, Object, Number]
    },
    errorMessage: {
        type: String
    },
    loading: {
        type: Boolean
    },
    removeConfirmation: {
        type: Boolean,
        default: false
    }
})

const multiselect = ref()

const getLabel = (option) => {
    if (!props.options.length) {
        return ''
    }
    const id = option?.id ?? option
    return props.options.find((item) => item[props.optionValue] === id)[
        props.optionLabel
    ]
}

const emit = defineEmits(['update:modelValue', 'remove'])
const handleInput = (value) => {
    emit('update:modelValue', value)
}

const handleRemove = (option) => {
    emit('remove', option)
}
const remove = (option) => {
    multiselect.value.removeElement(option)
}
defineExpose({
    remove
})
</script>

<style>
.checkboxes .multiselect__element {
    margin-top: 0 !important;
}

.checkboxes .multiselect__option--highlight,
.checkboxes .multiselect__option--selected {
    background: transparent !important;
}

.checkboxes .multiselect__option--selected {
    background: transparent !important;
}

.checkboxes .multiselect__option--selected .checkbox__mark {
    background-color: var(--color);
    border-color: var(--color);
}

.checkboxes .multiselect__option--selected .checkbox__mark:after {
    opacity: 1 !important;
}
</style>
