<template>
    <div v-if="notificationsData.length">
        <div class="notification-title">Запросы на добавления в друзья</div>
        <div class="notification-group">
            <div
                class="notification"
                v-for="notification in notificationsData"
                :key="notification.id"
            >
                <a
                    :href="`/users/${notification.sender?.id}`"
                    class="notification__pic"
                >
                    <img
                        :src="
                            notification.sender?.avatar ?? '/img/no-photo.png'
                        "
                        :alt="notification.sender?.full_name"
                        class="notification__img"
                    />
                </a>
                <div class="notification__body">
                    <div class="notification__info" v-if="notification.sender">
                        <a
                            :href="`/users/${notification.sender?.id}`"
                            class="notification__name"
                            >{{ notification.sender?.full_name }}</a
                        >
                        <div class="notification__text">
                            {{ notification.sender?.city }}
                        </div>
                    </div>
                </div>
                <div class="notification__footer">
                    <template v-if="notification.status === 'accepted'">
                        <div class="notification__text">У вас в друзьях</div>
                        <button
                            type="button"
                            class="button button_bg_secondary button_circle notification__hide"
                            @click="handleDelete(notification.id)"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </template>
                    <template v-else-if="notification.status === 'denied'">
                        <div class="notification__cancel">Запрос отклонен</div>
                        <button
                            type="button"
                            class="button button_bg_secondary button_circle notification__hide"
                            @click="handleDelete(notification.id)"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </template>
                    <template v-else>
                        <button
                            type="button"
                            class="button button_size_sm button_bg_gray confirm__friendship"
                            :class="{ 'is-wait': loading }"
                            @click="react('deny', notification)"
                        >
                            Отклонить
                        </button>
                        <button
                            type="button"
                            class="button button_size_sm confirm__friendship"
                            :class="{ 'is-wait': loading }"
                            @click="react('accept', notification)"
                        >
                            Подтвердить
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { notificationsMixin } from '@/components/Notifications/NotificationsMixin.js'
import { mapActions } from 'pinia'
import { useFriendshipStore } from '../../stores/FriendshipStore'

export default {
    name: 'FriendsNotifications',

    mixins: [notificationsMixin],

    methods: {
        ...mapActions(useFriendshipStore, {
            accept: 'acceptFriendship',
            deny: 'denyFriendship'
        }),
        react(reaction, notification) {
            this[reaction](notification.sender.id).then(() => {
                this.$emit('update')
            })
        }
    }
}
</script>
