<script setup>
import InfiniteLoading from 'v3-infinite-loading'
import 'v3-infinite-loading/lib/style.css'

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    perPage: {
        type: Number,
        default: 10
    }
})

const emit = defineEmits(['onLoad'])

const load = async ($state) => {
    try {
        const response = await axios.get(props.url)
        if (response?.data?.data?.length < props.perPage) {
            $state.complete()
        } else {
            $state.loaded()
        }
        emit('onLoad', response?.data?.data)
    } catch (error) {
        console.log('Errro', error)
        $state.error()
    }
}
</script>
<template>
    <div>
        <slot />
        <InfiniteLoading @infinite="load">
            <template #spinner>
                <slot name="loader" />
            </template>
            <template #complete>
                <slot name="complete">
                    <span></span>
                </slot>
            </template>
            <template #error>
                <div
                    class="specialty-item__text"
                    style="text-align: center; font-size: 16px"
                >
                    <slot name="error" />
                </div>
            </template>
        </InfiniteLoading>
    </div>
</template>
