<template>
    <template v-if="loading">
        <div class="section__header">
            <Skeletor class="section__headline"/>
            <!--                <a href="#" class="section__more">Все 12</a>-->
        </div>
        <div class="section__body">
            <div class="event-item" v-for="item in 5" :key="item">
                <div class="event-item__main">
                    <Skeletor width="4.4rem" height="4.4rem" circle class="event-item__pic event-item__pic_bg_3"/>
                    <div class="event-item__info">
                        <Skeletor class="event-item__date" width="70px"/>
                        <Skeletor class="event-item__date" width="150px"/>
                    </div>
                </div>
                <div class="event-item__footer">
                    <Skeletor class="event-item__link" v-for="i in 2" :key="i"/>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="section__header" v-if="achievements.length">
            <div class="section__headline"> Достижения</div>
            <!--                <a href="#" class="section__more">Все 12</a>-->
        </div>
        <div class="section__body">
            <div class="event-item" v-for="achievement in achievements" :key="achievement.id">
                <div class="event-item__main">
                    <div class="event-item__pic" :class="`event-item__pic_bg_${achievement.achievement}`">
                        <i :class="`icon-${achievement.achievement === '0' ? 'competitor' : 'winner'}`"></i>
                    </div>
                    <div class="event-item__info">
                        <div class="event-item__date">{{ achievement.year }}</div>
                        <div class="event-item__headline">{{ achievement.achievement_name }}</div>
                    </div>
                </div>
                <div class="event-item__footer">
                    <button class="event-item__link" @click="handleAchievementClick(achievement.id)">
                        {{ `${achievement.event}${achievement.city ? `, ${achievement.city.name}` : ''}` }}
                    </button>
                </div>
            </div>
        </div>
    </template>

    <modals-container/>
</template>

<script setup>
import {onMounted, ref} from "vue"
import {notify} from "@kyvg/vue3-notification"
import {Skeletor} from 'vue-skeletor'
import {$vfm, ModalsContainer} from 'vue-final-modal'
import EditAchievement from "./EditAchievement.vue";

const props = defineProps(['petId', 'isOwner'])

const achievements = ref([])
const loading = ref(true)


const fetchAchievements = async () => {
    try {
        const {data} = await axios.get(`/api/v1/pets/${props.petId}/achievements`)
        achievements.value = data.data ?? []
    } catch (error) {
        notify({
            type: 'error',
            text: error
        })
    } finally {
        loading.value = false
    }
}

const handleAchievementClick = (id) => {
    $vfm.show({
        component: EditAchievement,
        bind: {
            id,
            name: 'EditAchievement',
            isOwner: props.isOwner
        },
        on: {
            close() {
                $vfm.hide('EditAchievement')
            },
            onDelete() {
                notify({
                    type: 'success',
                    text: 'Достижение успешно удалено'
                })
                $vfm.hide('EditAchievement')
                fetchAchievements()
            },
            onUpdate() {
                notify({
                    type: 'success',
                    text: 'Достижение успешно обновлено'
                })
                fetchAchievements()
            }
        }
    })
}

const show = ref(false)

onMounted(() => {
    fetchAchievements()
})
</script>
