<template>
    <button
        type="button"
        @click="toggle"
        class="button button_rounded community__subscribe__toggle"
    >
        <template v-if="isSubscribed"> Отписаться </template>
        <template v-else> Подписаться </template>
    </button>
    <div class="preview-main__meta" v-if="subscribers > 0">
        <div class="preview-main__meta-item" v-html="trans(subscribers)"></div>
    </div>
</template>

<script>
export default {
    name: 'SubscriptionToggler',

    props: {
        subscribed: {
            type: String,
            default: 'no'
        },
        id: {
            required: true
        },
        count: {
            default: 0
        }
    },

    data() {
        return {
            isSubscribed: this.subscribed === 'subscribed',
            subscribers: this.count
        }
    },

    methods: {
        toggle() {
            axios
                .post('/api/v1/communities/toggle-subscribe', {
                    community_id: this.id
                })
                .then((res) => {
                    console.log('res', res)
                    if (res.data.success) {
                        this.isSubscribed = !this.isSubscribed
                        this.subscribers = res.data.subscribers
                        document.location.reload()
                    }
                })
        },
        trans(q) {
            switch (parseInt(q)) {
                case 1:
                    return '1 подписчик'
                case 2:
                    return '2 подписчика'
                case 3:
                    return '3 подписчика'
                case 4:
                    return '4 подписчика'
                default:
                    return `${q} подписчиков`
            }
        }
    }
}
</script>
