<template>
    <div class="post">
        <div class="post__header">
            <div class="profile">
                <a
                    :href="post.author.url"
                    class="profile__pic"
                    v-if="post.author"
                >
                    <img
                        :src="
                            getAvatar(
                                post.business
                                    ? post.business.avatar
                                    : post.author.avatar
                            )
                        "
                        alt=""
                        class="profile__img"
                    />
                    <img
                        :src="post.pet.avatar"
                        alt=""
                        v-if="post.pet"
                        class="profile__img"
                    />
                    <!--                    <img :src="post.community.image" alt="" v-else-if="post.community"-->
                    <!--                         class="profile__img">-->
                </a>
                <span v-else class="profile__pic">
                    <img
                        :src="'/img/no-photo.png'"
                        alt=""
                        class="profile__img"
                    />
                    <img
                        :src="post.pet.avatar"
                        alt=""
                        v-if="post.pet"
                        class="profile__img"
                    />
                    <!--                    <img :src="post.community.thumb" alt="" v-else-if="post.community"-->
                    <!--                         class="profile__img">-->
                </span>
                <div class="profile__body">
                    <template v-if="post.business">
                        <a
                            :href="`/business/${post.business.id}`"
                            class="profile__name"
                            >{{ post.business.name }}</a
                        >
                    </template>
                    <template v-else>
                        <a
                            :href="post.author.url"
                            v-if="post.author"
                            class="profile__name"
                            >{{ post.author.full_name }}</a
                        >
                        <span v-else style="text-transform: uppercase"
                            >deleted</span
                        >
                    </template>
                    <template v-if="post.pet">
                        ,&nbsp;<a
                            :href="`/pet/${post.pet.id}`"
                            class="profile__name"
                            style="text-transform: capitalize"
                            >{{ post.pet.nickname }}</a
                        >
                    </template>
                    <template v-if="post.community">
                        &nbsp;в&nbsp;<a
                            :href="`/communities/${post.community.id}`"
                            class="profile__name"
                            >{{ post.community.title_ru }}</a
                        >
                    </template>
                    <div class="profile__meta">
                        <div class="profile__meta-item">
                            {{ post.created_at_formatted }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="post__headline">
                <a :href="`/posts/${post.id}`">{{ post.title }}</a>
            </div>
        </div>
        <div class="post__body">
            <div class="video-container" v-if="post.youtube">
                <iframe
                    width="560"
                    height="315"
                    :src="post.youtube"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    allowfullscreen
                ></iframe>
            </div>
            <!-- изображения выстраиваются по сетке grid, тип сетки зависит от атрибута data-qnt, который может принимать значения от 1 до 4-->
            <div
                class="post__gallery"
                v-if="post.images?.length > 0"
                :data-qnt="Math.min(post.images.length, 4)"
            >
                <!--                @if (Arr::get($image, 'data.file.url'))-->
                <a
                    v-for="img in post.images"
                    :href="`/posts/${post.id}`"
                    class="post__pic"
                >
                    <img :src="img.data.file.url" :alt="post.title" />
                </a>
                <!--                @endif-->
            </div>
            <div class="post__text">
                <div v-html="post.preview"></div>
            </div>
        </div>
        <post-footer :post="post" :auth-user-id="authUserId"></post-footer>
        <!--        <x-posts.footer :post="$post"></x-posts.footer>-->
    </div>
</template>

<script>
import PostFooter from '@/components/Posts/PostFooter.vue'

export default {
    name: 'PostInList',

    components: {
        PostFooter
    },

    props: {
        post: {
            required: true
        },
        authUserId: {
            default: null
        }
    },

    methods: {
        getAvatar(avatar = null) {
            if (!avatar || avatar === '') {
                return '/img/organization-empty-logo.svg'
            }
            return avatar
        }
    }
}
</script>
