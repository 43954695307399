<script setup>
import { useChatStore } from '../../stores/ChatStore'
import { onMounted, ref } from 'vue'
import { Skeletor } from 'vue-skeletor'
import Sockets from '../Sockets.vue'

const props = defineProps(['authId'])

const chatStore = useChatStore()

const sockets = ref()

onMounted(async () => {
    await chatStore.fetchHasNewMessages()
    sockets.value.socket.on(
        'App\\Notifications\\Chat\\NewMessageNotification',
        async (data) => {
            if (parseInt(data.notifiable_id) === parseInt(props.authId)) {
                await chatStore.fetchHasNewMessages()
            }
        }
    )
})

const loading = ref(true)
const roomType = ref('user')

const handleClick = () => {
    loading.value = true
    chatStore.fetchLastMessages(roomType.value).then(() => {
        loading.value = false
    })
}

const handleRoomTypeChange = (type) => {
    loading.value = true
    roomType.value = type
    chatStore.fetchLastMessages(type).then(() => {
        loading.value = false
    })
}

const getAvatar = (room) => {
    let avatar
    if (room.recipient_type === 'business') {
        avatar = room.user?.avatar
    } else {
        avatar = room.recipient?.avatar
    }
    if (!avatar) {
        avatar = '/img/no-photo.png'
    }
    return parseInt(room.recipient_id) === parseInt(props.authId)
        ? room.user.avatar ?? '/img/no-photo.png'
        : avatar
}
</script>

<template>
    <Sockets ref="sockets" :auth-id="props.authId">
        <button
            type="button"
            class="user-nav__label"
            data-action="dropdown-toggle"
            @click="handleClick"
        >
            <i class="icon-chat"></i>
            <span
                class="user-nav__notification"
                v-if="chatStore.hasUnreadMessages"
            ></span>
        </button>
        <div class="dropdown-drop" data-action="dropdown-drop">
            <div class="dropdown-drop__header">
                <div class="dropdown-drop__title">Сообщения</div>
                <a href="/chat2" class="dropdown-drop__more">Показать все</a>
            </div>
            <div class="dropdown-drop__list" style="min-height: 72px">
                <div class="chat__tab" style="padding: 12px 20px 0">
                    <div class="tab-nav">
                        <button
                            class="tab-nav__button"
                            :class="{
                                'tab-nav__button_active': roomType === 'user'
                            }"
                            type="button"
                            @click="handleRoomTypeChange('user')"
                        >
                            Личные
                        </button>
                        <button
                            class="tab-nav__button"
                            :class="{
                                'tab-nav__button_active':
                                    roomType === 'business'
                            }"
                            type="button"
                            @click="handleRoomTypeChange('business')"
                        >
                            Заказы
                        </button>
                        <button
                            class="tab-nav__button"
                            :class="{
                                'tab-nav__button_active': roomType === 'shop'
                            }"
                            type="button"
                            @click="handleRoomTypeChange('shop')"
                        >
                            Барахолка
                        </button>
                    </div>
                </div>
                <template v-if="!loading">
                    <template v-if="chatStore.lastMessages.length">
                        <a
                            :href="
                                '/chat/' +
                                (parseInt(room.recipient_id) ===
                                parseInt(authId)
                                    ? room.user.id
                                    : room.recipient_id) +
                                `?type=${room.recipient_type}`
                            "
                            class="notification"
                            v-for="room in chatStore.lastMessages"
                            :key="room.id"
                        >
                            <div class="notification__pic">
                                <img
                                    :src="getAvatar(room)"
                                    alt=""
                                    class="notification__img"
                                />
                            </div>
                            <div class="notification__body">
                                <div class="notification__info">
                                    <div class="notification__name">
                                        {{ room.name }}
                                    </div>

                                    <div class="notification__meta">
                                        <div
                                            class="notification__date"
                                            v-if="room.last_message"
                                        >
                                            <span
                                                v-if="
                                                    parseInt(
                                                        room.last_message
                                                            .user_id
                                                    ) === parseInt(authId)
                                                "
                                                >Вы: </span
                                            >{{
                                                room.last_message
                                                    .created_at_formatted
                                            }}
                                            <span
                                                class="unread_count"
                                                v-if="room.unread_count > 0"
                                            >
                                                {{ room.unread_count }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="notification__event"
                                    style="overflow: hidden"
                                >
                                    <span v-if="room.last_message">{{
                                        room.last_message.message.length > 80
                                            ? `${room.last_message.message.substring(
                                                  0,
                                                  80
                                              )}...`
                                            : room.last_message.message
                                    }}</span>
                                    <span
                                        v-else
                                        class="dropdown-drop__empty-text"
                                        style="padding-top: 15px"
                                        >Сообщений пока нет</span
                                    >
                                </div>
                            </div>
                        </a>
                    </template>
                    <span v-else class="dropdown-drop__empty-text"
                        >Сообщений пока нет</span
                    >
                </template>
                <div v-else>
                    <div
                        class="chat-user"
                        v-for="room in [1, 2, 3]"
                        :key="room"
                    >
                        <div class="chat-user__pic">
                            <Skeletor circle class="chat-user__img" />
                        </div>
                        <div class="chat-user__body">
                            <div class="chat-user__info" style="width: 100%">
                                <div
                                    class="chat-user__name"
                                    style="width: 100%"
                                >
                                    <Skeletor width="50%" />
                                </div>
                            </div>
                            <div class="chat-user__text">
                                <Skeletor width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Sockets>
</template>

<style>
.unread_count {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: var(--Base0);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    margin-left: auto;
}
</style>
