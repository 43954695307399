<template>
    <div class="form">
        <div class="form__group">
            <slot name="title">
                <div
                    class="headline-alt"
                    v-if="!attitudeType || attitudeType === 'user'"
                >
                    <div>Публикация на моей странице</div>
                </div>
                <div class="headline-alt" v-else>
                    <div v-if="attitudeType === 'pet'">
                        Публикация для питомца
                    </div>
                    <div v-if="attitudeType === 'community'">
                        Публикация в клубе
                    </div>
                    <div class="pet" v-if="attitude">
                        <template v-if="attitudeType === 'pet'">
                            <a :href="`/pet/${attitudeId}`" class="pet__pic">
                                <img
                                    class="pet__img"
                                    :src="attitude.avatar"
                                    alt=""
                                />
                            </a>
                            <div class="pet__body">
                                <a
                                    :href="`/pet/${attitudeId}`"
                                    class="pet__name"
                                    >{{ attitude.nickname }}</a
                                >
                            </div>
                        </template>
                        <template v-else-if="attitudeType === 'community'">
                            <a
                                :href="`/communities/${attitudeId}`"
                                class="pet__pic"
                            >
                                <img
                                    class="pet__img"
                                    :src="attitude.thumb"
                                    alt=""
                                />
                            </a>
                            <div class="pet__body">
                                <a
                                    :href="`/communities/${attitudeId}`"
                                    class="pet__name"
                                    >{{ attitude.title_ru }}</a
                                >
                            </div>
                        </template>
                    </div>
                </div>
            </slot>
        </div>

        <div class="form__set">
            <div class="form__group">
                <div class="form-control">
                    <input
                        type="text"
                        class="form-control__control"
                        v-model="title"
                        data-action="form-control"
                        title="Название поста"
                        :class="{
                            error: v$.title.$invalid && v$.title.$dirty,
                            'is-valued': !!title
                        }"
                    />
                    <div class="form-control__label">Название поста</div>
                    <div
                        v-for="error of v$.title.$errors"
                        :key="error.$uid"
                        class="error"
                    >
                        {{ error.$message }}
                    </div>
                </div>
            </div>

            <div class="form__group" v-if="showType">
                <div class="form-control">
                    <VueMultiselect
                        v-model="type"
                        :show-labels="false"
                        :allow-empty="false"
                        placeholder="Выберите тип"
                        :searchable="false"
                        label="name"
                        track-by="id"
                        :options="types"
                    >
                        <template v-slot:noResult
                            >Типов, удовлетворяющих условию поиска не найдено
                        </template>
                        <template v-slot:noOptions
                            >Типов, удовлетворяющих условию поиска не найдено
                        </template>
                    </VueMultiselect>
                    <!--                    <div class="form-control__label">Тип</div>-->
                </div>
            </div>

            <!--            @if($errors->has('content.blocks')) border-color: var(&#45;&#45;Red)!important; @endif-->
            <div
                style="
                    padding: 20px;
                    border-radius: 6px;
                    min-height: 420px;
                    border: 1px solid var(--Gray20);
                "
                :style="
                    v$.content.$errors.length ? 'border-color: var(--Red)' : ''
                "
            >
                <div id="post"></div>
            </div>

            <div
                v-for="error of v$.content.$errors"
                style="
                    color: #ee3d32;
                    margin-top: 0.8rem;
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 1.2;
                "
                :key="error.$uid"
                class="error"
            >
                {{ error.$message }}
            </div>
        </div>

        <div
            class="constructor__controls"
            style="
                padding-left: 0;
                padding-right: 0;
                justify-content: flex-end;
                gap: 20px;
            "
        >
            <div class="constructor__control">
                <a
                    :href="previous"
                    type="button"
                    class="button button_bg_gray button_size_sm"
                    >Отменить</a
                >
            </div>
            <div class="constructor__control">
                <button
                    type="button"
                    class="button button_size_sm"
                    :class="{ 'is-wait': loading }"
                    @click="handleSubmit"
                >
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import EditorJS from '@editorjs/editorjs'
import ImageTool from '@editorjs/image'
import Embed from '@editorjs/embed'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@/utils/i18n-validators'
import VueMultiselect from 'vue-multiselect'

export default {
    name: 'PostForm',

    components: { VueMultiselect },

    setup() {
        return { v$: useVuelidate() }
    },

    props: {
        auth_user: {
            required: true
        },
        post: {
            type: String,
            required: false
        },
        previous: {
            type: String,
            default: document.location.href
        },
        placeholder: {
            type: String,
            required: false,
            default:
                'Расскажите свою историю. Вы также можете добавить видео youtube, просто скопировав ссылку в область ввода текста.'
        },
        initialShowType: {
            type: Boolean,
            default: false
        },
        initialAttitudeType: {
            required: false,
            default: null
        },
        initialAttitudeId: {
            required: false,
            default: null
        }
    },

    data() {
        return {
            authData: JSON.parse(this.auth_user) ?? {},
            postData: this.post ? JSON.parse(this.post) : {},
            editor: null,
            title: '',
            loading: false,
            showType: this.initialShowType,
            type: {
                id: 'article',
                name: 'Статья'
            },
            attitude: null,
            attitudeType: null,
            attitudeId: null,
            content: '',
            vuelidateExternalResults: {
                title: [],
                content: []
            },
            types: [
                {
                    id: 'article',
                    name: 'Статья'
                },
                {
                    id: 'question',
                    name: 'Вопрос и ответ'
                },
                {
                    id: 'event',
                    name: 'Мероприятие'
                }
            ]
        }
    },

    validations() {
        return {
            title: { required },
            content: { maxLength: maxLength(10000) }
        }
    },

    methods: {
        initEditor() {
            let $this = this
            window.editor = new EditorJS({
                holder: 'post',

                tools: {
                    image: {
                        class: ImageTool,
                        config: {
                            uploader: {
                                /**
                                 * Upload file to the server and return an uploaded image data
                                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                                 * @return {Promise.<{success, file: {url}}>}
                                 */
                                uploadByFile(file) {
                                    // your own uploading logic here
                                    const formData = new FormData()
                                    formData.append('files', file)
                                    return axios
                                        .post('/api/v1/files', formData)
                                        .then((res) => {
                                            if (res.data.success) {
                                                return res.data
                                            } else {
                                                $this.$notify({
                                                    type: 'error',
                                                    title: res.data?.message
                                                })
                                            }
                                        })
                                },

                                /**
                                 * @param {string} url - pasted image URL
                                 * @return {Promise.<{success, file: {url}}>}
                                 */
                                uploadByUrl(url) {
                                    $this.$notify({
                                        type: 'warning',
                                        title: 'Вставить текст с картинкой не получится'
                                    })
                                    return Promise.reject(
                                        'Вставить текст с картинкой не получится'
                                    )
                                }
                            }
                        }
                    },
                    embed: {
                        class: Embed,
                        config: {
                            services: {
                                youtube: true
                            }
                        }
                    }
                },

                data: this.getInitialContent(),

                i18n: {
                    /**
                     * @type {I18nDictionary}
                     */
                    messages: {
                        /**
                         * Other below: translation of different UI components of the editor.js core
                         */
                        ui: {
                            blockTunes: {
                                toggler: {
                                    'Click to tune': 'Нажмите, чтобы настроить',
                                    'or drag to move': 'или перетащите'
                                }
                            },
                            inlineToolbar: {
                                converter: {
                                    'Convert to': 'Конвертировать в'
                                }
                            },
                            toolbar: {
                                toolbox: {
                                    Add: 'Добавить',
                                    Filter: 'Поиск'
                                }
                            }
                        },

                        /**
                         * Section for translation Tool Names: both block and inline tools
                         */
                        toolNames: {
                            Text: 'Текст',
                            Heading: 'Заголовок',
                            Image: 'Картинка',
                            List: 'Список',
                            Warning: 'Примечание',
                            Checklist: 'Чеклист',
                            Quote: 'Цитата',
                            Code: 'Код',
                            Delimiter: 'Разделитель',
                            'Raw HTML': 'HTML-фрагмент',
                            Table: 'Таблица',
                            Link: 'Ссылка',
                            Marker: 'Маркер',
                            Bold: 'Полужирный',
                            Italic: 'Курсив',
                            InlineCode: 'Моноширинный'
                        },

                        /**
                         * Section for passing translations to the external tools classes
                         */
                        tools: {
                            /**
                             * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
                             * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
                             */
                            warning: {
                                // <-- 'Warning' tool will accept this dictionary section
                                Title: 'Название',
                                Message: 'Сообщение'
                            },

                            /**
                             * Link is the internal Inline Tool
                             */
                            link: {
                                'Add a link': 'Вставьте ссылку'
                            },
                            /**
                             * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
                             */
                            stub: {
                                'The block can not be displayed correctly.':
                                    'Блок не может быть отображен'
                            },
                            image: {
                                'Select an Image': 'Загрузить изображение',
                                Caption: 'Описание',
                                'With border': 'Добавить границы',
                                'Stretch image': 'На всю ширину',
                                'With background': 'Фон'
                            },
                            embed: {
                                'Enter a caption': 'Описание'
                            }
                        },

                        /**
                         * Section allows to translate Block Tunes
                         */
                        blockTunes: {
                            /**
                             * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
                             * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
                             *
                             * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
                             */
                            delete: {
                                Delete: 'Удалить'
                            },
                            moveUp: {
                                'Move up': 'Переместить вверх'
                            },
                            moveDown: {
                                'Move down': 'Переместить вниз'
                            },
                            image: {
                                stretched: 'Растянуть изображение'
                            }
                        }
                    }
                },
                placeholder: this.placeholder
                // onChange(editorState) {
                //     console.log('editorState', editorState)
                // }
            })
        },

        getInitialContent() {
            this.title = this.postData?.title ?? null
            if (this.postData?.postable_type === 'community') {
                this.showType = true
                this.type = this.types.find(
                    (type) => type.id === this.postData.type
                )
            }
            return this.postData?.content ?? null
        },

        async handleSubmit() {
            if (this.loading) {
                return
            }
            this.v$.$clearExternalResults()
            this.loading = true
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.loading = false
                return
            }
            editor.save().then((savedData) => {
                let payload = {
                    title: this.title,
                    content: JSON.stringify(savedData),
                    attitude_type: this.attitudeType,
                    attitude_id: this.attitudeId
                }
                if (this.type) {
                    Object.assign(payload, { type: this.type.id })
                }

                let url = this.postData.id
                    ? `/api/v1/posts/${this.postData.id}`
                    : '/api/v1/posts'
                axios
                    .post(url, payload)
                    .then((res) => {
                        console.log('res', res)
                        if (!res.data?.data?.id) {
                            //  FIXME: Как-то по нормальному сделать отображение ошибок
                            if (
                                res.response?.data?.errors.hasOwnProperty(
                                    'content.blocks'
                                )
                            ) {
                                Object.assign(this.vuelidateExternalResults, {
                                    content:
                                        'Поле "Контент" обязательно для заполнения'
                                })
                            }
                            return
                        }
                        this.$notify({
                            type: 'success',
                            title: 'Пост успешно создан'
                        })
                        document.location.href = `/posts/${
                            this.postData?.id ?? res.data?.data?.id
                        }`
                    })
                    .catch((res) => {
                        console.log('res.response', res.response)
                        if (
                            res.response?.data?.errors?.hasOwnProperty(
                                'content.blocks'
                            )
                        ) {
                            //  FIXME: Как-то по нормальному сделать отображение ошиброк
                            Object.assign(this.vuelidateExternalResults, {
                                content:
                                    'Поле "Контент" обязательно для заполнения'
                            })
                        }
                        this.loading = false
                    })
            })
        },

        async initQueryParams() {
            const params = new Proxy(
                new URLSearchParams(window.location.search),
                {
                    get: (searchParams, prop) => searchParams.get(prop)
                }
            )
            let attitude_type = params.attitude_type
            if (attitude_type === 'community') {
                this.showType = true
            }
            this.attitudeType =
                attitude_type ?? this.initialAttitudeType ?? null
            this.attitudeId =
                params.attitude_id ?? this.initialAttitudeId ?? null
            if (this.postData?.id) {
                switch (this.postData.postable_type) {
                    case 'community':
                        this.attitudeType = 'community'
                        break
                    case 'user':
                        this.attitudeType = 'user'
                        break
                    case 'pet':
                        this.attitudeType = 'pet'
                        break
                    default:
                        this.attitudeType = this.initialAttitudeType
                }
                this.attitudeId = this.postData.postable_id
            }
        },

        async fetchAttitude() {
            let type = null
            console.log('attitudeType', this.attitudeType)
            switch (this.attitudeType) {
                case 'community':
                    type = 'communities'
                    break
                case 'pet':
                    type = 'pets'
                    break
            }
            if (type) {
                axios.get(`/api/v1/${type}/${this.attitudeId}`).then((res) => {
                    this.attitude = res.data?.data
                })
            }
        }
    },

    async mounted() {
        this.initEditor()
        await this.initQueryParams()
        await this.fetchAttitude()
    }
}
</script>

<style>
.codex-editor__redactor {
    padding-bottom: 0 !important;
}

.ce-paragraph[data-placeholder]:empty::before {
    color: var(--Gray50);
}
</style>
