<template>
    <Layout section="working_hours">
        <div class="constructor__content">
            <div class="constructor__header">
                <div class="constructor__headline">Режим работы</div>
            </div>

            <div
                v-for="(item, i) in v$.working_hours.$model"
                class="form__group"
            >
                <div class="form__wrap" v-if="!store.loading">
                    <div class="week">
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="1"
                            />
                            <span>пн</span>
                        </label>
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="2"
                            />
                            <span>вт</span>
                        </label>
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="3"
                            />
                            <span>ср</span>
                        </label>
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="4"
                            />
                            <span>чт</span>
                        </label>
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="5"
                            />
                            <span>пт</span>
                        </label>
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="6"
                            />
                            <span>сб</span>
                        </label>
                        <label class="week__label">
                            <input
                                type="checkbox"
                                name="week[]"
                                v-model="item.days"
                                :value="0"
                            />
                            <span>вс</span>
                        </label>
                    </div>
                    <!--      <div class="form__alert form__alert_error" v-if="errorMessage">-->
                    <!--        <i class="icon-details"></i> {{ errorMessage }}-->
                    <!--      </div>-->
                    <div class="range">
                        <div class="range__header">
                            <i class="icon-time"></i>
                            <div class="range__clock">
                                Время работы
                                <span>{{
                                    parseWorkingTime(item.time[0])
                                }}</span>
                                <span>-</span>
                                <span>{{
                                    parseWorkingTime(item.time[1])
                                }}</span>
                            </div>
                        </div>
                        <Slider
                            v-model="item.time"
                            :max="48"
                            :lazy="false"
                            :tooltips="false"
                        />
                    </div>
                    <button
                        type="button"
                        class="form__button form__button_danger"
                        @click="handleDelete(i)"
                        v-if="working_hours.length > 1"
                    >
                        Удалить
                    </button>
                </div>
                <Skeletor v-else height="144px" as="div"></Skeletor>
            </div>

            <!--        <div class="form__alert form__alert_error" v-if="errors.length">-->
            <!--            <i class="icon-details"></i> {{ errors[0] }}-->
            <!--        </div>-->

            <div class="form__group">
                <div
                    class="form__wrap"
                    @click="handleAdd"
                    style="cursor: pointer"
                    v-if="!store.loading"
                >
                    <button type="button" class="form__button">
                        Добавить еще
                    </button>
                </div>
                <Skeletor v-else height="52" as="div"></Skeletor>
                <div
                    class="form__alert form__alert_error"
                    v-if="v$.working_hours.$errors[0]?.$message"
                >
                    <i class="icon-details"></i>
                    {{ v$.working_hours.$errors[0]?.$message }}
                </div>
            </div>

            <FormFooter
                :loading="store.loading"
                @onSubmit="handleSubmit"
                @onCancel="handleReset"
            />
        </div>
    </Layout>
</template>

<script setup>
import { onMounted, provide, ref } from 'vue'
import FormFooter from './FormFooter.vue'
import Layout from './Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import Slider from '@vueform/slider'
import { Skeletor } from 'vue-skeletor'
import _ from 'lodash'

const store = useBusinessStore()

const props = defineProps({
    authUser: {
        required: true
    },
    business: {
        required: true
    }
})

const working_hours = ref(
    props.business?.working_hours
        ? props.business.working_hours.map((item) => {
              return {
                  days: [...item.days],
                  time: [_.first(item.time), _.last(item.time)]
              }
          })
        : store.initialValues?.working_hours ?? [
              {
                  days: [1, 2, 3, 4, 5],
                  time: [16, 36]
              }
          ]
)

onMounted(() => {
    store.setInitialValues(props.business)
})

const rules = {
    working_hours: {
        requiredLocalized
    }
}
const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    { working_hours: working_hours.value },
    { $externalResults }
)

const handleDelete = (i) => {
    working_hours.value.splice(i, 1)
}

const handleAdd = () => {
    if (!working_hours.value) {
        working_hours.value = []
    }
    working_hours.value.push({
        days: [1, 2, 3, 4, 5],
        time: [16, 36]
    })
}

const parseWorkingTime = (item) => {
    let hours = Math.trunc(item / 2)
    let minutes = item % 2 === 0 ? '00' : '30'
    return `${hours}:${minutes}`
}

const handleSubmit = async () => {
    v$.value.$clearExternalResults()
    if (!v$.value.$anyDirty && store.completedSteps.includes(3)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    try {
        store.startLoading()
        const {
            data: { data }
        } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
            step: 3,
            working_hours: working_hours.value
        })
        store.setInitialValues(data, 4)
    } catch (error) {
        const errors = error.response.data.errors
        console.log('errors', errors)
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}

const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    const formData = {}
    formData['step'] = 3
    const fields = ['working_hours']
    fields.forEach((field) => {
        if (eval(`${field}.value`)) {
            formData[field] = eval(`${field}.value`)
        }
    })

    console.log('formData', formData)

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            formData
        )
        document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}

const handleReset = async () => {
    working_hours.value = props.business?.working_hours ??
        store.initialValues?.working_hours ?? [
            {
                days: [1, 2, 3, 4, 5],
                time: [16, 36]
            }
        ]
    v$.value.$reset()
}
</script>
