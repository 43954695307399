<script setup>
import ModalRegisterButton from '../components/Auth/ModalRegisterButton.vue'
import Cookies from '../components/Cookies.vue'
import Auth from '../components/Auth/Auth.vue'
import { ref } from 'vue'

const props = defineProps({
    posts: {
        type: [Array, Object],
        default: () => []
    },
    categories: {
        type: [Array, Object],
        default: () => []
    }
})

const auth = ref()
</script>

<template>
    <div>
        <!--        <Notifications />-->
        <header class="header">
            <div class="container">
                <div class="header__inner">
                    <a class="logo" href="/">
                        <img
                            class="logo__img"
                            :src="'/landing/img/logo_white.svg'"
                            alt="lapki.club"
                        />
                    </a>
                    <div class="header__nav">
                        <button
                            class="button button_bg_transparent button_size_md"
                            type="button"
                            @click="auth.login"
                        >
                            Войти
                        </button>
                        <button
                            class="button button_size_md"
                            v-cloak
                            type="button"
                            @click="auth.register"
                        >
                            Присоединиться
                        </button>
                    </div>
                    <button class="header__toggle js-burger-open" type="button">
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                </div>
            </div>
        </header>
        <div class="burger">
            <div class="burger__inner">
                <div class="burger__header">
                    <a class="logo" href="/">
                        <img
                            class="logo__img"
                            :src="'/img/logo.svg'"
                            alt="lapki.club"
                    /></a>
                    <button class="burger__close js-burger-close" type="button">
                        <svg viewBox="0 0 32 32">
                            <path d="M10,10 L22,22 M22,10 L10,22"></path>
                        </svg>
                    </button>
                </div>
                <div class="burger__body">
                    <div class="burger__nav">
                        <button
                            class="button button_bg_transparent button_size_md"
                            type="button"
                            @click="auth.login"
                        >
                            Войти
                        </button>
                        <button
                            @click="auth.register"
                            class="button button_bg_blue button_size_md"
                        >
                            Присоединиться
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider">
            <div class="swiper" data-swiper="slider">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="hero">
                            <div
                                class="hero__bg-mob"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide1-mob_1.jpeg');
                                "
                            ></div>
                            <div
                                class="hero__bg"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide1_1.jpeg');
                                "
                            ></div>
                            <div class="container">
                                <div class="hero__wrap">
                                    <div class="hero__content">
                                        <div class="hero__title">
                                            Ваш круг общения о животных
                                        </div>
                                        <div class="hero__cloud">
                                            <div class="hero__headline">
                                                Добавить в друзья Майло
                                            </div>
                                        </div>
                                        <div class="hero__text">
                                            Делись, общайся, перенимай опыт,
                                            находи новых друзей, ищи и предлагай
                                            услуги
                                        </div>
                                        <div class="hero__footer">
                                            <button
                                                class="button"
                                                @click="auth.register"
                                            >
                                                Зафрендить Майло
                                            </button>
                                        </div>
                                    </div>
                                    <div class="hero__likes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="hero hero_wide">
                            <div
                                class="hero__bg-mob"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide2-mob_1.jpeg');
                                "
                            ></div>
                            <div
                                class="hero__bg"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide2_1.jpeg');
                                "
                            ></div>
                            <div class="container">
                                <div class="hero__wrap">
                                    <div class="hero__content">
                                        <div class="hero__title">
                                            Ваш круг общения о животных
                                        </div>
                                        <div class="hero__cloud">
                                            <div class="hero__headline">
                                                Куда и с кем сегодня мы идём
                                                гулять?
                                            </div>
                                        </div>
                                        <div class="hero__text">
                                            Делись, общайся, перенимай опыт,
                                            находи новых друзей, ищи и предлагай
                                            услуги
                                        </div>
                                        <div class="hero__footer">
                                            <button
                                                class="button"
                                                @click="auth.register"
                                            >
                                                Найти компаньона
                                            </button>
                                        </div>
                                    </div>
                                    <div class="hero__likes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="hero hero_wide">
                            <div
                                class="hero__bg-mob"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide3-mob_1.jpeg');
                                "
                            ></div>
                            <div
                                class="hero__bg"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide3_1.jpeg');
                                "
                            ></div>
                            <div class="container">
                                <div class="hero__wrap">
                                    <div class="hero__content">
                                        <div class="hero__title">
                                            Ваш круг общения о животных
                                        </div>
                                        <div class="hero__cloud">
                                            <div class="hero__headline">
                                                Где найти нормальный корм, ааа?
                                            </div>
                                        </div>
                                        <div class="hero__text">
                                            Делись, общайся, перенимай опыт,
                                            находи новых друзей, ищи и предлагай
                                            услуги
                                        </div>
                                        <div class="hero__footer">
                                            <button
                                                class="button"
                                                @click="auth.register"
                                            >
                                                Найти корм возле дома
                                            </button>
                                        </div>
                                    </div>
                                    <div class="hero__likes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="hero hero_mid">
                            <div
                                class="hero__bg-mob"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide4-mob_1.jpeg');
                                "
                            ></div>
                            <div
                                class="hero__bg"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide4_1.jpeg');
                                "
                            ></div>
                            <div class="container">
                                <div class="hero__wrap">
                                    <div class="hero__content">
                                        <div class="hero__title">
                                            Ваш круг общения о животных
                                        </div>
                                        <div class="hero__cloud">
                                            <div class="hero__headline">
                                                Может не надо нам груминг?
                                            </div>
                                        </div>
                                        <div class="hero__text">
                                            Делись, общайся, перенимай опыт,
                                            находи новых друзей, ищи и предлагай
                                            услуги
                                        </div>
                                        <div class="hero__footer">
                                            <button
                                                class="button"
                                                @click="auth.register"
                                            >
                                                Найти хороший груминг
                                            </button>
                                        </div>
                                    </div>
                                    <div class="hero__likes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="hero">
                            <div
                                class="hero__bg-mob"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide5-mob_1.jpeg');
                                "
                            ></div>
                            <div
                                class="hero__bg"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide5_1.jpeg');
                                "
                            ></div>
                            <div class="container">
                                <div class="hero__wrap">
                                    <div class="hero__content">
                                        <div class="hero__title">
                                            Ваш круг общения о животных
                                        </div>
                                        <div class="hero__cloud">
                                            <div class="hero__headline">
                                                Хозяин, зачем нам эта диета?
                                            </div>
                                        </div>
                                        <div class="hero__text">
                                            Делись, общайся, перенимай опыт,
                                            находи новых друзей, ищи и предлагай
                                            услуги
                                        </div>
                                        <div class="hero__footer">
                                            <button
                                                class="button"
                                                @click="auth.register"
                                            >
                                                Найти правильную диету
                                            </button>
                                        </div>
                                    </div>
                                    <div class="hero__likes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="hero">
                            <div
                                class="hero__bg-mob"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide6-mob_1.jpeg');
                                "
                            ></div>
                            <div
                                class="hero__bg"
                                style="
                                    background-image: url('/landing/img/hero/hero-slide6_1.jpeg');
                                "
                            ></div>
                            <div class="container">
                                <div class="hero__wrap">
                                    <div class="hero__content">
                                        <div class="hero__title">
                                            Ваш круг общения о животных
                                        </div>
                                        <div class="hero__cloud">
                                            <div class="hero__headline">
                                                А что там у соседа в миске?
                                            </div>
                                        </div>
                                        <div class="hero__text">
                                            Делись, общайся, перенимай опыт,
                                            находи новых друзей, ищи и предлагай
                                            услуги
                                        </div>
                                        <div class="hero__footer">
                                            <button
                                                class="button"
                                                @click="auth.register"
                                            >
                                                Чем кормят своих питомцев?
                                            </button>
                                        </div>
                                    </div>
                                    <div class="hero__likes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider__nav">
                <button
                    class="slider__item js-slider-item active"
                    type="button"
                >
                    Зафрендить Майло
                </button>
                <button class="slider__item js-slider-item" type="button">
                    Куда и с кем идём гулять?
                </button>
                <button class="slider__item js-slider-item" type="button">
                    Где найти нормальный корм?
                </button>
                <button class="slider__item js-slider-item" type="button">
                    Груминг это ад?
                </button>
                <button class="slider__item js-slider-item" type="button">
                    Зачем нам эта диета?
                </button>
                <button class="slider__item js-slider-item" type="button">
                    Что у соседа в миске?
                </button>
            </div>
        </div>
        <div class="section-preview">
            <div class="container">
                <div class="section-preview__flex">
                    <div class="section-preview__headline-mob">
                        Новые возможности для вас и вашего любимца
                    </div>
                    <div class="section-preview__pic">
                        <img
                            class="section-preview__img"
                            :src="'/landing/img/preview.png'"
                            alt=""
                        />
                    </div>
                    <div class="section-preview__info">
                        <div class="section-preview__headline">
                            Новые возможности для вас и вашего любимца
                        </div>
                        <div class="section-preview__items">
                            <div class="specs">
                                <div class="specs__item specs__item_turquoise">
                                    <div class="specs__icon">
                                        <svg class="icon-Profile">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Profile'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="specs__content">
                                        <div class="specs__headline">
                                            Профиль
                                        </div>
                                        <div class="specs__text">
                                            Заведи профиль питомца. Делись
                                            фотографиями, получай лайки,
                                            комментарии
                                        </div>
                                    </div>
                                </div>
                                <div class="specs__item specs__item_green">
                                    <div class="specs__icon">
                                        <svg class="icon-Friends">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Friends'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="specs__content">
                                        <div class="specs__headline">
                                            Друзья
                                        </div>
                                        <div class="specs__text">
                                            Ищи друзей. Организовывайте встречи.
                                            Делитесь опытом
                                        </div>
                                    </div>
                                </div>
                                <div class="specs__item specs__item_purple">
                                    <div class="specs__icon">
                                        <svg class="icon-Clubs">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Clubs'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="specs__content">
                                        <div class="specs__headline">Клубы</div>
                                        <div class="specs__text">
                                            Только полезная информация об
                                            интересующей вас породе
                                        </div>
                                    </div>
                                </div>
                                <div class="specs__item specs__item_pink">
                                    <div class="specs__icon">
                                        <svg class="icon-Event">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Event'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="specs__content">
                                        <div class="specs__headline">
                                            События
                                        </div>
                                        <div class="specs__text">
                                            Всегда будь в курсе событий.
                                            Выставки, встречи, конкурсы
                                        </div>
                                    </div>
                                </div>
                                <div class="specs__item specs__item_yellow">
                                    <div class="specs__icon">
                                        <svg class="icon-Services">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Services'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="specs__content">
                                        <div class="specs__headline">
                                            Услуги
                                        </div>
                                        <div class="specs__text">
                                            Подбирай услуги. Фотосессии,
                                            дрессировка, ветеринары
                                        </div>
                                    </div>
                                </div>
                                <div class="specs__item specs__item_aquamarine">
                                    <div class="specs__icon">
                                        <svg class="icon-Shop">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Shop'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="specs__content">
                                        <div class="specs__headline">
                                            Магазины и барахолка
                                        </div>
                                        <div class="specs__text">
                                            Покупай со скидками, следи за
                                            акциями. Продавай ненужное
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="apps">
                            <a
                                class="apps__link apps__google"
                                href="https://play.google.com/store/apps/details?id=com.evritrevel.lapkiclub&hl=ru&gl=US"
                                target="_blank"
                            ></a>
                            <a
                                class="apps__link apps__apple"
                                href="https://apps.apple.com/ru/app/lapki-club/id1662083055"
                                target="_blank"
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-tiles">
            <div class="container">
                <div class="section-tiles__grid">
                    <div class="section-tiles__major">
                        <div class="section-tiles__content">
                            <div class="description">
                                <div class="description__headline">
                                    Создай профиль и расскажи о своём питомце
                                </div>
                                <div class="description__text">
                                    Вы можете завести профиль на каждого своего
                                    подопечного. Будьте в курсе всех событий.
                                </div>
                                <div class="description__footer">
                                    <button
                                        @click="auth.register"
                                        class="button button_bg_white"
                                    >
                                        Присоединиться
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="list__icon">
                                <svg class="icon-Profile">
                                    <use
                                        :xlink:href="'/landing/icons/icons.svg#Profile'"
                                    ></use>
                                </svg>
                            </div>
                            <div class="list__items">
                                <div class="list__item">
                                    <div class="list__check">
                                        <svg class="icon-Check">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Check'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="list__text">Фотоальбом</div>
                                </div>
                                <div class="list__item">
                                    <div class="list__check">
                                        <svg class="icon-Check">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Check'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="list__text">Посты и лента</div>
                                </div>
                                <div class="list__item">
                                    <div class="list__check">
                                        <svg class="icon-Check">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Check'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="list__text">
                                        Родословная и потомство
                                    </div>
                                </div>
                                <div class="list__item">
                                    <div class="list__check">
                                        <svg class="icon-Check">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Check'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="list__text">
                                        Календарь прививок
                                    </div>
                                </div>
                                <div class="list__item">
                                    <div class="list__check">
                                        <svg class="icon-Check">
                                            <use
                                                :xlink:href="'/landing/icons/icons.svg#Check'"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="list__text">
                                        Конкурсы и выставки
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-tiles__item">
                        <div class="tile">
                            <img
                                class="tile__img"
                                :src="'/landing/uploads/tile1.jpg'"
                                alt=""
                            />
                            <div class="tile__content">
                                <div class="tile__headline">
                                    Друзья и единомышленики
                                </div>
                                <div class="tile__text">
                                    Теперь проще найти друга для совместных
                                    прогулок и тренировок
                                </div>
                                <div class="tile__footer">
                                    <button
                                        @click="auth.register"
                                        class="button"
                                    >
                                        Найти друзей
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-tiles__item">
                        <div class="tile">
                            <img
                                class="tile__img"
                                :src="'/landing/uploads/tile2.jpg'"
                                alt=""
                            />
                            <div class="tile__content">
                                <div class="tile__headline">
                                    Специалистам и владельцам бизнеса
                                </div>
                                <div class="tile__text">
                                    Самый простой способ поиска клиентов и
                                    продвижения вашего дела. Бесплатные сервисы,
                                    оптимизирующие ваши процесcы.
                                </div>
                                <div class="tile__footer">
                                    <button
                                        @click="auth.register"
                                        class="button"
                                    >
                                        Подробнее
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-exp">
            <div class="container">
                <div class="section__header">
                    <div class="section__headline">Опыт и помощь экспертов</div>
                    <div class="section__text">
                        Статьи о здоровье, психологии, тренировках, вопросы и
                        ответы
                    </div>
                </div>
                <div class="section__content">
                    <div class="carousel">
                        <div class="swiper" data-swiper="carousel" data-qnt="4">
                            <div class="swiper-wrapper">
                                <div
                                    class="swiper-slide"
                                    v-for="post in props.posts"
                                    :key="post.id"
                                >
                                    <div class="post-card">
                                        <a
                                            :href="`/posts/${post.id}`"
                                            class="post-card__pic"
                                        >
                                            <img
                                                class="post-card__img"
                                                :src="post.image"
                                                :alt="post.title"
                                            />
                                        </a>
                                        <div class="post-card__body">
                                            <a
                                                class="post-card__headline"
                                                :href="`/posts/${post.id}`"
                                            >
                                                {{ post.title }}
                                            </a>
                                        </div>
                                        <div class="post-card__footer">
                                            <a
                                                class="post-card__author"
                                                v-if="post.author"
                                                :href="`/users/${post.author.id}`"
                                            >
                                                <img
                                                    :src="post.author.avatar"
                                                    :alt="post.author.full_name"
                                                />
                                                <span>{{
                                                    post.author.full_name
                                                }}</span>
                                            </a>
                                            <div class="post-card__controls">
                                                <div class="post-card__bar">
                                                    <div
                                                        class="post-card__control"
                                                    >
                                                        <svg class="icon-Like">
                                                            <use
                                                                :xlink:href="'/landing/icons/icons.svg#Like'"
                                                            ></use>
                                                        </svg>
                                                        <span
                                                            v-if="
                                                                post.liked_users
                                                            "
                                                            >{{
                                                                post.liked_users
                                                            }}</span
                                                        >
                                                    </div>
                                                    <div
                                                        class="post-card__control"
                                                    >
                                                        <svg
                                                            class="icon-Comment"
                                                        >
                                                            <use
                                                                :xlink:href="'/landing/icons/icons.svg#Comment'"
                                                            ></use>
                                                        </svg>
                                                        <span
                                                            v-if="
                                                                post.comments_count
                                                            "
                                                            >{{
                                                                post.comments_count
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-find">
            <div class="container">
                <div class="section-find__pic"></div>
                <div class="section-find__wrap">
                    <div class="section-find__content">
                        <div class="description">
                            <div class="description__headline">
                                Теперь найти друзей стало ещё проще
                            </div>
                            <div class="description__text">
                                Вы всегда сможете на карте найти кто где гуляет,
                                где в магазинах акция и где ближайший салон
                                груминга
                            </div>
                            <div class="description__footer">
                                <button
                                    @click="auth.register"
                                    class="button button_bg_white"
                                >
                                    Присоединиться
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="section-find__map"></div>
                </div>
            </div>
        </div>
        <!--        <div class="section section-clubs">-->
        <!--            <div class="container">-->
        <!--                <div class="section__header">-->
        <!--                    <div class="section__headline">Клубы</div>-->
        <!--                    <div class="section__text">-->
        <!--                        Только полезная информация об интересующей вас породе-->
        <!--                    </div>-->
        <!--                    <div class="tab-nav">-->
        <!--                        <button-->
        <!--                            v-for="category in props.categories"-->
        <!--                            class="tab-nav__button js-tab-nav"-->
        <!--                            type="button"-->
        <!--                            :key="category.id"-->
        <!--                            :data-src="`#id_${category.id}`"-->
        <!--                        ></button>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="section__content">-->
        <!--                    <div class="tab">-->
        <!--                        <div-->
        <!--                            class="tab__item active"-->
        <!--                            v-for="category in props.categories"-->
        <!--                            :key="category.id"-->
        <!--                            :id="`id_${category.id}`"-->
        <!--                        >-->
        <!--                            <div class="carousel">-->
        <!--                                <div-->
        <!--                                    class="swiper"-->
        <!--                                    data-swiper="carousel"-->
        <!--                                    data-qnt="5"-->
        <!--                                >-->
        <!--                                    <div class="swiper-wrapper">-->
        <!--                                        <div-->
        <!--                                            class="swiper-slide"-->
        <!--                                            v-for="community in category.communities"-->
        <!--                                            :key="community.id"-->
        <!--                                        >-->
        <!--                                            <div class="club-card">-->
        <!--                                                <div class="club-card__pic">-->
        <!--                                                    <img-->
        <!--                                                        class="club-card__img"-->
        <!--                                                        :src="-->
        <!--                                                            community.firstMediaUrl-->
        <!--                                                        "-->
        <!--                                                        :alt="community.title"-->
        <!--                                                    />-->
        <!--                                                </div>-->
        <!--                                                <div class="club-card__body">-->
        <!--                                                    <a-->
        <!--                                                        class="club-card__headline"-->
        <!--                                                        :href="`/communities/${community.id}`"-->
        <!--                                                    >-->
        <!--                                                        {{ community.title }}-->
        <!--                                                    </a>-->
        <!--                                                    <div-->
        <!--                                                        v-if="-->
        <!--                                                            community.subscribers_count-->
        <!--                                                        "-->
        <!--                                                        class="club-card__text"-->
        <!--                                                    >-->
        <!--                                                        &lt;!&ndash;  {{ trans_choice('communities.subscribers', $community->subscribers_count, ['value' => $community->subscribers_count]) }}&ndash;&gt;-->
        <!--                                                        {{-->
        <!--                                                            community.subscribers_count-->
        <!--                                                        }}-->
        <!--                                                    </div>-->
        <!--                                                </div>-->
        <!--                                                <div class="club-card__footer">-->
        <!--                                                                <button-->
        <!--                                                                    @click="auth.register"-->
        <!--                                                                    class="button button_bg_blue button_size_md"-->
        <!--                                                                >Подписаться</button>-->
        <!--                                                </div>-->
        <!--                                            </div>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="section-banner">
            <div class="container">
                <div class="section-banner__wrap">
                    <div class="section-banner__content">
                        <div class="description">
                            <div class="description__logo">
                                <a class="logo" href="/">
                                    <img
                                        class="logo__img"
                                        :src="'/landing/img/logo_white.svg'"
                                        alt="lapki.club"
                                /></a>
                            </div>
                            <div class="description__headline">
                                Новый круг общения твоего питомца
                            </div>
                            <div class="description__text">
                                Присоединяйся и используй все возможности,
                                которые тебе даст новый сервис.<br />От простого
                                общения и экономии времени, до решения важных
                                вопросов и здоровья.
                            </div>
                            <div class="description__footer">
                                <button
                                    @click="auth.register"
                                    class="button button_bg_white"
                                >
                                    Присоединиться
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <div class="container">
                <div class="footer__inner">
                    <a class="logo" href="/">
                        <img
                            class="logo__img"
                            :src="'/img/logo.svg'"
                            alt="lapki.club"
                    /></a>
                </div>
                <div class="footer__inner">
                    <div class="footer__about">
                        <div class="footer__copyright">©2024 LAPKI.CLUB</div>
                        <div class="footer__nav">
                            <div class="row-nav">
                                <div class="row-nav__item">
                                    <a class="row-nav__link" href="/privacy"
                                        >Политика конфидициальности</a
                                    >
                                </div>
                                <div class="row-nav__item">
                                    <a class="row-nav__link" href="/rules"
                                        >Правила использования</a
                                    >
                                </div>
                                <div class="row-nav__item">
                                    <a
                                        class="row-nav__link"
                                        href="/rules/account-management"
                                        >Информация об аккаунте</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer__lang"></div>
                </div>
            </div>
        </footer>

        <Auth ref="auth" />
        <Cookies />
    </div>
</template>

<style>
.button_bg_blue {
    border: 1px solid var(--Base0);
    background-color: var(--Base0);
    color: var(--Gray0);
}
@media (min-width: 1024px) {
    .button_bg_blue:not(:disabled):not(.disabled):hover {
        border-color: var(--Base-15);
        background-color: var(--Base-15);
    }
}
</style>
