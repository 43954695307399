<template>
    <Sockets :auth-id="props.authId" ref="sockets">
        <div class="chat__header">
            <button type="button" class="chat__back" @click="handleBackClick">
                <i class="icon-back"></i>
            </button>
            <div class="chat__pic">
                <img :src="activeRoomAvatar" alt="" />
            </div>
            <div class="chat__headline">
                {{ activeRoomName }}
            </div>
        </div>
        <div class="chat__box">
            <div class="chat__flex">
                <div ref="chatBox"></div>
                <ChatMessage
                    v-for="message in chatStore.messages"
                    :key="message.id"
                    :message="message"
                    :auth-id="props.authId"
                />
                <InfiniteLoading
                    @infinite="loadData"
                    :top="true"
                    :identifier="chatStore.activeRoomId"
                >
                    <template #spinner>
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="
                                border-top-right-radius: 0;
                                margin-left: auto;
                            "
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="border-top-left-radius: 0"
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="
                                border-top-right-radius: 0;
                                margin-left: auto;
                            "
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="
                                border-top-right-radius: 0;
                                margin-left: auto;
                            "
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="
                                border-top-right-radius: 0;
                                margin-left: auto;
                            "
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="border-top-left-radius: 0"
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="border-top-left-radius: 0"
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="border-top-left-radius: 0"
                        />
                        <Skeletor
                            pill
                            class="chat-message chat-message_own"
                            width="40%"
                            height="50px"
                            style="
                                border-top-right-radius: 0;
                                margin-left: auto;
                            "
                        />
                    </template>
                    <template #complete>
                        <span></span>
                    </template>
                </InfiniteLoading>
            </div>
        </div>
        <div class="chat__footer">
            <form class="chat-field" @submit.prevent="sendMessage">
                <div class="chat-field__text">
                    <textarea
                        class="chat-field__textarea"
                        ref="textarea"
                        data-action="textarea"
                        v-model="newMessage"
                        @keydown.enter.prevent="sendMessage"
                        rows="1"
                        placeholder="Напишите сообщение…"
                    ></textarea>
                </div>
                <button
                    type="submit"
                    class="chat-field__send"
                    :class="{ disabled: !newMessage || loading }"
                >
                    <i class="icon-send"></i>
                </button>
            </form>
        </div>
    </Sockets>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useChatStore } from '../../stores/ChatStore'
import { notify } from '@kyvg/vue3-notification'
import InfiniteLoading from 'v3-infinite-loading'
import _, { debounce } from 'lodash'
import { Skeletor } from 'vue-skeletor'
import ChatMessage from './ChatMessage.vue'
import Sockets from '../Sockets.vue'

const handleBackClick = () => {
    chatStore.setActiveRoomId(null)
}

const props = defineProps({
    authId: {
        required: true
    },
    businessId: {
        required: false
    },
    name: {
        required: false
    }
})

const getUserAvatar = (avatar = null) => {
    if (!avatar || avatar === '') {
        return '/img/no-photo.png'
    }
    return avatar
}

const activeRoomAvatar = computed(() => {
    return parseInt(chatStore.activeRoom?.recipient_id) ===
        parseInt(props.authId)
        ? getUserAvatar(chatStore.activeRoom?.user?.avatar)
        : chatStore.activeRoom?.recipient?.avatar ??
              (chatStore.activeRoom?.recipient_type === 'business'
                  ? '/img/organization-empty-logo.svg'
                  : '/img/no-photo.png')
})

const activeRoomName = computed(() => {
    if (props.name) {
        return props.name
    }
    return parseInt(chatStore.activeRoom?.recipient_id) ===
        parseInt(props.authId)
        ? chatStore.activeRoom?.user?.full_name
        : chatStore.activeRoom?.recipient?.full_name ??
              chatStore.activeRoom?.name ??
              chatStore.activeRoom?.recipient?.name
})
const chatStore = useChatStore()
const textarea = ref()

const loading = ref(false)

const emit = defineEmits('onSendMessage')

const newMessage = ref('')
const sendMessage = async (event) => {
    try {
        if (loading.value || !newMessage.value) {
            return
        }
        if (newMessage.value.length > 1000) {
            notify({
                type: 'error',
                text: 'Текст сообщения превышает 1000 символов'
            })
            return
        }
        if (event.ctrlKey) {
            newMessage.value += '\n'
            return
        }
        loading.value = true
        await chatStore.sendMessage(newMessage.value).then(() => {
            newMessage.value = ''
            textarea.value.style.height = 'auto'
            scrollToBottom()
        })
        if (props.name) {
            emit('onSendMessage')
        }
    } catch (err) {
        notify({
            type: 'error',
            title:
                err.response?.data?.message ?? 'Ошибка при отправке соообщения'
        })
    } finally {
        loading.value = false
    }
}

const chatBox = ref(null)
const scrollToBottom = () => {
    chatBox.value?.scrollIntoView({ block: 'end' })
}
const loadData = debounce(async ($state) => {
    console.log('load data with page', chatStore.page)
    // console.log('Load data', chatStore.page)
    chatStore.fetchMessages().then((res) => {
        console.log('res in load data', res)
        if (chatStore.page <= 2) {
            scrollToBottom()
        }
        if (chatStore.page >= res.meta?.last_page) {
            $state.complete()
        }
    })
}, 800)

const onElementVisibility = (state) => {
    console.log('onElementVisibility', state)
}

const sockets = ref()

onMounted(async () => {
    sockets.value.socket.on(
        'App\\Notifications\\Chat\\NewMessageNotification',
        async (data) => {
            if (parseInt(data.notifiable_id) === parseInt(props.authId)) {
                console.log('data', data)
                const message = {
                    id: data?.message_id,
                    user_id: data?.user_id,
                    chat_room_id: data?.chat_room_id,
                    message: data?.message,
                    read_at: null,
                    edited_at: null,
                    created_at_formatted: data?.created_at_formatted
                }
                await chatStore.unshiftMessage(message)
                scrollToBottom()
            }
        }
    )
})
</script>

<style>
.chat__flex {
    display: flex;
    flex-direction: column-reverse;
    cursor: text !important;
}

.chat-field__textarea {
    height: auto;
    max-height: 250px;
    overflow: auto;
    cursor: text;
}

.chat__back {
    @media (min-width: 768px) {
        display: none;
    }
}
</style>
