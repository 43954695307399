<template>
    <Layout @nextStep="handleSubmit" @onExit="handleExit">
        <p style="margin: 0" class="form__extra-text">
            Часовой пояс: {{ currentTimezone }}
        </p>
        <div
            v-for="(item, i) in v$.working_hours.$model"
            class="form__group"
            style="margin-top: 8px"
            :key="i"
        >
            <div class="form__wrap" v-if="!store.loading">
                <div class="week">
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="1"
                        />
                        <span>пн</span>
                    </label>
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="2"
                        />
                        <span>вт</span>
                    </label>
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="3"
                        />
                        <span>ср</span>
                    </label>
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="4"
                        />
                        <span>чт</span>
                    </label>
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="5"
                        />
                        <span>пт</span>
                    </label>
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="6"
                        />
                        <span>сб</span>
                    </label>
                    <label class="week__label">
                        <input
                            type="checkbox"
                            name="week[]"
                            v-model="item.days"
                            :value="0"
                        />
                        <span>вс</span>
                    </label>
                </div>
                <!--      <div class="form__alert form__alert_error" v-if="errorMessage">-->
                <!--        <i class="icon-details"></i> {{ errorMessage }}-->
                <!--      </div>-->
                <div class="range">
                    <div class="range__header">
                        <i class="icon-time"></i>
                        <div class="range__clock">
                            Время работы
                            <span>{{ parseWorkingTime(item.hours[0]) }}</span>
                            <span>-</span>
                            <span v-if="item.hours">{{
                                parseWorkingTime(
                                    item.hours[item.hours.length - 1]
                                )
                            }}</span>
                        </div>
                    </div>
                    <Slider
                        v-model="item.hours"
                        :max="48"
                        :lazy="false"
                        :tooltips="false"
                    />
                </div>
                <button
                    type="button"
                    class="form__button form__button_danger"
                    @click="handleDelete(i)"
                    v-if="working_hours.length > 1"
                >
                    Удалить
                </button>
            </div>
            <Skeletor v-else height="144px" as="div"></Skeletor>
        </div>

        <!--        <div class="form__alert form__alert_error" v-if="errors.length">-->
        <!--            <i class="icon-details"></i> {{ errors[0] }}-->
        <!--        </div>-->

        <div class="form__group">
            <div
                class="form__wrap"
                @click="handleAdd"
                style="cursor: pointer"
                v-if="!store.loading"
            >
                <button type="button" class="form__button">Добавить еще</button>
            </div>
            <Skeletor v-else height="52" as="div"></Skeletor>
            <div
                class="form__alert form__alert_error"
                v-if="v$.working_hours.$errors[0]?.$message"
            >
                <i class="icon-details"></i>
                {{ v$.working_hours.$errors[0]?.$message }}
            </div>
            <div
                class="form__alert form__alert_error"
                v-if="$externalResults.opening_hours.length"
            >
                <i class="icon-details"></i>
                {{ $externalResults.opening_hours[0] }}
            </div>
        </div>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'

import { computed, ref } from 'vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import Slider from '@vueform/slider'
import { useVuelidate } from '@vuelidate/core'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { Skeletor } from 'vue-skeletor'

const store = useBusinessStore()
const working_hours = ref(
    !store.initialValues?.working_hours?.length
        ? [
              {
                  days: [1, 2, 3, 4, 5],
                  hours: [16, 36]
              }
          ]
        : store.initialValues?.working_hours
)

const rules = {
    working_hours: {
        requiredLocalized
    }
}
const $externalResults = ref({
    opening_hours: []
})
const v$ = useVuelidate(
    rules,
    { working_hours: working_hours.value },
    { $externalResults }
)

const handleDelete = (i) => {
    working_hours.value.splice(i, 1)
    v$.value.$clearExternalResults()
}

const currentTimezone = computed(() => {
    return (
        store.initialValues?.timezone ||
        Intl.DateTimeFormat('ru').resolvedOptions().timeZone
    )
})

const handleAdd = () => {
    if (!working_hours.value) {
        working_hours.value = []
    }
    working_hours.value.push({
        days: [1, 2, 3, 4, 5],
        hours: [16, 36]
    })
}

const parseWorkingTime = (item) => {
    let hours = Math.trunc(item / 2)
    let minutes = item % 2 === 0 ? '00' : '30'
    return `${hours}:${minutes}`
}

const handleSubmit = async () => {
    v$.value.$clearExternalResults()
    // if (!v$.value.$anyDirty && store.completedSteps.includes(3)) {
    //     store.nextStep()
    //     return
    // }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    try {
        store.startLoading()
        const {
            data: { data }
        } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
            step: 3,
            working_hours: working_hours.value
        })
        store.setInitialValues(data, 4)
    } catch (error) {
        const errors = error.response.data.errors
        console.log('errors', errors)
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}

const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    const formData = {}
    formData['step'] = 3
    const fields = ['working_hours']
    fields.forEach((field) => {
        if (eval(`${field}.value`)) {
            formData[field] = eval(`${field}.value`)
        }
    })

    console.log('formData', formData)

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            formData
        )
        document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}
</script>

<style>
@import '@vueform/slider/themes/default.css';

:root {
    --slider-handle-width: 20px;
    --slider-handle-height: 20px;
    --slider-connect-bg: var(--Base0);
    --slider-handle-ring-color: rgba(47, 128, 237, 0.188);
}
</style>
