import {defineStore} from 'pinia'
import {ref} from "vue";
import {notify} from "@kyvg/vue3-notification";

export const useFriendshipStore = defineStore('friendship', () => {
    const incomingRequests = ref([])
    const outgoingRequests = ref([])
    const friends = ref([])
    const blockedBy = ref([])
    const blockedMe = ref([])

    function setFriendships(data = null) {
        if (!data) {
            notify({
                type: 'error',
                text: 'Error on setFriendships()'
            })
            return
        }
        incomingRequests.value = data.incomingRequests
        outgoingRequests.value = data.outgoingRequests
        friends.value = data.friends
        blockedBy.value = data.blockedBy
        blockedMe.value = data.blockedMe
    }

    async function api(method, url, params = null) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios[method](url, params)
                // console.log('response', response)
                resolve(response)
            } catch (err) {
                notify({
                    type: 'error',
                    text: err.response?.data?.message ?? err
                })
                reject(err)
            }
        })
    }

    async function fetchFriendships() {
        return api('get', '/api/v1/friends/lists')
            .then(({data}) => {
                setFriendships(data)
            })
    }

    async function offerFriendship(id) {
        await api('post', '/api/v1/friends', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'success',
                text: 'Запрос на дружбу отправлен'
            })
        })
    }

    async function cancelFriendshipOffer(id) {
        await api('post', '/api/v1/friends/delete', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'warning',
                text: 'Запрос на дружбу отменен'
            })
        })
    }

    async function acceptFriendship(id) {
        await api('post', '/api/v1/friends/accept', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'success',
                text: 'Запрос подтвержден'
            })
        })
    }

    async function denyFriendship(id) {
        await api('post', '/api/v1/friends/deny', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'success',
                text: 'Запрос удален'
            })
        })
    }

    async function deleteFriendship(id) {
        await api('post', '/api/v1/friends/delete', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'success',
                text: 'Пользователь удален из друзей'
            })
        })
    }

    async function block(id) {
        await api('post', '/api/v1/friends/block', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'success',
                text: 'Пользователь заблокирован'
            })
        })
    }

    async function unblock(id) {
        await api('post', '/api/v1/friends/unblock', {
            id
        }).then(({data}) => {
            setFriendships(data)
            notify({
                type: 'success',
                text: 'Пользователь разблокирован'
            })
        })
    }

    return {
        incomingRequests,
        outgoingRequests,
        friends,
        blockedBy,
        blockedMe,
        fetchFriendships,
        offerFriendship,
        cancelFriendshipOffer,
        acceptFriendship,
        denyFriendship,
        deleteFriendship,
        block,
        unblock
    }
})
