<template>
    <div class="form__group">
        <div class="form-control" v-if="!loading">
            <textarea
                :name="props.name"
                class="form-control__control"
                :class="{
                    'is-valued': !!props.modelValue,
                    error: props.errorMessage
                }"
                :value="modelValue"
                @input="emit('update:modelValue', $event.target.value)"
                data-action="form-control"
                autocomplete="off"
            ></textarea>
            <div class="form-control__label">{{ label }}</div>
            <div
                class="form__alert form__alert_error"
                v-if="props.errorMessage"
            >
                <i class="icon-details"></i> {{ props.errorMessage }}
            </div>
        </div>
        <Skeletor v-else class="rounded" height="15rem" as="div"></Skeletor>
        <slot name="before-label"></slot>
        <div class="form__group">
            <div
                class="form__extra-text"
                v-if="extraText && !loading"
                v-html="extraText"
            ></div>
            <div
                v-if="props.limit"
                class="form__qnt"
                style="margin-top: 0 !important"
                :class="{ error: props.modelValue?.length >= props.limit }"
            >
                {{ props.modelValue?.length ?? 0 }} / {{ props.limit }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { Skeletor } from 'vue-skeletor'
import { toRef } from 'vue'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String
    },
    loading: {
        type: Boolean,
        required: false
    },
    extraText: {
        type: String
    },
    modelValue: {
        type: String
    },
    errorMessage: {
        type: String
    },
    limit: {
        type: Number,
        required: false
    }
})

const loading = toRef(props, 'loading')
const emit = defineEmits(['update:modelValue'])
</script>

<style scoped>
.rounded {
    border-radius: 6px;
}
</style>
