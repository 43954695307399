<template>
    <teleport to="body">
        <div class="modal" id="modal-login-email">
            <div class="modal__content" data-modal="modal-content">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__back"
                        data-action="modal-back"
                    >
                        <i class="icon-back"></i>
                    </button>
                    <button
                        type="button"
                        class="modal__close"
                        data-action="modal-close"
                        data-target="#modal-login-email"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__inner">
                    <div class="modal__header">
                        <div class="logo">
                            <img
                                :src="'/img/logo.svg'"
                                alt="lapki.club"
                                class="logo__img"
                            />
                        </div>
                        <div class="modal__headline" id="send_password_text">
                            Мы отправили вам пароль
                        </div>
                    </div>
                    <div class="modal__body">
                        <form @submit.prevent="onSubmit" class="form">
                            <TextInput
                                name="password"
                                :loading="loading"
                                @onInput="handleInput"
                                ref="input"
                                label="Одноразовый пароль"
                            />
                            <div class="form__footer">
                                <div class="form__group">
                                    <button
                                        type="submit"
                                        class="button"
                                        :class="{ 'is-wait': loading }"
                                        @click="onSubmit"
                                    >
                                        Войти
                                    </button>
                                </div>
                                <div class="form__group">
                                    <button
                                        type="button"
                                        class="form__link"
                                        @click="handleResendCode"
                                    >
                                        Отправить код еще раз?
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form method="POST" :action="action" ref="form">
                            <input type="hidden" name="_token" :value="csrf" />
                            <input
                                type="hidden"
                                name="device_token"
                                :value="deviceToken"
                            />
                            <input
                                type="hidden"
                                name="email"
                                id="login_email"
                                ref="email"
                            />
                            <input
                                type="hidden"
                                name="password"
                                :value="values.password"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
import { inject, ref } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import TextInput from '../Inputs/TextInput.vue'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import { notify } from '@kyvg/vue3-notification'

const csrf = inject('csrf')
const props = defineProps({
    action: {
        type: String,
        required: true
    }
})
const form = ref()
const input = ref()
const deviceToken = ref()
const password = ref()
const email = ref()
const loading = ref(false)

const schema = yup.object({
    password: yup.string().required('Надо ввести Пароль')
})
const { handleSubmit, values, resetForm, setErrors, setFieldError, errors } =
    useForm({
        validationSchema: schema
    })

const handleResendCode = async () => {
    try {
        loading.value = true
        const { data } = await axios.post('/api/v1/auth/send-password', {
            email: email.value.value
        })
        if (!data.success) {
            setErrors(data.errors)
        }
        notify({
            type: 'success',
            title: 'Код отправлен повторно!'
        })
    } catch (err) {
        console.log('err', err)
        const errors = err.response?.data?.errors
        console.log('errors', errors)
        if (errors) {
            setErrors(errors)
        } else {
            let error = 'Что-то пошло не так'
            if (err.response?.status === 429) {
                error = 'Слишком много попыток'
            } else {
                error = err.response?.data?.message ?? error
            }
            setFieldError('password', error)
        }
    } finally {
        loading.value = false
    }
}

// const onSubmit = handleSubmit(async (values) => {
//     try {
//         loading.value = true
//         const { data } = await axios.post('/api/v1/auth/check-password', {
//             email: email.value.value,
//             password: values.password
//         })
//         console.log('data', data)
//         if (!data.success) {
//             setErrors(data.errors)
//         } else {
//             localStorage.setItem('token', data.token ?? null)
//             await getDeviceToken()
//         }
//     } catch (err) {
//         console.log('err', err)
//         const errors = err.response?.data?.errors
//         console.log('errors', errors)
//         if (errors) {
//             setErrors(errors)
//         } else {
//             let error = 'Что-то пошло не так'
//             if (err.response?.status === 429) {
//                 error = 'Слишком много попыток'
//             } else {
//                 error = err.response?.data?.message ?? error
//             }
//             setFieldError('password', error)
//         }
//     } finally {
//         loading.value = false
//     }
// })

const onSubmit = () => {
    console.log('sd')
}

const setFocus = () => {
    input.value.setFocus()
}
defineExpose({
    setFocus
})

const handleInput = (event) => {
    let value = event.target.value
    const regex = /\d{0,4}/g
    values.password = value.match(regex)?.[0]
}

const getDeviceToken = async () => {
    loading.value = true
    console.log('Mounted FirebaseCloudMessaging')
    const firebaseConfig = {
        apiKey: 'AIzaSyAX2QFZ-fw1elujeBtf8GzlOyMIlx2wFIU',
        authDomain: 'lapki-club.firebaseapp.com',
        projectId: 'lapki-club',
        storageBucket: 'lapki-club.appspot.com',
        messagingSenderId: '946554576978',
        appId: '1:946554576978:web:1f8c623e3792eb2b05bf8b',
        measurementId: 'G-9YF5ZS6NYZ'
    }
    // Initialize Firebase
    const app = initializeApp(firebaseConfig)
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app)
    // Add the public key generated from the console here.
    await getToken(messaging, {
        vapidKey:
            'BJVm-Un0IOuX2YabeR_0ETkT7w4FHpn91duGz2BdogS5i0ceGaFg8gQNh73P1bjiDLY4b3yb_5lXX2zwSx-rU8k'
    })
        .then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log('currentToken:', currentToken)
                deviceToken.value = currentToken
                form.value.device_token.value = currentToken
            } else {
                // Show permission request UI
                console.log(
                    'No registration token available. Request permission to generate one.'
                )
                // ...
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // ...
        })
        .finally(() => {
            form.value.submit()
            loading.value = false
        })
}
</script>

<!--<script>-->
<!--import {useVuelidate} from "@vuelidate/core";-->
<!--import {required} from '@/utils/i18n-validators'-->
<!--import {IMaskComponent} from "vue-imask";-->
<!--import {initializeApp} from "firebase/app";-->
<!--import {getMessaging, getToken} from "firebase/messaging";-->

<!--export default {-->
<!--    name: "ModalEnterCode",-->

<!--    setup() {-->
<!--        return {v$: useVuelidate()}-->
<!--    },-->

<!--    components: {-->
<!--        'imask-input': IMaskComponent,-->
<!--    },-->

<!--    validations() {-->
<!--        return {-->
<!--            password: {required},-->
<!--        }-->
<!--    },-->

<!--    props: {-->
<!--        action: {-->
<!--            type: String,-->
<!--            required: true,-->
<!--        },-->
<!--        csrf: {-->
<!--            type: String,-->
<!--            required: true,-->
<!--        }-->
<!--    },-->

<!--    data() {-->
<!--        return {-->
<!--            loading: false,-->
<!--            password: '',-->
<!--            vuelidateExternalResults: {-->
<!--                password: [],-->
<!--                email: []-->
<!--            },-->
<!--            device_token: ''-->
<!--        }-->
<!--    },-->

<!--    methods: {-->
<!--        setFocus() {-->
<!--            this.$nextTick(() => {-->
<!--                console.log('dd in enter code')-->
<!--                try {-->
<!--                    this.$refs.input.$el.focus()-->
<!--                } catch (e) {-->
<!--                    console.log('Err', e)-->
<!--                }-->
<!--            })-->
<!--        },-->
<!--        clearErrors() {-->
<!--            this.v$.$clearExternalResults()-->
<!--        },-->
<!--        onAcceptPassword(val) {-->
<!--            this.password = val-->
<!--        },-->
<!--        async handleResendCode() {-->
<!--            try {-->
<!--                const {data} = await axios.post('/api/v1/auth/send-password', {-->
<!--                    email: this.$refs.email.value,-->
<!--                })-->
<!--                console.log('data resend', data)-->
<!--                if (data.success) {-->
<!--                    console.log('Notify')-->
<!--                    this.$notify({-->
<!--                        type: 'success',-->
<!--                        title: 'Код отправлен повторно!'-->
<!--                    })-->
<!--                }-->
<!--            } catch (err) {-->
<!--                const errors = err.response?.data?.errors-->
<!--                if (errors) {-->
<!--                    Object.assign(this.vuelidateExternalResults, errors)-->
<!--                } else {-->
<!--                    Object.assign(this.vuelidateExternalResults, {password: [err.response?.data?.message]})-->
<!--                }-->
<!--            }-->
<!--        },-->
<!--        async handleSubmit() {-->
<!--            this.loading = true-->
<!--            this.v$.$clearExternalResults()-->
<!--            const isFormCorrect = await this.v$.$validate()-->
<!--            if (!isFormCorrect) {-->
<!--                this.loading = false-->
<!--                return-->
<!--            }-->
<!--            try {-->
<!--                const {data} = await axios.post('/api/v1/auth/check-password', {-->
<!--                    'email': this.$refs.email.value,-->
<!--                    'password': this.password,-->
<!--                })-->
<!--                console.log('data', data)-->
<!--                if (!data.success) {-->
<!--                    console.log('data.errors', data.errors)-->
<!--                    Object.assign(this.vuelidateExternalResults, data.errors)-->
<!--                    this.loading = false-->
<!--                } else {-->
<!--                    console.log('Here we')-->
<!--                    await this.getDeviceToken()-->
<!--                    console.log('this.device_token', this.device_token)-->
<!--                }-->
<!--            } catch (err) {-->
<!--                console.log('err', err)-->
<!--                this.loading = false-->
<!--                const errors = err.response?.data?.errors-->
<!--                console.log('errors', errors)-->
<!--                if (errors) {-->
<!--                    Object.assign(this.vuelidateExternalResults, errors)-->
<!--                } else {-->
<!--                    let error = 'Что-то пошло не так'-->
<!--                    if (err.response?.status === 429) {-->
<!--                        error = 'Слишком много попыток'-->
<!--                    } else {-->
<!--                        error = err.response?.data?.message ?? 'Что-то пошло не так'-->
<!--                    }-->
<!--                    Object.assign(this.vuelidateExternalResults, {password: [error]})-->
<!--                }-->
<!--            }-->
<!--        },-->

<!--        async getDeviceToken() {-->
<!--            console.log('Mounted FirebaseCloudMessaging')-->

<!--            const firebaseConfig = {-->
<!--                apiKey: "AIzaSyAX2QFZ-fw1elujeBtf8GzlOyMIlx2wFIU",-->
<!--                authDomain: "lapki-club.firebaseapp.com",-->
<!--                projectId: "lapki-club",-->
<!--                storageBucket: "lapki-club.appspot.com",-->
<!--                messagingSenderId: "946554576978",-->
<!--                appId: "1:946554576978:web:1f8c623e3792eb2b05bf8b",-->
<!--                measurementId: "G-9YF5ZS6NYZ"-->
<!--            };-->

<!--            // Initialize Firebase-->
<!--            const app = initializeApp(firebaseConfig);-->

<!--            // Initialize Firebase Cloud Messaging and get a reference to the service-->
<!--            const messaging = getMessaging(app);-->

<!--// Add the public key generated from the console here.-->
<!--            await getToken(messaging, {vapidKey: "BJVm-Un0IOuX2YabeR_0ETkT7w4FHpn91duGz2BdogS5i0ceGaFg8gQNh73P1bjiDLY4b3yb_5lXX2zwSx-rU8k"}).then((currentToken) => {-->
<!--                if (currentToken) {-->
<!--                    // Send the token to your server and update the UI if necessary-->
<!--                    console.log('currentToken:', currentToken);-->
<!--                    this.device_token = currentToken-->
<!--                    this.$refs.form.device_token.value = currentToken-->
<!--                    // console.log('this.$refs.form', this.$refs.form.device_token.value);-->

<!--                } else {-->
<!--                    // Show permission request UI-->
<!--                    console.log('No registration token available. Request permission to generate one.');-->
<!--                    // ...-->
<!--                }-->
<!--            }).catch((err) => {-->
<!--                console.log('An error occurred while retrieving token. ', err);-->
<!--                // ...-->
<!--            }).finally(() => {-->
<!--                this.$refs.form.submit()-->
<!--            });-->

<!--            // console.log('Requesting permission...');-->
<!--            // Notification.requestPermission().then((permission) => {-->
<!--            //     if (permission === 'granted') {-->
<!--            //         console.log('Notification permission granted.');-->
<!--            //     }-->
<!--            // })-->
<!--        }-->
<!--    }-->
<!--}-->
<!--</script>-->
