<template>
    <div
        class="chat-message"
        :class="{
            'chat-message_own':
                parseInt(props.message.user_id) === parseInt(props.authId)
        }"
    >
        <Waypoint @change="onChange">
            <div class="chat-message__content">
                <div class="chat-message__info">
                    <div class="chat-message__meta">
                        {{ props.message.created_at_formatted }}
                    </div>
                </div>
                <div class="chat-message__text">
                    {{ props.message.message }}
                </div>
            </div>
        </Waypoint>
    </div>
</template>

<script setup>
import { Waypoint } from 'vue-waypoint'
import { debounce } from 'lodash'
import { useChatStore } from '../../stores/ChatStore'

const props = defineProps({
    message: {
        type: Object,
        required: true
    },
    authId: {
        type: String
    }
})
const chatStore = useChatStore()
const onChange = debounce(async function (waypointState) {
    if (
        !!props.message.read_at ||
        parseInt(props.authId) === parseInt(props.message.user_id)
    ) {
        return
    }
    if (waypointState.going === 'IN') {
        await chatStore.readMessage(props.message.id)
    }
}, 500)
</script>
