<template>
    <Layout section="contacts">
        <div class="constructor__content">
            <div class="constructor__header">
                <div class="constructor__headline">Контакты</div>
            </div>

            <div class="form">
                <div class="form__set">
                    <div class="form__strokes">
                        <CheckboxInput
                            label="Выезд на дом"
                            v-model="v$.home_visit.$model"
                            :loading="store.loading"
                            :errorMessage="v$.home_visit.$errors[0]?.$message"
                        />
                        <CheckboxInput
                            label="Есть офис"
                            v-model="v$.has_office.$model"
                            :loading="store.loading"
                            :errorMessage="v$.has_office.$errors[0]?.$message"
                        />
                    </div>
                    <CityIdInput
                        v-if="!has_office"
                        label="Город предоставления услуг"
                        :initial-city="store.initialValues?.city"
                        :loading="store.loading"
                        v-model="v$.city_id.$model"
                        :errorMessage="v$.city_id.$errors[0]?.$message"
                    />
                    <AddressInput
                        v-if="has_office"
                        :coordinates="coordinates"
                        @updateAddress="handleUpdateAddress"
                        :office_address="v$.office_address.$model"
                        :loading="store.loading"
                        :office-additional="v$.office_additional.$model"
                        :office-additional-error-message="
                            v$.office_additional.$errors[0]?.$message
                        "
                        @updateOfficeAdditional="handleUpdateOfficeAdditional"
                        address-label="Адрес"
                    />
                </div>
                <div class="form__set">
                    <div class="form__group">
                        <div class="form__extra-text" v-if="!store.loading">
                            Укажите телефон и email, используемые для контакта с
                            клиентами.
                        </div>
                    </div>
                    <!--                                <TextInput label="Номер телефона" name="phone"/>-->
                    <!--                <PhoneInput label="Номер телефона" name="phone" />-->
                    <TextInput
                        label="Email"
                        v-model="v$.email.$model"
                        :loading="store.loading"
                        :errorMessage="v$.email.$errors[0]?.$message"
                    />
                    <PhoneInput
                        label="Телефон"
                        v-model="v$.phone.$model"
                        :loading="store.loading"
                        :errorMessage="v$.phone.$errors[0]?.$message"
                    />
                    <TextInput
                        label="Веб-сайт (необязательно)"
                        v-model="v$.site.$model"
                        :loading="store.loading"
                        :errorMessage="v$.site.$errors[0]?.$message"
                    />
                </div>
            </div>

            <FormFooter
                :loading="store.loading"
                @onSubmit="handleSubmit"
                @onCancel="handleReset"
            />
        </div>
    </Layout>
</template>

<script setup>
import * as yup from 'yup'
import useEdit from './useEdit'
import { onMounted, provide, ref } from 'vue'
import FormFooter from './FormFooter.vue'
import Layout from './Layout.vue'
import CheckboxInput from '../inputs/CheckboxInput.vue'
import CityIdInput from '../inputs/CityIdInput.vue'
import AddressInput from '../inputs/AddressInput.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { boolean } from '../../../rules/boolean'
import { requiredIfLocalized } from '../../../rules/requiredIfLocalized'
import { emailLocalized } from '../../../rules/emailLocalized'
import { maxLengthLocalized } from '../../../rules/maxLengthLocalized'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import TextInput from '../inputs/TextInput.vue'
import PhoneInput from '../inputs/PhoneInput.vue'

const store = useBusinessStore()

const props = defineProps({
    authUser: {
        required: true
    },
    business: {
        required: true
    }
})

const home_visit = ref(
    props.business?.home_visit ?? store.initialValues?.home_visit
)
const has_office = ref(
    props.business?.has_office ?? store.initialValues?.has_office
)
const city_id = ref(props.business?.city_id ?? store.initialValues?.city_id)
const email = ref(props.business?.email ?? store.initialValues?.email)
const site = ref(props.business?.site ?? store.initialValues?.site)
const phone = ref(props.business?.phone ?? store.initialValues?.phone)
const coordinates = ref(
    props.business?.coordinates?.length
        ? props.business?.coordinates
        : props.business?.city?.center
)
const office_address = ref(
    props.business?.office_address ?? store.initialValues?.office_address
)
const office_additional = ref(
    props.business?.office_additional ?? store.initialValues?.office_additional
)

const rules = {
    home_visit: {
        requiredLocalized,
        boolean
    },
    has_office: {
        requiredLocalized,
        boolean
    },
    city_id: {
        requiredLocalized
    },
    phone: {
        requiredLocalized
    },
    coordinates: {
        requiredIfLocalized: requiredIfLocalized(has_office)
    },
    email: {
        requiredLocalized,
        emailLocalized
    },
    site: {
        maxLengthLocalized: maxLengthLocalized(255)
    },
    office_address: {
        maxLengthLocalized: maxLengthLocalized(500)
    },
    office_additional: {
        maxLengthLocalized: maxLengthLocalized(1000)
    }
}

onMounted(() => {
    store.setInitialValues(props.business)
})

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    {
        home_visit,
        has_office,
        city_id,
        email,
        site,
        office_address,
        coordinates,
        phone,
        office_additional
    },
    { $externalResults }
)

const handleSubmit = async () => {
    if (!v$.value.$anyDirty && store.completedSteps.includes(2)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    axios
        .post(`/api/v1/business/${store.initialValues.id}`, {
            step: 2,
            home_visit: home_visit.value,
            has_office: has_office.value,
            city_id: city_id.value,
            office_address: office_address.value,
            coordinates: coordinates.value,
            email: email.value,
            office_additional: office_additional.value,
            site: site.value,
            phone: phone.value.replaceAll(' ', '').replaceAll('-', '')
        })
        .then((res) => {
            console.log('res', res)
            notify({
                type: 'success',
                text: 'Бизнес успешно обновлен'
            })
            store.setInitialValues(res.data.data, 3)
        })
        .catch((error) => {
            const errors = error.response.data.errors
            console.log('errors', errors)
            if (!errors) {
                notify({
                    type: 'error',
                    text: error.message
                })
                return
            }
            $externalResults.value = errors
        })
        .finally(() => {
            store.stopLoading()
        })

    // try {
    //     store.startLoading()
    //     const {
    //         data: { data }
    //     } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
    //         step: 2,
    //         home_visit: home_visit.value,
    //         has_office: has_office.value,
    //         city_id: city_id.value,
    //         office_address: office_address.value,
    //         coordinates: coordinates.value,
    //         email: email.value,
    //         office_additional: office_additional.value,
    //         site: site.value,
    //         phone: phone.value.replaceAll(' ', '').replaceAll('-', '')
    //     })
    //     store.setInitialValues(data, 3)
    // } catch (error) {

    // } finally {
    //     store.stopLoading()
    // }
}

const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    const formData = {}
    formData['step'] = 2
    const fields = [
        'home_visit',
        'has_office',
        'city_id',
        'email',
        'site',
        'coordinates',
        'phone',
        'office_address',
        'office_additional'
    ]
    fields.forEach((field) => {
        if (eval(`${field}.value`)) {
            formData[field] = eval(`${field}.value`)
        }
    })

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            formData
        )
        // document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}

const handleUpdateAddress = (address) => {
    office_address.value = address.result
    coordinates.value = address.center
}

const handleUpdateOfficeAdditional = (value) => {
    office_additional.value = value
    v$.value.office_additional.$touch()
}

const handleReset = async () => {
    home_visit.value =
        props.business?.home_visit ?? store.initialValues?.home_visit
    has_office.value =
        props.business?.has_office ?? store.initialValues?.has_office
    city_id.value = props.business?.city_id ?? store.initialValues?.city_id
    email.value = props.business?.email ?? store.initialValues?.email
    site.value = props.business?.site ?? store.initialValues?.site
    phone.value = props.business?.phone ?? store.initialValues?.phone
    coordinates.value = props.business?.coordinates?.length
        ? props.business?.coordinates
        : props.business?.city?.center

    office_address.value =
        props.business?.office_address ?? store.initialValues?.office_address

    office_additional.value =
        props.business?.office_additional ??
        store.initialValues?.office_additional

    v$.value.$reset()
}
</script>
