<script setup>
import PostInList from './PostInList.vue'
import { computed, ref } from 'vue'
import PostSkeletor from './PostSkeletor.vue'
import InfiniteLoader from '../InfiniteLoader.vue'

const props = defineProps({
    authUserId: {
        type: [String, Number]
    },
    userId: {
        type: [String, Number]
    },
    url: {
        type: String
    },
    query: {
        type: Array,
        default: () => []
    }
})

const posts = ref([])

let page = ref(1)
const load = async (data) => {
    posts.value.push(...data)
    page.value++
}

const urlWithQuery = computed(() => {
    const params = new URLSearchParams({
        ...props.query,
        page: page.value
    })
    return `${props.url}/?${params}`
})
</script>

<template>
    <InfiniteLoader class="post-wall" :url="urlWithQuery" @onLoad="load">
        <template #default>
            <PostInList
                v-for="post in posts"
                :key="post.id"
                :post="post"
                :auth-user-id="props.authUserId"
            />
        </template>
        <template #loader>
            <PostSkeletor v-for="i in 3" :key="i" />
        </template>
        <template #error> Не удалось обновить ленту постов :( </template>
        <template #complete>
            <slot name="complete">
                <span v-if="posts.length"></span>
                <div v-else class="specialty-item">
                    <div class="specialty-item__pic">
                        <slot name="complete-img">
                            <img :src="'/img/special-dog.svg'" alt="" />
                        </slot>
                    </div>
                    <div class="specialty-item__text">
                        <slot name="complete-text">
                            {{
                                authUserId === userId
                                    ? 'Делитесь опытом и эмоциями!'
                                    : 'У пользователя пока нет никакаих публикаций'
                            }}
                        </slot>
                    </div>
                </div>
            </slot>
        </template>
    </InfiniteLoader>
</template>
