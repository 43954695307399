<script setup>
import { computed, ref } from 'vue'
import InfiniteLoader from '../InfiniteLoader.vue'
import CommunityCard from './CommunityCard.vue'
import CommunitySkeletor from './CommunitySkeletor.vue'
import qs from 'qs'

const communities = ref([])

let page = ref(1)
const load = async (data) => {
    communities.value.push(...data)
    page.value++
}

const url = computed(() => {
    const params = {}
    const url = new URL(document.location.href)
    url.searchParams.forEach((value, key) => {
        params[key] = value
    })
    params['page'] = page.value
    return `/api/v1/communities/?${qs.stringify(params)}`
})
</script>

<template>
    <InfiniteLoader :url="url" @onLoad="load">
        <template #default>
            <div class="section__grid">
                <CommunityCard
                    v-for="community in communities"
                    :key="community.id"
                    :community="community"
                />
            </div>
        </template>
        <template #loader>
            <div class="section__grid" style="margin-top: 15px">
                <CommunitySkeletor v-for="i in 10" :key="i" />
            </div>
        </template>
        <template #error> Не удалось обновить список сообществ :( </template>
    </InfiniteLoader>
</template>
