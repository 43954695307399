<template>
    <div class="search-filter__inner">
        <div class="search-filter__header">
            <div class="search-filter__headline">Фильтры</div>
            <button
                type="button"
                class="search-filter__close"
                data-action="filter-close"
            >
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="search-filter__body">
            <div class="search-filter__group">
                <div class="search-filter__title">Местоположение</div>
                <CityInput v-model="city" clearable />
            </div>
        </div>
        <div class="search-filter__footer">
            <button
                type="button"
                @click="handleReset"
                class="button button_bg_gray"
            >
                Сбросить
            </button>
            <button type="button" @click="handleApply" class="button">
                Показать
            </button>
        </div>
    </div>
</template>

<script>
import CityInput from '@/components/Search/CityInput.vue'

export default {
    name: 'UsersFilter',

    components: {
        CityInput
    },

    data() {
        return {
            city: null
        }
    },

    methods: {
        handleReset() {
            const url = new URL(document.location.href)
            url.searchParams.delete('city')
            document.location.href = url.href
        },
        handleApply() {
            const url = new URL(document.location.href)
            if (this.city && this.city.id) {
                url.searchParams.append('city', this.city.id)
            } else {
                url.searchParams.delete('city')
            }
            document.location.href = url.href
        }
    }
}
</script>
