<template>
    <div class="photo-add">
        <div class="photo-add__pic" v-if="!loading">
            <img :src="src" alt="" />
            <label class="photo-add__add">
                <input
                    :name="props.name"
                    type="file"
                    class="photo-add__input"
                    @change="handleFileChange"
                    :title="props.label"
                    accept="image/jpeg,image/png,image/heic"
                />
                <i class="icon-camera"></i>
            </label>
        </div>
        <Skeletor v-else class="photo-add__pic" circle></Skeletor>
        <div class="photo-add__text" v-if="!loading">
            Вам необходимо загрузить логотип компании. Если услуга оказывается
            лично можете также использовать ваше фото. Изображение должно быть
            размером до 5mb в форматах .jpg и .png.
            <div
                class="form__alert form__alert_error"
                v-if="props.errorMessage"
            >
                <i class="icon-details"></i> {{ props.errorMessage }}
            </div>
        </div>
        <Skeletor v-else class="photo-add__text"></Skeletor>
    </div>
    <Modal name="modal">
        <template #header>
            <div class="modal__headline">
                Загрузка изображения <br />бизнеса
            </div>
        </template>
        <Cropper
            ref="cropper"
            :src="file"
            class="cropper"
            image-restriction="fit-area"
            :stencil-props="{ aspectRatio: 1 }"
            @change="handleCrop"
        />
        <p class="form__extra-text">
            Вы можете загрузить изображение размером до 5mb в форматах .jpg и
            .png.
        </p>
        <label class="form-photo" style="margin-top: 12px">
            <span class="form-photo__pic"></span>
            <span class="form-photo__text" style="justify-content: center"> Выбрать другое изображение </span>
            <input
                type="file"
                @change="handleFileChange"
                class="photo-add__input"
            />
        </label>
        <template #footer>
            <div class="form__controls">
                <button type="button" class="button" @click="onSelect">
                    Загрузить
                </button>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { computed, ref, toRef } from 'vue'
import { Skeletor } from 'vue-skeletor'
import Modal from '../../Modal.vue'
import { $vfm } from 'vue-final-modal'
import { notify } from '@kyvg/vue3-notification'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String
    },
    loading: {
        required: false
    },
    modelValue: {
        type: String
    },
    errorMessage: {
        type: String
    },
    imagePlaceholder: {
        type: String
    }
})

const loading = toRef(props, 'loading')
const src = computed(() => {
    return !!props.modelValue ? props.modelValue : props.imagePlaceholder
})

const file = ref(null)
const handleFileChange = (f) => {
    let reader = new FileReader()
    reader.onload = (e) => {
        file.value = e.target.result
    }
    reader.readAsDataURL(f.target.files[0])
    $vfm.show('modal')
}

const cropper = ref(null)
const result = ref(null)
const handleCrop = () => {
    const { canvas } = cropper.value.getResult()
    try {
        canvas.toBlob((blob) => {
            result.value = blob
        })
    } catch (e) {
        notify({
            type: 'error',
            text: 'Что-то пошло не так при обрезке фото'
        })
    }
}

const cancel = () => {
    file.value = null
    $vfm.hide('modal')
}
const emit = defineEmits(['update:modelValue', 'select'])
const onSelect = () => {
    let reader = new FileReader()
    reader.onload = (e) => {
        emit('update:modelValue', e.target.result)
        emit('select', result.value)
    }
    reader.readAsDataURL(result.value)
    $vfm.hide('modal')
}
</script>
