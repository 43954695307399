<template>
    <div class="birthday">
        <div class="birthday__label">Дата рождения</div>
        <div class="birthday__container">
            <DayInput v-model="day" />
            <MonthInput v-model="month" />
            <YearInput v-model="year" />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import DayInput from './DateInput/DayInput.vue'
import MonthInput from './DateInput/MonthInput.vue'
import YearInput from './DateInput/YearInput.vue'

const day = ref(null)
const month = ref(null)
const year = ref(null)

const props = defineProps({
    modelValue: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['update:modelValue'])

const date = computed(() => {
    if (!(day.value && month.value && year.value)) {
        return null
    }
    const d = new Date(year.value, month.value - 1, day.value)
    return d instanceof Date ? d : null
})

watch(date, async (val) => {
    if (val instanceof Date) {
        const d = String(val.getDate()).padStart(2, '0')
        const m = String(val.getMonth() + 1).padStart(2, '0')
        const y = val.getFullYear()
        await emit('update:modelValue', `${d}.${m}.${y}`)
    }
})

onMounted(() => {
    if (!props.modelValue) {
        return
    }
    const [d, m, y] = props.modelValue.split('.')
    day.value = parseInt(d)
    month.value = parseInt(m)
    year.value = parseInt(y)
})
</script>

<style lang="scss">
.birthday {
    &__container {
        display: grid;
        grid-template-columns: 20% 55% 25%;
        margin-top: 6px;
        gap: 5px;
    }
    &__label {
        text-align: left;
        color: var(--Gray50);
        font-size: 1.7rem;
    }
}
</style>
