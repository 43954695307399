<template>
    <notifications width="538" :duration="6000">
        <template #body="props">
            <div
                class="toast-informer is-show"
                :class="`toast-informer_${props.item.type}`"
            >
                <button
                    type="button"
                    class="toast-informer__close"
                    @click="props.close"
                >
                    <span class="icon-close"></span>
                </button>
                <div class="toast-informer__inner">
                    <div class="toast-informer__icon">
                        <span
                            :class="`icon-${iconClass(props.item.type)}`"
                        ></span>
                    </div>
                    <div class="toast-informer__content">
                        <div
                            class="toast-informer__title"
                            v-if="props.item.title"
                        >
                            {{ props.item.title }}
                        </div>
                        <div
                            class="toast-informer__text"
                            v-if="props.item.text"
                            v-html="props.item.text"
                        ></div>
                    </div>
                </div>
            </div>
        </template>
    </notifications>
</template>

<style>
.toast-informer__inner {
    display: flex;
    padding: 20px 60px 20px 20px;
    gap: 22px;
    font-size: 26px;
    align-items: center;
}

.toast-informer__icon {
    position: relative;
    top: 0;
    left: 0;
    font-size: 26px;
}

.toast-informer__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.toast-informer__text {
    margin: 0 !important;
}
</style>

<script setup lang="ts">
const iconClass = (type) => {
    if (type === 'success') {
        return 'checked'
    }
    return 'details'
}
</script>
