<template>
    <Layout hide-footer hide-header>
        <div class="constructor-success">
            <div class="constructor-success__icon">✨</div>
            <div class="constructor-success__header">
                <div class="constructor-success__headline">Спасибо!</div>
            </div>
            <div class="constructor-success__info">
                <div class="constructor-success__text">
                    <p>
                        Для подтверждения вашего бизнес профиля перейдите по
                        ссылке в письме высланном на почту
                        <span>{{ store.initialValues?.email }}</span
                        >.
                    </p>
                    <p>
                        После этого в течение 24 часов мы проверим данные на
                        вашей странице и сделаем ее доступной для публикации.
                    </p>
                    <p>
                        Если письмо с подтверждением не пришло или вы хотите
                        указать другую почту нажмите
                        <span
                            style="cursor: pointer"
                            @click="showChangeEmailModal"
                            >здесь</span
                        >.
                    </p>
                </div>
            </div>
            <div class="constructor-success__footer">
                <!--                                            <div class="constructor-success__text">А пока вы можете настроить расписание ваших сотрудников.</div>-->
                <a
                    href="/"
                    type="button"
                    class="button button_bg_gray button_size_sm"
                    >На главную</a
                >
            </div>
        </div>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { $vfm } from 'vue-final-modal'
import ChangeEmailModal from '../../../../views/components/modal/business/ChangeEmailModal.vue'

const store = useBusinessStore()

const showChangeEmailModal = () => {
    $vfm.show({
        component: ChangeEmailModal
    })
}
</script>
