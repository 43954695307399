<template>
    <div class="search-filter__inner">
        <div class="search-filter__header">
            <div class="search-filter__headline">Фильтры</div>
            <button
                type="button"
                class="search-filter__close"
                data-action="filter-close"
            >
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="search-filter__body">
            <div class="search-filter__group">
                <CityInput v-model="city" clearable />
            </div>
            <div class="search-filter__group">
                <div class="search-filter__title">Тип</div>
                <div class="search-filter__list">
                    <!--                    <label class="radio radio_align_right items-center">-->
                    <!--                        <span class="type__icon">-->
                    <!--                            <img :src="'/img/pet-other.svg'" alt=""/>-->
                    <!--                        </span>-->
                    <!--                        <input type="radio" class="radio__input" v-model="type" name="type" value="all">-->
                    <!--                        <span class="radio__mark"></span>-->
                    <!--                        <span class="radio__text">Не важно</span>-->
                    <!--                    </label>-->
                    <label
                        class="radio radio_align_right items-center"
                        v-for="item in types"
                        :key="item.id"
                    >
                        <span class="type__icon">
                            <img :src="`/img/pet-${item.slug}.svg`" alt="" />
                        </span>
                        <input
                            type="radio"
                            class="radio__input"
                            v-model="type"
                            name="type"
                            :value="item.slug"
                        />
                        <span class="radio__mark"></span>
                        <span class="radio__text">{{ item.title }}</span>
                    </label>
                </div>
            </div>
            <div class="search-filter__group">
                <div class="search-filter__title">Порода</div>
                <VueMultiselect
                    v-model="breed"
                    name="breed"
                    :loading="loading"
                    @search-change="handleSearch"
                    placeholder="Выберите породу"
                    :allow-empty="false"
                    @select="handleSelectBreed"
                    :show-labels="false"
                    :custom-label="
                        (option) =>
                            breeds.find((item) => item.slug === option)
                                ? breeds.find((item) => item.slug === option)
                                      .title
                                : ''
                    "
                    :options="breeds.map((item) => item.slug)"
                >
                    <template v-slot:noResult
                        >Пород, удовлетворяющих условию поиска не найдено
                    </template>
                    <template v-slot:noOptions>Начните вводить породу</template>
                    <template v-slot:clear v-if="breed">
                        <i
                            class="icon-close icon-clear"
                            @mousedown.prevent.stop="clearBreed"
                        ></i>
                    </template>
                    <template v-slot:caret v-if="breed">
                        <div></div>
                    </template>
                </VueMultiselect>
            </div>
            <div class="search-filter__group">
                <div class="search-filter__title">Пол</div>
                <div class="search-filter__list">
                    <label class="radio radio_align_right">
                        <input
                            type="radio"
                            class="radio__input"
                            v-model="sex"
                            name="sex"
                            value="all"
                        />
                        <span class="radio__mark"></span>
                        <span class="radio__text">Не важно</span>
                    </label>
                    <label class="radio radio_align_right">
                        <input
                            type="radio"
                            class="radio__input"
                            v-model="sex"
                            name="sex"
                            value="female"
                        />
                        <span class="radio__mark"></span>
                        <span class="radio__text">Девочка</span>
                    </label>
                    <label class="radio radio_align_right">
                        <input
                            type="radio"
                            class="radio__input"
                            v-model="sex"
                            name="sex"
                            value="male"
                        />
                        <span class="radio__mark"></span>
                        <span class="radio__text">Мальчик</span>
                    </label>
                </div>
            </div>
            <div class="search-filter__group">
                <div class="search-filter__title">
                    <label class="switch switch_align_right">
                        <input
                            type="checkbox"
                            name="achievements"
                            class="switch__input"
                            :value="true"
                            v-model="achievements"
                        />
                        <span class="switch__bar">
                            <span class="switch__toggle"></span>
                        </span>
                        <span class="switch__text">Есть достижения</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="search-filter__footer">
            <button
                type="button"
                @click="handleReset"
                class="button button_bg_gray"
            >
                Сбросить
            </button>
            <button type="button" @click="handleApply" class="show button">
                Показать
            </button>
        </div>
    </div>
</template>

<script>
import CityInput from '@/components/Search/CityInput.vue'
import VueMultiselect from 'vue-multiselect'
import { debounce } from 'lodash'

export default {
    name: 'PetsFilter',

    components: {
        CityInput,
        VueMultiselect
    },

    data() {
        return {
            city: null,
            type: 'dog',
            breed: null,
            sex: 'all',
            achievements: false,
            loading: false,
            types: [],
            breeds: []
        }
    },

    methods: {
        handleReset() {
            this.city = null
            const url = new URL(document.location.href)
            url.searchParams.delete('city')
            url.searchParams.delete('type')
            url.searchParams.delete('sex')
            url.searchParams.delete('breed')
            url.searchParams.delete('achievements')
            document.location.href = url.href
        },
        handleApply() {
            const url = new URL(document.location.href)
            url.searchParams.delete('city')
            url.searchParams.delete('type')
            url.searchParams.delete('breed')
            url.searchParams.delete('sex')
            url.searchParams.delete('achievements')
            if (this.city && this.city.id) {
                url.searchParams.append('city', this.city.id)
            } else {
                url.searchParams.delete('city')
            }
            if (this.type) {
                url.searchParams.append('type', this.type)
            } else {
                url.searchParams.delete('type')
            }
            if (this.sex !== 'all') {
                url.searchParams.append('sex', this.sex)
            } else {
                url.searchParams.delete('sex')
            }
            if (this.achievements) {
                url.searchParams.append('achievements', 'true')
            } else {
                url.searchParams.delete('achievements')
            }
            if (this.breed) {
                url.searchParams.append('breed', this.breed)
            } else {
                url.searchParams.delete('breed')
            }
            document.location.href = url.href
        },
        async initFilter() {
            try {
                const { data } = await axios.get(
                    `${document.location.protocol}//${document.location.host}/api/v1/search/pet-types`
                )
                this.types = data?.data
            } catch (e) {
                console.log('Error  in fetching types: ', e)
            }

            const url = new URL(document.location.href)
            this.sex = url.searchParams.get('sex') ?? 'all'
            this.breed = url.searchParams.get('breed') ?? null
            this.achievements = url.searchParams.get('achievements') ?? false
            this.type = url.searchParams.get('type') ?? 'dog'
        },

        fetchBreeds(query = null) {
            if (this.loading) {
                return
            }
            this.loading = true
            axios
                .post(`/api/v1/search/breeds/`, {
                    type: this.type,
                    query,
                    additionally_load_by_slug: this.breed
                })
                .then((res) => {
                    this.breeds = res.data?.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleSearch: debounce(function (q) {
            if (q) {
                this.fetchBreeds(q)
            }
        }, 500),
        handleTypeInput() {
            console.log('val')
        },
        clearBreed() {
            this.breed = null
        },

        handleSelectBreed(val) {
            const breed = this.breeds.find((item) => item.slug === val)
            if (breed) {
                this.type = breed.type.slug ?? this.type
            }
        }
    },

    async mounted() {
        await this.initFilter()
        await this.fetchBreeds()
    }
}
</script>

<style scoped lang="scss">
.icon-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 32px;
    z-index: 1;
    cursor: pointer;
    opacity: 50%;

    &:hover {
        opacity: 100%;
    }
}

.type__icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    display: block;
    margin-right: 8px;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.items-center {
    align-items: center;
}

.icon-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 16px;
    z-index: 1;
    cursor: pointer;
    opacity: 50%;

    &:hover {
        opacity: 100%;
    }
}
</style>
