<template>
    <VueMultiselect
        v-model="city"
        :name="name"
        :loading="loading"
        @search-change="handleSearch"
        @select="handleSelect"
        :placeholder="placeholder"
        :show-labels="false"
        :allow-empty="false"
        :label="label"
        track-by="id"
        :options="cities"
        :internal-search="false"
    >
        <template v-slot:clear v-if="icon">
            <i :class="icon"></i>
        </template>
        <template v-slot:clear v-if="clearable && city">
            <i
                class="icon-close icon-clear"
                @mousedown.prevent.stop="clearAll"
            ></i>
        </template>
        <template v-slot:noResult
            >Городов, удовлетворяющих условию поиска не найдено</template
        >
        <template v-slot:noOptions>Начните вводить город</template>
        <template v-slot:caret v-if="modelValue"><div></div></template>
    </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import _ from 'lodash'

export default {
    name: 'CityInput',

    components: { VueMultiselect },

    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'city'
        },
        modelValue: {
            required: false
        },
        icon: {
            default: ''
        },
        placeholder: {
            default: 'Укажите город'
        },
        label: {
            type: String,
            default: 'name'
        }
    },

    data() {
        return {
            cities: [],
            city: this.modelValue,
            loading: false
        }
    },

    methods: {
        handleSearch: _.debounce(async function (query) {
            if (!query) {
                await this.setInitialCity()
            } else {
                query = { city: query }
                await this.fetchCities(query)
                console.log('cities fetched')
            }
        }, 500),
        async fetchCities(query) {
            this.loading = true
            await axios
                .get('/api/v1/search/cities', {
                    params: query
                })
                .then((res) => {
                    this.cities = res.data?.data
                })
                .catch((err) => {
                    console.log('err', err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleSelect(val) {
            this.city = val
            this.$emit('update:modelValue', val)
        },
        async setInitialCity() {
            const url = new URL(document.location.href)
            let query = {}
            if (url.searchParams.has('city')) {
                query['id'] = url.searchParams.get('city')
            }
            await this.fetchCities(query)
            if (this.cities.length && url.searchParams.has('city')) {
                this.city = _.head(this.cities)
                this.$emit('update:modelValue', this.city)
            }
            if (this.modelValue && this.modelValue.hasOwnProperty('id')) {
                this.cities.unshift(this.modelValue)
                if (this.modelValue['full_name']) {
                    this.modelValue['name'] = this.modelValue['full_name']
                }
                this.city = this.modelValue
                this.$emit('update:modelValue', this.city)
            }
        },
        clearAll() {
            this.city = null
            this.$emit('update:modelValue', null)
        }
    },

    mounted() {
        this.setInitialCity()
    }
}
</script>

<style scoped lang="scss">
.icon-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 16px;
    z-index: 1;
    cursor: pointer;
    opacity: 50%;
    &:hover {
        opacity: 100%;
    }
}
</style>
