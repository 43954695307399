<template>
    <VueFinalModal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="modal-container"
        content-class="modal__content centered"
        :click-to-close="false"
        :esc-to-close="true"
    >
        <div class="modal__bar">
            <button type="button" class="modal__close" @click="close">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal__inner">
            <div class="modal__body">
                <div class="form__group">
                    <div class="form__extra-text">Укажите новый email</div>
                </div>
                <TextInput
                    label="Email"
                    v-model="v$.email.$model"
                    :errorMessage="v$.email.$errors[0]?.$message"
                />
            </div>
            <div class="modal__header" style="margin-bottom: 0">
                <div class="modal-alert modal-alert_error">
                    <div class="modal-alert__controls">
                        <button
                            type="button"
                            class="button button_size_sm"
                            @click="handleSubmit"
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>

<script setup lang="ts">
import { $vfm, VueFinalModal } from 'vue-final-modal'
import TextInput from '../../../../js/components/Business/inputs/TextInput.vue'
import { requiredLocalized } from '../../../../js/rules/requiredLocalized'
import { emailLocalized } from '../../../../js/rules/emailLocalized'
import { useVuelidate } from '@vuelidate/core'
import { ref } from 'vue'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { useBusinessStore } from '../../../../js/stores/BusinessStore'

const store = useBusinessStore()

const rules = {
    email: {
        requiredLocalized,
        emailLocalized
    }
}

const email = ref(store.initialValues?.email)
const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    {
        email
    },
    { $externalResults }
)

const handleSubmit = async () => {
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    axios
        .post(`/api/v1/business/${store.initialValues.id}/change-email`, {
            email: email.value
        })
        .then((res) => {
            console.log('res', res)
            notify({
                type: 'success',
                text: 'Email успешно обновлен!'
            })
            if (!res?.data?.data) {
                throw new Error('Что-то пошло не так!')
            }
            store.setInitialValues(res.data.data, 8)
            $vfm.hideAll()
        })
        .catch((error) => {
            const errors = error.response.data.errors
            console.log('errors', errors)
            if (!errors) {
                notify({
                    type: 'error',
                    text: error.message
                })
                return
            }
            $externalResults.value = errors
        })
}
</script>
