// @/utils/i18n-validators.js
import * as validators from '@vuelidate/validators'
import { i18n } from "@/i18n"

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators

const messagePath = ({ $validator }) => `validations.${$validator}`;
const messageParams = (params) => ({
    ...params,
    _field_: params._field_ ? i18n.t(`fields.${params._field_}`) : i18n.global.t(`fields.${params.property}`)
});

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n), messagePath, messageParams })
// for vue-i18n@8
// const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
export const min = withI18nMessage(validators.minValue, { withArguments: true })
export const max = withI18nMessage(validators.maxValue, { withArguments: true })

