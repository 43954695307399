<template>
    <UserButtons
        :auth-user-id="authUserId"
        :user-id="userId"
        @onUnblock="onUnblock"
        @onBlock="onBlock"
    />
    <ModalsContainer />
</template>

<script setup>
import UserButtons from './UserButtons.vue'
import { useFriendshipStore } from '../../stores/FriendshipStore'
import { onMounted } from 'vue'
import { ModalsContainer } from 'vue-final-modal'

const props = defineProps({
    userId: {
        required: true
    },
    authUserId: {
        required: true
    }
})

const store = useFriendshipStore()

onMounted(() => {
    if (props.authUserId) {
        store.fetchFriendships()
    }
})

const onUnblock = () => {
    document.location.reload()
}
const onBlock = () => {
    document.location.reload()
}
</script>
