<template>
    <Modal class="form__group" content-class="modal_alt" name="modal">
        <template #button="{ show }">
            <button
                type="button"
                class="constructor-service"
                @click="show"
                v-if="!props.loading"
            >
                <span class="constructor-service__label" v-if="props.isEdit">{{
                    props.modelValue?.name ??
                    store.services.find(
                        (item) => item.id === props.modelValue?.id?.id
                    )?.name
                }}</span>
                <span class="constructor-service__label" v-else>
                    Добавить услугу
                </span>
                <span class="constructor-service__text" v-if="props.isEdit"
                    >от
                    {{
                        props.modelValue?.price_from ??
                        props.modelValue?.pivot.price_from
                    }}
                    {{ currencyGlyph }}</span
                >

                <span class="constructor-service__icon" v-if="props.isEdit">
                    <i class="icon-write_post"></i>
                </span>
                <span class="constructor-service__add" v-else>
                    <i class="icon-add"></i>
                </span>
            </button>
            <Skeletor v-else height="60" as="div"></Skeletor>
        </template>
        <template #default="{ close }">
            <form class="form">
                <div class="form__group" v-if="!props.loading">
                    <Multiselect
                        :options="store.servicesGrouped"
                        v-if="store.servicesGrouped"
                        group-label="group_key"
                        label="name"
                        v-model="v$.id.$model"
                        :show-labels="false"
                        placeholder="Название"
                        track-by="id"
                        group-values="group_values"
                    >
                        <template #tag="{ option, remove }">
                            <div
                                class="multiple__tag"
                                style="margin-right: 8px; margin-bottom: 8px"
                            >
                                {{ option.name }}
                                <button
                                    type="button"
                                    class="multiple__remove"
                                    @click.prevent="remove(option)"
                                >
                                    <i class="icon-close"></i>
                                </button>
                            </div>
                        </template>
                    </Multiselect>
                    <div
                        class="form__alert form__alert_error"
                        v-if="v$.id.$errors[0]?.$message"
                    >
                        <i class="icon-details"></i>
                        {{ v$.id.$errors[0]?.$message }}
                    </div>
                    <div class="form__extra-text">
                        Если не нашли нужную услугу сообщите нам на почту
                        <a href="mailto:connect@lapki.club"
                            >connect@lapki.club</a
                        >
                    </div>
                </div>

                <TextareaInput
                    label="Описание (необязательно)"
                    v-model="v$.pivot_description.$model"
                    :errorMessage="v$.pivot_description.$errors[0]?.$message"
                />
                <TextInput
                    label="Цена от"
                    v-model="v$.price_from.$model"
                    :icon="currencyGlyph"
                    :errorMessage="v$.price_from.$errors[0]?.$message"
                />
                <div class="form__group">
                    <div class="form__controls form__controls_align_right">
                        <button
                            type="button"
                            class="button button_size_sm button_bg_gray"
                            v-if="props.isEdit"
                            @click="showDeleteConfirmation = true"
                        >
                            Удалить
                        </button>
                        <button
                            type="button"
                            class="button button_size_sm"
                            @click="onSubmit"
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </template>
    </Modal>

    <VueFinalModal
        :model-value="showDeleteConfirmation"
        :fit-parent="false"
        :click-to-close="false"
        :esc-to-close="true"
        :content-class="['modal__content centered']"
    >
        <div class="modal__inner">
            <div class="modal__bar">
                <button
                    type="button"
                    class="modal__close"
                    data-action="modal-close"
                    @click="closeModal"
                >
                    <i class="icon-close"></i>
                </button>
            </div>
            <div class="modal__header">
                <div class="modal-alert__headline">
                    Вы уверены что хотите удалить услугу?
                </div>
            </div>
            <div class="form__footer">
                <div class="form__controls">
                    <button
                        type="button"
                        @click="closeModal"
                        class="button button_size_sm button_bg_light"
                    >
                        Отменить
                    </button>
                    <button
                        type="button"
                        @click="onDelete"
                        class="button button_size_sm button_bg_danger"
                    >
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>

<script setup lang="ts">
import TextareaInput from './TextareaInput.vue'
import TextInput from './TextInput.vue'
import Modal from '../../Modal.vue'
import MultiselectInput from './MultiselectInput.vue'
import Multiselect from 'vue-multiselect'
import { computed, onMounted, ref } from 'vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { useVuelidate } from '@vuelidate/core'
import { maxLengthLocalized } from '../../../rules/maxLengthLocalized'
import { $vfm, VueFinalModal } from 'vue-final-modal'
import { numericLocalized } from '../../../rules/numericLocalized'
import { Skeletor } from 'vue-skeletor'

const store = useBusinessStore()

const props = defineProps({
    modelValue: {
        type: Object
    },
    isEdit: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean
    }
})

const id = ref(props.modelValue)
const pivot_description = ref(
    props.modelValue?.pivot_description ??
        props.modelValue?.pivot?.description ??
        props.modelValue?.description
)
const price_from = ref(
    props.modelValue?.price_from ?? props.modelValue?.pivot?.price_from
)

const rules = {
    id: {
        requiredLocalized
    },
    pivot_description: {
        maxLengthLocalized: maxLengthLocalized(500)
    },
    price_from: {
        requiredLocalized,
        maxLengthLocalized: maxLengthLocalized(7),
        numericLocalized
    }
}
const v$ = useVuelidate(rules, {
    id,
    pivot_description,
    price_from
})

const handleSelect = (value) => {
    id.value = value
}

const emit = defineEmits(['onAdd', 'onDelete', 'onUpdate'])
const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    if (props.modelValue?.id) {
        emit('onUpdate', {
            id: id.value.id,
            pivot_description: pivot_description.value,
            icon: id.value.icon,
            name: id.value.name,
            price_from: price_from.value,
            service_type_id: id.value.service_type_id
        })
    } else {
        emit('onAdd', {
            id: id.value.id,
            pivot_description: pivot_description.value,
            icon: id.value.icon,
            name: id.value.name,
            price_from: price_from.value,
            service_type_id: id.value.service_type_id
        })
    }

    if (!props.isEdit) {
        id.value = null
        pivot_description.value = ''
        price_from.value = ''
    }
    v$.value.$reset()

    await $vfm.hideAll()
}

const onDelete = async () => {
    showDeleteConfirmation.value = null
    emit('onDelete', id.value)
    await $vfm.hideAll()
}

const currencyIcon = computed(() => {
    const currencyId = store.initialValues?.currency_id
    switch (currencyId) {
        case 'EUR':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-75 -60 117.75 120"><path d="M-70.843013-15H31.177404l-4.156987 10H-75zm0 20H22.863429l-4.156987 10H-75zM38.302222-32.13938a50 50 0 1 0 0 64.278761v14.04438a60 60 0 1 1 4.231556-88.502524z"/></svg>'
        case 'KZT':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 950"><path d="M0 0h700v100H0z"/><path d="M700 150v100H400v700H300V250H0V150h700z" /></svg>'
        case 'RUB':
            return '<svg viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none}</style></defs><path d="M19,4H10V15H7v2h3v3H7v2h3v6h2V22h9V20H12V17h7a6.0067,6.0067,0,0,0,6-6V10A6.0067,6.0067,0,0,0,19,4Zm4,7a4.0045,4.0045,0,0,1-4,4H12V6h7a4.0045,4.0045,0,0,1,4,4Z"/><rect class="cls-1" data-name="&lt;Transparent Rectangle&gt;" height="32" id="_Transparent_Rectangle_" width="32"/></svg>'
        default:
            return currencyId
    }
})
const currencyGlyph = computed(() => {
    const currencyId = store.initialValues?.currency_id
    switch (currencyId) {
        case 'EUR':
            return '€'
        case 'KZT':
            return '₸'
        case 'RUB':
            return '₽'
        default:
            return currencyId
    }
})

const showDeleteConfirmation = ref(null)

const closeModal = () => {
    showDeleteConfirmation.value = false
}
</script>
