<template>
    <slot :handle-click="handleClick"></slot>
</template>

<script setup>
import { $vfm } from 'vue-final-modal'
import DeleteFriendConfirmation from './DeleteFriendConfirmation.vue'

const emit = defineEmits(['onDelete'])

const handleClick = () => {
    $vfm.show({
        component: DeleteFriendConfirmation,
        bind: {
            name: 'DeleteFriendConfirmation'
        },
        on: {
            close() {
                $vfm.hide('DeleteFriendConfirmation')
            },
            submit() {
                $vfm.hide('DeleteFriendConfirmation')
                emit('onDelete')
            }
        }
    })
}
</script>
