<template>
    <div class="profile" v-if="user.id">
        <a :href="`/users/${user.id}`" class="profile__pic">
            <img
                :src="getUserAvatar(user.avatar)"
                :alt="user.full_name"
                class="profile__img"
            />
        </a>
        <div class="profile__body">
            <a :href="`/users/${user.id}`" class="profile__name">{{
                user.full_name
            }}</a>
            <div class="profile__meta" v-if="user.city">
                <div class="profile__meta-item">
                    {{ user.city ?? user.city?.full_name }}
                </div>
            </div>
            <UserButtons
                :auth-user-id="authUserId"
                :user-id="user.id"
                @onDelete="handleDelete"
            />
        </div>
    </div>
</template>

<script>
import UserButtons from './UserButtons.vue'

export default {
    name: 'UserInList',

    components: { UserButtons },

    props: {
        user: {
            required: true
        },
        authUserId: {
            required: false
        }
    },

    methods: {
        getUserAvatar(avatar = null) {
            if (!avatar || avatar === '') {
                return '/img/no-photo.png'
            }
            return avatar
        },
        handleDelete() {
            this.$emit('onDelete', this.user)
        }
    }
}
</script>
