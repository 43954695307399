<template>
    <div class="cookies" v-if="show">
        <p>
            Мы заботимся о вашей конфиденциальности, нажимая «Принять все файлы
            cookie», вы соглашаетесь на сохранение файлов cookie на вашем
            устройстве для улучшения навигации по сайту, анализа использования
            сайта и помощи в наших маркетинговых усилиях.
        </p>
        <a class="link" href="/cookies">Политика</a>
        <button
            class="button button_size_md button_rounded"
            type="button"
            @click="setCookie"
        >
            Принять
        </button>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const show = ref(!localStorage.getItem('show_cookie_agreement'))

const setCookie = () => {
    localStorage.setItem('show_cookie_agreement', 'false')
    show.value = false
}
</script>

<style scoped lang="scss">
.cookies {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    background: #fff;
    padding: 12px;
    width: 100%;
    z-index: 99;
    font-size: 12px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    p {
        margin: 0;
    }
    a,
    button {
        flex-shrink: 0;
    }
}
.link {
    font-size: 12px;
    text-decoration: underline;
    display: block;
    margin-left: auto;
    &:hover {
        text-decoration: underline;
        color: var(--hoverColor);
    }
}
</style>
