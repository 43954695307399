<template>
    <div class="chat">
        <div class="chat__inner">
            <div class="chat__side">
                <div class="chat__users">
                    <template v-if="!loading">
                        <template v-if="store.rooms.length">
                            <div
                                class="chat-user"
                                v-for="room in store.rooms"
                                :key="room.id"
                                :class="{
                                    'chat-user_select': room.id === room_id
                                }"
                                @click="handleRoomClick(room.id)"
                            >
                                <div class="chat-user__pic">
                                    <img
                                        :src="getAvatar(room)"
                                        alt=""
                                        class="chat-user__img"
                                    />
                                </div>
                                <div class="chat-user__body">
                                    <div class="chat-user__info">
                                        <div class="chat-user__name">
                                            {{ getRoomName(room) }}
                                        </div>
                                        <div class="chat-user__meta">
                                            <div
                                                class="chat-user__date"
                                                v-if="room.last_message"
                                            >
                                                {{
                                                    room.last_message
                                                        .created_at_formatted
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-user__text">
                                        <span v-if="room.last_message">
                                            <span
                                                v-if="
                                                    parseInt(
                                                        room.last_message
                                                            .user_id
                                                    ) === parseInt(props.id)
                                                "
                                                >Вы:
                                            </span>
                                            {{
                                                room.last_message.message
                                                    .length > 25
                                                    ? room.last_message.message.substring(
                                                          0,
                                                          25
                                                      ) + '...'
                                                    : room.last_message.message
                                            }}
                                        </span>
                                        <span v-else>Заказов пока нет</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div
                            v-else
                            class="specialty-item__text"
                            style="padding: 15px"
                        >
                            Заказов пока нет
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="chat-user"
                            v-for="room in [1, 2, 3, 4, 5]"
                            :key="room"
                        >
                            <div class="chat-user__pic">
                                <Skeletor circle class="chat-user__img" />
                            </div>
                            <div class="chat-user__body">
                                <div
                                    class="chat-user__info"
                                    style="width: 100%"
                                >
                                    <div
                                        class="chat-user__name"
                                        style="width: 100%"
                                    >
                                        <Skeletor width="50%" />
                                    </div>
                                </div>
                                <div class="chat-user__text">
                                    <Skeletor width="100%" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="chat__main">
                <ChatRoom
                    v-if="store.activeRoomId"
                    :auth-id="props.id"
                    :name="getRoomName(store.activeRoom)"
                    @onSendMessage="handleNewMessage"
                />
                <div class="specialty-item" v-else>
                    <div class="specialty-item__pic">
                        <div class="constructor-success__icon">💡</div>
                    </div>
                    <div class="specialty-item__text">
                        {{
                            !store.rooms.length
                                ? 'У вас еще нет заказов полученных на сайте.'
                                : 'Выберите заказ чтобы уточнить его детали'
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AvatarInput from '../../Inputs/AvatarInput.vue'
import TextInput from '../../Inputs/TextInput.vue'
import TextareaInput from '../../Inputs/TextareaInput.vue'
import * as yup from 'yup'
import useEdit from './useEdit'
import FormFooter from './FormFooter.vue'
import { Skeletor } from 'vue-skeletor'
import 'vue-skeletor/dist/vue-skeletor.css'
import ChatRoom from '../../Chat/ChatRoom.vue'
import { onMounted, ref } from 'vue'
import { useChatStore } from '../../../stores/ChatStore'

const props = defineProps({
    id: {
        required: true
    }
})

const room_id = ref(null)
const rooms = ref([])
const loading = ref(true)
const store = useChatStore()

// const fetchOrders = async (startLoading = false) => {
//     if (startLoading) {
//         loading.value = true
//     }
//     const { data } = await axios.get(`/api/v1/business/${props.id}/orders`)
//     rooms.value = data.data
//     loading.value = false
// }

onMounted(() => {
    store.fetchOrders(props.id).then(() => (loading.value = false))
})

const getRoomName = (room) => {
    console.log('room', room)
    return parseInt(room.recipient_id) === parseInt(props.id)
        ? room.user.full_name
        : room.recipient.full_name ?? room.recipient.name
}

const handleRoomClick = async (id) => {
    room_id.value = id
    await store.setActiveRoomId(id)
}

const handleNewMessage = () => {
    console.log('Emitted')
    store.fetchOrders(props.id).then(() => (loading.value = false))
}
// const validationObject = {
//     avatar: yup.string().nullable().required(),
//     result: yup.mixed().nullable(),
//     name: yup.string().nullable().required('Это обязательное поле'),
//     description: yup.string().nullable().required('Это обязательное поле'),
// }
//
// const { loading, resetForm, onSubmit } = useEdit(validationObject, props.id)

const getUserAvatar = (avatar = null) => {
    if (!avatar || avatar === '') {
        return '/img/no-photo.png'
    }
    return avatar
}

const getAvatar = (room) => {
    return getUserAvatar(room?.user?.avatar)
}
</script>
