<template>
    <div v-if="authUserId" class="profile__controls">
        <div
            class="profile__control"
            v-if="
                store.friends.includes(parseInt(props.userId)) &&
                !store.blockedMe.includes(parseInt(props.userId)) &&
                !store.blockedBy.includes(parseInt(props.userId))
            "
        >
            <a
                :href="`/chat/${props.userId}`"
                type="button"
                class="profile__button button button_circle button_bg_gray"
            >
                <i class="icon-chat"></i>
            </a>
        </div>
        <div class="profile__control">
            <template
                v-if="store.outgoingRequests?.includes(parseInt(props.userId))"
            >
                <button
                    type="button"
                    class="profile__button button button_rounded friendship__status"
                    data-action="dropdown-toggle"
                >
                    Запрос отправлен
                </button>
                <div class="dropdown-drop" data-action="dropdown-drop">
                    <nav class="navigation">
                        <div class="navigation__item">
                            <button
                                class="navigation__link"
                                @click="
                                    store.cancelFriendshipOffer(
                                        parseInt(props.userId)
                                    )
                                "
                            >
                                <span class="navigation__icon">
                                    <i class="icon-not_included"></i>
                                </span>
                                <span class="navigation__text"
                                    >Отменить запрос</span
                                >
                            </button>
                        </div>
                    </nav>
                </div>
            </template>

            <template
                v-else-if="
                    store.incomingRequests?.includes(parseInt(props.userId))
                "
            >
                <button
                    type="button"
                    class="profile__button button button_circle button_bg_gray"
                    data-action="dropdown-toggle"
                >
                    <i class="icon-additional"></i>
                </button>
                <div class="dropdown-drop" data-action="dropdown-drop">
                    <nav class="navigation">
                        <div class="navigation__item">
                            <button
                                class="navigation__link"
                                @click="
                                    store.acceptFriendship(
                                        parseInt(props.userId)
                                    )
                                "
                            >
                                <span class="navigation__icon">
                                    <i class="icon-checked"></i>
                                </span>
                                <span class="navigation__text"
                                    >Принять запрос</span
                                >
                                <span class="navigation__qnt"></span>
                            </button>
                        </div>
                        <div class="navigation__item">
                            <button
                                class="navigation__link"
                                @click="
                                    store.denyFriendship(parseInt(props.userId))
                                "
                            >
                                <span class="navigation__icon">
                                    <i class="icon-not_included"></i>
                                </span>
                                <span class="navigation__text"
                                    >Отменить запрос</span
                                >
                                <span class="navigation__qnt"></span>
                            </button>
                        </div>
                    </nav>
                </div>
            </template>

            <template
                v-else-if="
                    store.friends.includes(parseInt(props.userId)) ||
                    store.blockedBy.includes(parseInt(props.userId)) ||
                    store.blockedMe.includes(parseInt(props.userId))
                "
            >
                <button
                    type="button"
                    class="profile__button button button_circle button_bg_gray"
                    data-action="dropdown-toggle"
                >
                    <i class="icon-additional"></i>
                </button>
                <div class="dropdown-drop" data-action="dropdown-drop">
                    <nav class="navigation">
                        <div class="navigation__item">
                            <button
                                class="navigation__link friendship__unblock"
                                v-if="
                                    store.blockedBy.includes(
                                        parseInt(props.userId)
                                    )
                                "
                                @click="onUnblock"
                            >
                                <span class="navigation__icon">
                                    <i class="icon-checked"></i>
                                </span>
                                <span class="navigation__text"
                                    >Разблокировать</span
                                >
                                <span class="navigation__qnt"></span>
                            </button>
                            <button
                                class="navigation__link"
                                v-if="
                                    !store.blockedMe.includes(
                                        parseInt(props.userId)
                                    ) &&
                                    !store.blockedBy.includes(
                                        parseInt(props.userId)
                                    )
                                "
                                @click="onBlock"
                            >
                                <span class="navigation__icon">
                                    <i class="icon-not_included"></i>
                                </span>
                                <span class="navigation__text"
                                    >Заблокировать</span
                                >
                                <span class="navigation__qnt"></span>
                            </button>
                            <DeleteFriendButton @onDelete="handleDelete">
                                <template #default="{ handleClick }">
                                    <button
                                        class="navigation__link"
                                        @click="handleClick"
                                    >
                                        <span class="navigation__icon">
                                            <i class="icon-trash"></i>
                                        </span>
                                        <span class="navigation__text"
                                            >Убрать из друзей</span
                                        >
                                        <span class="navigation__qnt"></span>
                                    </button>
                                </template>
                            </DeleteFriendButton>
                        </div>
                    </nav>
                </div>
            </template>

            <button
                type="button"
                v-else
                @click="store.offerFriendship(parseInt(props.userId))"
                class="profile__button button button_rounded button_bg_light"
            >
                В друзья
            </button>
        </div>
    </div>
</template>

<script setup>
import { useFriendshipStore } from '../../stores/FriendshipStore'
import DeleteFriendButton from './DeleteFriendButton.vue'

const props = defineProps({
    userId: {
        required: true
    },
    authUserId: {
        required: true
    }
})

const store = useFriendshipStore()

const emit = defineEmits(['onDelete', 'onUnblock'])

const handleDelete = () => {
    store.deleteFriendship(parseInt(props.userId))
    emit('onDelete')
}

const onUnblock = () => {
    store.unblock(parseInt(props.userId))
    emit('onUnblock')
}
const onBlock = () => {
    store.block(parseInt(props.userId))
    emit('onBlock')
}
</script>
