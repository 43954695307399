<template>
    <Suspense>
        <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal__content"
                         :click-to-close="false"
                         :esc-to-close="true">

            <div class="modal__bar">
                <!--                <button type="button" class="modal__back" data-action="modal-back">-->
                <!--                    <i class="icon-back"></i>-->
                <!--                </button>-->
                <button type="button" class="modal__close" @click="close">
                    <i class="icon-close"></i>
                </button>
            </div>
            <div class="modal__inner modal__inner_wide">
                <div class="modal__header">
                    <div class="modal__headline">Просмотр запроса в родители</div>
                </div>
                <div class="modal__body">
                    <form class="form">
                        <div class="form__group">
                            <div class="choose-pet">
                                <span class="choose-pet__inner">
												<span class="choose-pet__pic">
													<img :src="petParent.avatar" :alt="petParent.nickname"/>
												</span>
                                    <span class="choose-pet__info">
													<span class="choose-pet__name">
                                                        {{ petParent.nickname }}
                                                    </span>
													<span class="choose-pet__meta">{{ petParent.age }} <i
                                                        :class="`icon-sex_${petParent.sex}`"></i>
													</span>
												</span>
                                </span>
                            </div>
                            <div class="form__separate">
                                <span>{{ genderParent }} для: </span>
                            </div>
                            <button type="button" class="choose-pet">
                                <span class="choose-pet__inner">
												<span class="choose-pet__pic">
													<img :src="pet.avatar" :alt="pet.nickname"/>
												</span>
                                    <span class="choose-pet__info">
													<span class="choose-pet__name">{{ pet.nickname }}</span>
													<span class="choose-pet__meta">{{ pet.age }} <i
                                                        :class="`icon-sex_${pet.sex}`"></i>
													</span>
												</span>
                                </span>
                            </button>
                        </div>
                        <div class="form__footer">
                            <div class="form__controls">
                                <button type="button" class="button button_bg_gray" @click="cancel">Отменить запрос</button>
                                <!--                                <button type="submit" class="button">Повторить</button>-->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </vue-final-modal>
    </Suspense>
</template>

<script setup>
import {VueFinalModal} from 'vue-final-modal'
import {computed} from "vue";
import {notify} from "@kyvg/vue3-notification";

const props = defineProps({
    gender: {
        type: String,
        required: true
    },
    pet: {
        required: true
    }
})

const genderParent = computed(() => {
    return props.gender === 'father' ? 'Папа' : 'Мама'
})

const petParent = computed(() => {
    return props.pet[props.gender][0]
})

const emit = defineEmits(['onCancel'])
const cancel = () => {
    axios.post('/api/v1/pets/parentship/cancel', {
        pet_id: props.pet.id,
        pet_parent_id: petParent.value.id
    })
        .then((res) => {
            if (res.data.success) {
                notify({
                    type: 'success',
                    text: 'Запрос на родительство успешно удален'
                })
                emit('onCancel')
            } else {
                throw new Error('Не удалось отменить запрос!')
            }
        })
        .catch((err) => {
            notify({
                type: 'error',
                text: err
            })
        })
}
</script>

<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.block {
    display: block;
}
</style>

