<template>
    <Sockets ref="sockets" :auth-id="authId">
        <div>
            <button
                type="button"
                class="user-nav__label"
                data-action="dropdown-toggle"
            >
                <i class="icon-notifications"></i>
                <span
                    class="user-nav__notification"
                    v-if="hasUnreadNotifications"
                ></span>
            </button>
            <div class="dropdown-drop" data-action="dropdown-drop">
                <template v-if="loading">
                    <div class="dropdown-drop__header">
                        <div class="dropdown-drop__title">Уведомления</div>
                        <div class="dropdown-drop__tabs">
                            <div class="tab-nav">
                                <Skeletor
                                    class="tab-nav__button"
                                    width="50px"
                                />
                                <Skeletor
                                    class="tab-nav__button"
                                    width="120px"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-drop__list">
                        <div class="tab-content">
                            <div
                                class="tab-content__item tab-content__item_active"
                            >
                                <div
                                    class="notification simple__notification"
                                    v-for="item in 4"
                                    :key="item"
                                >
                                    <div class="notification__pic">
                                        <Skeletor
                                            circle
                                            class="notification__img"
                                        />
                                    </div>
                                    <div class="notification__body">
                                        <div class="notification__info">
                                            <Skeletor
                                                class="notification__name"
                                            />
                                            <div class="notification__meta">
                                                <Skeletor
                                                    class="notification__date"
                                                />
                                            </div>
                                        </div>
                                        <div class="notification__event">
                                            <Skeletor
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="notifications.length">
                        <div class="dropdown-drop__header">
                            <div class="dropdown-drop__title">Уведомления</div>
                            <div class="dropdown-drop__tabs">
                                <div class="tab-nav">
                                    <button
                                        class="tab-nav__button"
                                        :class="{
                                            'tab-nav__button_active':
                                                !hasUnreadNotifications
                                        }"
                                        type="button"
                                        data-action="tab-button"
                                        data-tab="tab-notification-1"
                                    >
                                        Все
                                    </button>
                                    <button
                                        class="tab-nav__button"
                                        type="button"
                                        :class="{
                                            'tab-nav__button_active':
                                                hasUnreadNotifications
                                        }"
                                        data-action="tab-button"
                                        v-if="hasUnreadNotifications"
                                        data-tab="tab-notification-2"
                                    >
                                        Непрочитанные
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown-drop__list">
                            <div class="tab-content">
                                <div
                                    class="tab-content__item"
                                    :class="
                                        !hasUnreadNotifications
                                            ? 'tab-content__item_active'
                                            : ''
                                    "
                                    data-tab-content="tab-notification-1"
                                >
                                    <OrderNotifications
                                        :notifications="orderNotifications"
                                        @update="fetchNotifications"
                                    />
                                    <parents-notifications
                                        :notifications="parentsNotifications"
                                        @update="fetchNotifications"
                                    />
                                    <friends-notifications
                                        :notifications="friendsNotifications"
                                        @update="fetchNotifications"
                                    />
                                    <other-notifications
                                        :notifications="otherNotifications"
                                        @update="fetchNotifications"
                                    />
                                </div>
                                <div
                                    class="tab-content__item"
                                    :class="
                                        hasUnreadNotifications
                                            ? 'tab-content__item_active'
                                            : ''
                                    "
                                    data-tab-content="tab-notification-2"
                                >
                                    <OrderNotifications
                                        :notifications="
                                            unreadOrderNotifications
                                        "
                                        @update="fetchNotifications"
                                    />
                                    <parents-notifications
                                        :notifications="
                                            unreadParentsNotifications
                                        "
                                        @update="fetchNotifications"
                                    />
                                    <friends-notifications
                                        :notifications="
                                            unreadFriendsNotifications
                                        "
                                        @update="fetchNotifications"
                                    />
                                    <other-notifications
                                        :notifications="
                                            unreadOtherNotifications
                                        "
                                        @update="fetchNotifications"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else> Уведомлений нет </template>
                </template>
            </div>
        </div>
    </Sockets>
</template>

<script>
import FriendsNotifications from '@/components/Notifications/FriendsNotifications.vue'
import ParentsNotifications from '@/components/Notifications/ParentsNotifications.vue'
import OtherNotifications from '@/components/Notifications/OtherNotifications.vue'
import { Skeletor } from 'vue-skeletor'
import Sockets from '../Sockets.vue'
import OrderNotifications from './OrderNotifications.vue'

export default {
    name: 'AppNotifications',

    components: {
        OrderNotifications,
        Sockets,
        FriendsNotifications,
        ParentsNotifications,
        OtherNotifications,
        Skeletor
    },

    props: ['authId'],

    data() {
        return {
            loading: false,
            notifications: [],
            hasUnreadNotifications: false,
            parentsNotifications: [],
            unreadParentsNotifications: [],
            friendsNotifications: [],
            unreadFriendsNotifications: [],
            otherNotifications: [],
            unreadOtherNotifications: [],
            orderNotifications: [],
            unreadOrderNotifications: [],
            sockets: null
        }
    },

    methods: {
        clearData() {
            this.friendsNotifications = []
            this.unreadFriendsNotifications = []
            this.parentsNotifications = []
            this.unreadParentsNotifications = []
            this.otherNotifications = []
            this.unreadOtherNotifications = []
            this.orderNotifications = []
            this.unreadOrderNotifications = []
        },
        handleSocket(data) {
            console.log('handle socket event')
            console.log('data.notifiable_id', data.notifiable_id)
            console.log('this.authId', this.authId)
            if (parseInt(data.notifiable_id) === parseInt(this.authId)) {
                console.log('Fetch notification')
                this.fetchNotifications()
            }
        },
        async fetchNotifications() {
            if (this.loading) {
                return
            }
            this.loading = true
            this.hasUnreadNotifications = false
            this.clearData()
            axios
                .post('/api/v1/notifications')
                .then((res) => {
                    res.data?.data?.map((item) => {
                        if (item.type?.includes('NewOrderNotification')) {
                            if (!item.read_at) {
                                this.unreadOrderNotifications.push(item)
                                this.hasUnreadNotifications = true
                            }
                            this.orderNotifications.push(item)
                        }
                        if (
                            item.type?.includes('FriendRequestedNotification')
                        ) {
                            if (!item.read_at) {
                                this.unreadFriendsNotifications.push(item)
                                this.hasUnreadNotifications = true
                            }
                            this.friendsNotifications.push(item)
                        }
                        if (
                            item.type?.includes('RequestParentshipNotification')
                        ) {
                            if (!item.read_at) {
                                this.unreadParentsNotifications.push(item)
                                this.hasUnreadNotifications = true
                            }
                            this.parentsNotifications.push(item)
                        }
                        if (
                            item.type?.includes('NewCommentNotification') ||
                            item.type?.includes('NewCommentReplyNotification')
                        ) {
                            if (!item.read_at) {
                                this.unreadOtherNotifications.push(item)
                                this.hasUnreadNotifications = true
                            }
                            this.otherNotifications.push(item)
                        }
                    })
                    this.notifications =
                        res.data?.data.filter(
                            (item) =>
                                !item?.type.includes('NewMessageNotification')
                        ) ?? []
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },

    async mounted() {
        await this.fetchNotifications()
        this.$refs.sockets.socket.on(
            'App\\Notifications\\Chat\\NewMessageNotification',
            this.handleSocket
        )
        this.$refs.sockets.socket.on(
            'App\\Notifications\\Pets\\RequestParentshipNotification',
            this.handleSocket
        )
        this.$refs.sockets.socket.on(
            'App\\Notifications\\Comments\\NewCommentNotification',
            this.handleSocket
        )
        this.$refs.sockets.socket.on(
            'App\\Notifications\\Comments\\NewCommentReplyNotification',
            this.handleSocket
        )
        this.$refs.sockets.socket.on(
            'App\\Notifications\\Friends\\FriendRequestedNotification',
            this.handleSocket
        )
        this.$refs.sockets.socket.on(
            'App\\Notifications\\Orders\\NewOrderNotification',
            this.handleSocket
        )
    }
}
</script>
