<template>
    <div>
        <div class="profile-main__scroll js-drag-scroll">
            <div class="profile-main__list js-pets-list">
                <template v-if="isOwner === 'owner'">
                    <button
                        type="button"
                        class="pet-add switch-state-pet"
                        @click="showAddPetModal('active')"
                    >
                        <span class="pet-add__placeholder">
                            <i class="icon-add1"></i>
                        </span>
                        <span class="pet-add__text">Добавить</span>
                    </button>
                    <button
                        type="button"
                        class="pet-add switch-state-pet"
                        @click="showAddPetModal('desired')"
                    >
                        <span class="pet-add__placeholder">
                            <i class="icon-buy"></i>
                        </span>
                        <span class="pet-add__text">Планирую завести</span>
                    </button>
                </template>

                <div
                    class="pet"
                    v-for="pet in petsArray"
                    :key="pet.id"
                    :class="{ pet_rip: pet.status === 'rip' }"
                >
                    <div class="pet__pic">
                        <span class="pet__link" v-if="pet.status === 'desired'">
                            <img
                                :src="pet.avatar"
                                :alt="pet.name"
                                class="pet__img"
                            />
                        </span>
                        <a :href="`/pet/${pet.id}`" class="pet__link" v-else>
                            <img
                                :src="pet.avatar"
                                :alt="pet.name"
                                class="pet__img"
                            />
                        </a>
                        <span
                            class="pet__feature pet__feature_bg_black"
                            v-if="pet.status === 'rip'"
                        >
                            <span>
                                <i class="icon-rip"></i>
                            </span>
                        </span>
                        <delete-pet
                            v-else-if="
                                pet.status === 'desired' && isOwner === 'owner'
                            "
                            :deleting-desired-id="pet.id"
                            @success="handleDeletePet"
                        >
                            <template #button="{ show }">
                                <button
                                    class="pet__feature pet__feature_bg_gray"
                                    @click="show"
                                >
                                    <span>
                                        <i class="icon-trash"></i>
                                    </span>
                                </button>
                            </template>
                        </delete-pet>
                        <!--                        <a href="#" class="pet__feature ">-->
                        <!--                            <span>-->
                        <!--                                <i class='icon-competitor'></i>-->
                        <!--                            </span>-->
                        <!--                        </a>-->
                    </div>
                    <div class="pet__body">
                        <span
                            v-if="pet.status === 'desired'"
                            class="pet__name pet__name-desired"
                        >
                            <template v-if="isOwner === 'owner'"
                                >Планирую</template
                            ><template v-else>Планирует</template> завести
                        </span>
                        <a v-else :href="`/pet/${pet.id}`" class="pet__name">
                            {{ pet.nickname }}
                        </a>

                        <div class="pet__meta">
                            <div class="pet__meta-item">
                                <template v-if="pet.status === 'active'">
                                    {{ pet.age }}
                                </template>
                                <template v-else>
                                    {{ pet.years_of_life }}
                                </template>
                                <i :class="`icon-sex_${pet.sex}`"></i>
                            </div>

                            <a
                                class="pet__meta-item pet__breed"
                                v-if="pet.community"
                                :href="`/communities/${pet.community.id}`"
                            >
                                {{ pet.community.title_ru }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="modal" id="modal-add-pet-step-1">
                <div class="modal__content" data-modal="modal-content">
                    <div class="modal__bar">
                        <button
                            type="button"
                            class="modal__close"
                            data-action="modal-close"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal__inner modal__inner_wide">
                        <div class="modal__header">
                            <div class="modal__headline">Добавьте питомца</div>
                        </div>
                        <div class="modal__body">
                            <div class="pet-select">
                                <div
                                    class="pet-select__item"
                                    v-for="type in types"
                                    :key="type.id"
                                >
                                    <button
                                        type="button"
                                        class="pet-select__button"
                                        @click="handleTypeSelect(type)"
                                    >
                                        <span class="pet-select__icon">
                                            <img
                                                :src="`/img/pet-${type.slug}.svg`"
                                                alt=""
                                            />
                                        </span>
                                        <span class="pet-select__name">{{
                                            type.title
                                        }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal modal__pet__form" id="modal-add-pet-step-2">
                <!--            @if($errors->any())-->
                <!--            <div class="error" style="display: block">{{ $errors->first() }}</div>-->
                <!--            @endif-->
                <div class="modal__content" data-modal="modal-content">
                    <div class="modal__bar">
                        <button
                            type="button"
                            class="modal__back"
                            data-action="modal-back"
                        >
                            <i class="icon-back"></i>
                        </button>
                        <button
                            type="button"
                            class="modal__close"
                            data-action="modal-close"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal__inner">
                        <div class="modal__header">
                            <div class="modal__headline"></div>
                        </div>
                        <div class="modal__body">
                            <div class="form pet-add__form">
                                <!--                            <input type="hidden" name="type_slug" value="{{ old('type_slug') }}">-->
                                <!--                            <input type="hidden" name="type_id" value="{{ old('type_id') }}">-->
                                <!--                            <input type="hidden" name="status" value="{{ App\Models\Pet::STATUS_ACTIVE }}">-->

                                <div class="form__group">
                                    <div class="photo-add" v-if="!showCropper">
                                        <div class="photo-add__pic" v-if="type">
                                            <img
                                                :src="`/img/pet-${type.slug}.svg`"
                                                alt=""
                                            />
                                            <label
                                                class="photo-add__add"
                                                v-if="status === 'active'"
                                            >
                                                <input
                                                    type="file"
                                                    name="avatar"
                                                    @change="handleFileChange"
                                                    class="photo-add__input"
                                                />
                                                <i class="icon-camera"></i>
                                            </label>
                                        </div>
                                    </div>

                                    <cropper
                                        ref="cropper"
                                        v-else
                                        class="cropper"
                                        :src="file"
                                        image-restriction="fit-area"
                                        :stencil-component="
                                            $options.components.CircleStencil
                                        "
                                        :stencil-props="{
                                            aspectRatio: 1
                                        }"
                                        @change="handleFileCrop"
                                    ></cropper>
                                </div>

                                <div
                                    class="form__group"
                                    v-if="status === 'active'"
                                >
                                    <div
                                        class="form-control"
                                        :class="{
                                            error:
                                                v$.nickname.$invalid &&
                                                v$.nickname.$dirty
                                        }"
                                    >
                                        <input
                                            type="text"
                                            class="form-control__control"
                                            data-action="form-control"
                                            autocomplete="off"
                                            title="Введите Кличку"
                                            :class="{
                                                error:
                                                    (v$.nickname.$invalid &&
                                                        v$.nickname.$dirty) ||
                                                    vuelidateExternalResults
                                                        .nickname.length,
                                                'is-valued': !!nickname
                                            }"
                                            v-model="nickname"
                                        />
                                        <div class="form-control__label">
                                            Кличка
                                        </div>
                                        <div
                                            v-for="error of [
                                                ...v$.nickname.$errors,
                                                ...vuelidateExternalResults.nickname
                                            ]"
                                            :key="error.$uid"
                                            class="error"
                                        >
                                            {{
                                                error.$message ??
                                                'Необходимо указать кличку'
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form__group">
                                    <div
                                        class="form-control"
                                        :class="{
                                            error:
                                                v$.breed.$invalid &&
                                                v$.breed.$dirty
                                        }"
                                    >
                                        <VueMultiselect
                                            v-model="breed"
                                            :loading="breed_loading"
                                            @search-change="handleSearch"
                                            placeholder="Выберите породу"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            label="title"
                                            track-by="id"
                                            :options="breeds"
                                        >
                                            <template v-slot:noResult
                                                >Пород, удовлетворяющих условию
                                                поиска не найдено
                                            </template>
                                            <template v-slot:noOptions
                                                >Начните вводить породу
                                            </template>
                                        </VueMultiselect>
                                        <div
                                            v-for="error of v$.breed.$errors"
                                            :key="error.$uid"
                                            class="error"
                                        >
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="form__group"
                                    v-if="status === 'active'"
                                >
                                    <BirthdayInput v-model="birth_date" />
                                    <div
                                        v-for="error of [
                                            ...v$.birth_date.$errors,
                                            ...vuelidateExternalResults.birth_date
                                        ]"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message ?? error }}
                                    </div>
                                </div>
                                <div class="form__group">
                                    <div class="sex-select">
                                        <div class="sex-select__title">
                                            Выберите пол
                                        </div>
                                        <div class="sex-select__list">
                                            <label class="sex-select__label">
                                                <input
                                                    type="radio"
                                                    v-model="sex"
                                                    class="sex-select__input"
                                                    value="male"
                                                />
                                                <span
                                                    class="sex-select__mark sex-select__mark_male"
                                                >
                                                    <i
                                                        class="icon-sex_male"
                                                    ></i>
                                                </span>
                                            </label>
                                            <label class="sex-select__label">
                                                <input
                                                    type="radio"
                                                    v-model="sex"
                                                    class="sex-select__input"
                                                    value="female"
                                                />
                                                <span
                                                    class="sex-select__mark sex-select__mark_female"
                                                >
                                                    <i
                                                        class="icon-sex_female"
                                                    ></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        v-for="error of v$.sex.$errors"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="form__footer">
                                    <div class="form__group">
                                        <button
                                            type="button"
                                            class="button"
                                            :class="{ 'is-wait': loading }"
                                            @click="handleSubmit"
                                        >
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@/utils/i18n-validators'
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import _ from 'lodash'
import VueMultiselect from 'vue-multiselect'
import DeletePet from '@/components/Pets/DeletePet.vue'
import BirthdayInput from '../Inputs/BirthdayInput.vue'

export default {
    name: 'PetsButtons',

    props: {
        isOwner: {
            required: false,
            default: 'viewer'
        },
        pets: {
            required: false
        }
    },

    setup() {
        return { v$: useVuelidate() }
    },

    mounted() {
        this.checkIsFormShown()
    },

    components: {
        BirthdayInput,
        Cropper,
        VueMultiselect,
        DeletePet,
        CircleStencil
    },

    validations() {
        return {
            nickname: { required: requiredIf(() => this.status === 'active') },
            birth_date: {
                required: requiredIf(() => this.status === 'active')
            },
            sex: { required },
            breed: { required }
        }
    },

    data() {
        return {
            types: [],
            status: null,
            loading: false,
            type: null,
            nickname: '',
            birth_date: null,
            sex: null,
            vuelidateExternalResults: {
                birth_date: [],
                nickname: []
            },
            petsArray: JSON.parse(this.pets) ?? [],
            file: null,
            result: null,
            showCropper: false,
            breeds: [],
            breed: null,
            breed_loading: false,
            deleting_desired_id: null
        }
    },

    methods: {
        reset() {
            this.type = null
            this.nickname = ''
            this.birth_date = null
            this.sex = null
            this.vuelidateExternalResults = {
                birth_date: [],
                nickname: []
            }
            this.file = null
            this.result = null
            this.showCropper = false
            this.breed = null
            this.deleting_desired_id = null
            this.v$.$reset()
        },
        checkIsFormShown() {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)

            if (urlParams.has('form')) {
                const paramValue = urlParams.get('form')

                if (paramValue === 'create_pet') {
                    this.showAddPetModal('active')
                }
            }
        },
        async showAddPetModal(status) {
            try {
                this.loading = true
                this.status = status
                const { data } = await axios.get(
                    `${document.location.protocol}//${document.location.host}/api/v1/search/pet-types`
                )
                this.types = data?.data
                modal.open('#modal-add-pet-step-1')
            } catch (e) {
                this.types = []
            } finally {
                this.loading = false
            }
        },
        async handleTypeSelect(type) {
            this.reset()
            this.type = type
            await this.fetchBreeds()
            modal.open('#modal-add-pet-step-2')
        },
        async handleSubmit() {
            if (this.loading) {
                return
            }
            // this.v$.$clearExternalResults()
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return
            }
            try {
                this.loading = true
                const formData = new FormData()
                formData.append('sex', this.sex)
                formData.append('status', this.status)
                formData.append('type_id', this.type.id)
                formData.append('breed_id', this.breed.id)
                if (this.status === 'active') {
                    formData.append('nickname', this.nickname)
                    formData.append('birth_date', this.birth_date)
                    if (this.result) {
                        formData.append('avatar', this.result)
                    }
                }
                const { data } = await axios.post('/api/v1/pets', formData)

                this.petsArray = data.data
                this.status = null
                this.type = null
                this.nickname = ''
                this.birth_date = null
                this.sex = null
                this.breed = null
                modal.close()
                this.$notify({
                    type: 'success',
                    text: 'Питомец успешно добавлен'
                })
            } catch (err) {
                const errors = err.response?.data?.errors
                Object.assign(this.vuelidateExternalResults, errors)
            } finally {
                this.loading = false
            }
        },
        handleFileChange(file) {
            let reader = new FileReader()
            reader.onload = (e) => {
                this.file = e.target.result
            }
            reader.readAsDataURL(file.target.files[0])
            this.showCropper = true
        },
        handleFileCrop() {
            const { canvas } = this.$refs.cropper.getResult()
            try {
                canvas.toBlob((blob) => {
                    this.result = blob
                })
            } catch (e) {
                console.log('canvas.toBlob error')
            }
        },
        async fetchBreeds(query = null) {
            try {
                this.loading = true
                const { data } = await axios.get('/api/v1/search/breeds', {
                    params: {
                        type_slug: [this.type?.slug],
                        query
                    }
                })
                this.breeds = data.data
            } catch (err) {
                this.$notify({
                    type: 'error',
                    text: err.message ?? 'Что-то пошло не так'
                })
            } finally {
                this.loading = false
            }
        },
        handleSearch: _.debounce(function (query = null) {
            if (!query) {
                query = { type: 'popular' }
            }
            this.fetchBreeds(query)
        }, 500),
        handleDeletePet(id) {
            this.petsArray.splice(
                this.petsArray.findIndex((item) => item.id === id),
                1
            )
            this.$notify({
                type: 'success',
                text: 'Питомец успешно удален'
            })
        }
    }
}
</script>

<style>
.pet__name-desired {
    color: var(--Gray50);
}
</style>
