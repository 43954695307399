<script setup>
import { computed, onMounted, ref } from 'vue'
import InfiniteLoader from '../InfiniteLoader.vue'
import qs from 'qs'
import UserInList from './UserInList.vue'
import UserSkeletor from './UserSkeletor.vue'
import { useFriendshipStore } from '../../stores/FriendshipStore'

const props = defineProps({
    authUserId: {
        type: [String, Number]
    }
})

const users = ref([])

let page = ref(1)
const load = async (data) => {
    users.value.push(...data)
    page.value++
}

const url = computed(() => {
    const params = {}
    const url = new URL(document.location.href)
    url.searchParams.forEach((value, key) => {
        params[key] = value
    })
    params['page'] = page.value
    return `/api/v1/users/?${qs.stringify(params)}`
})

const store = useFriendshipStore()
onMounted(() => {
    if (props.authUserId) {
        store.fetchFriendships()
    }
})
</script>

<template>
    <InfiniteLoader :url="url" @onLoad="load">
        <template #default>
            <div class="search-list">
                <UserInList
                    v-for="user in users"
                    :key="user.id"
                    :user="user"
                    :auth-user-id="authUserId"
                />
            </div>
        </template>
        <template #loader>
            <div class="search-list" style="margin-top: 15px">
                <UserSkeletor v-for="i in 10" :key="i" />
            </div>
        </template>
        <template #error> Не удалось обновить список животных :( </template>
        <template #complete>
            <span v-if="users.length"></span>
            <div v-else class="specialty-item">
                <div class="specialty-item__pic">
                    <img :src="'/img/special-dog2.svg'" alt="" />
                </div>
                <div class="specialty-item__text">
                    По вашему запросу ничего не найдено :(
                </div>
            </div>
        </template>
    </InfiniteLoader>
</template>

<!--<template>-->
<!--    <div class="search-list">-->
<!--        <ModalsContainer />-->
<!--        <user-in-list-->
<!--            v-for="user in infiniteList"-->
<!--            :key="user.id"-->
<!--            :user="user"-->
<!--            :auth-user-id="authUserId"-->
<!--            @onDelete="handleDelete"-->
<!--        />-->
<!--        <template v-if="initialState">-->

<!--        </template>-->
<!--        <div-->
<!--            class="specialty-item"-->
<!--            v-if="!infiniteList.length && !loading && !initialState"-->
<!--        >-->
<!--            <div class="specialty-item__pic">-->
<!--        <InfiniteLoading @infinite="loadData" v-if="infinite">-->
<!--            <template #spinner>-->
<!--                <div-->
<!--                    style="-->
<!--                        width: 100%;-->
<!--                        display: flex;-->
<!--                        flex-direction: column;-->
<!--                        justify-content: center;-->
<!--                        align-items: center;-->
<!--                        margin-top: 24px;-->
<!--                    "-->
<!--                >-->
<!--                    <button-->
<!--                        type="button"-->
<!--                        class="button button_rounded is-wait"-->
<!--                    ></button>-->
<!--                </div>-->
<!--            </template>-->
<!--            <template #complete>-->
<!--                <span></span>-->
<!--            </template>-->
<!--        </InfiniteLoading>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--import UserInList from '@/components/Users/UserInList.vue'-->
<!--import { InfiniteLoadingMixin } from '@/mixins/InfiniteLoadingMixin'-->
<!--import { mapActions, mapState } from 'pinia'-->
<!--import { useFriendshipStore } from '../../stores/FriendshipStore'-->
<!--import { ModalsContainer } from 'vue-final-modal'-->
<!--import { Skeletor } from 'vue-skeletor'-->
<!--import UserButtons from './UserButtons.vue'-->

<!--export default {-->
<!--    name: 'UsersList',-->

<!--    components: {-->
<!--        UserButtons,-->
<!--        Skeletor,-->
<!--        UserInList,-->
<!--        ModalsContainer-->
<!--    },-->

<!--    mixins: [InfiniteLoadingMixin],-->

<!--    props: {-->
<!--        authUserId: {-->
<!--            required: false-->
<!--        },-->
<!--        url: {-->
<!--            required: true-->
<!--        }-->
<!--    },-->

<!--    methods: {-->
<!--        ...mapActions(useFriendshipStore, ['fetchFriendships']),-->

<!--        handleDelete(id) {-->
<!--            this.infiniteList = this.infiniteList.filter(-->
<!--                (item) => parseInt(item.id) !== parseInt(id)-->
<!--            )-->
<!--            // this.loadData()-->
<!--        }-->
<!--    },-->

<!--    async mounted() {-->
<!--        if (this.authUserId) {-->
<!--            await this.fetchFriendships()-->
<!--        }-->
<!--        if (!this.infinite) {-->
<!--            await this.loadData()-->
<!--        }-->
<!--    }-->
<!--}-->
<!--</script>-->
