<template>
    <teleport to="body">
        <div class="modal" id="modal-register">
            <div class="modal__content" data-modal="modal-content">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__close"
                        data-action="modal-close"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__inner">
                    <div class="modal__header">
                        <div class="modal__headline">
                            Задайте <br />
                            параметры входа
                        </div>
                    </div>
                    <div class="modal__body">
                        <form class="form" @submit.prevent="handleSubmit">
                            <div class="form__group">
                                <div class="form-control">
                                    <i class="icon-email"></i>
                                    <input
                                        type="text"
                                        ref="input"
                                        class="form-control__control"
                                        @input="clearErrors('email')"
                                        :class="{
                                            error:
                                                v$.email.$invalid &&
                                                v$.email.$dirty,
                                            'is-valued': !!email
                                        }"
                                        data-action="form-control"
                                        autocomplete="off"
                                        title="Введите Email"
                                        v-model="email"
                                    />
                                    <div class="form-control__label">Email</div>
                                    <div
                                        v-for="error of v$.email.$errors"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form__group">
                                <div class="form-control">
                                    <i class="icon-account"></i>
                                    <input
                                        type="text"
                                        class="form-control__control"
                                        @input="clearErrors('name')"
                                        :class="{
                                            error:
                                                v$.name.$invalid &&
                                                v$.name.$dirty,
                                            'is-valued': !!name
                                        }"
                                        data-action="form-control"
                                        title="Введите Имя"
                                        v-model="name"
                                    />
                                    <div class="form-control__label">Имя</div>
                                    <div
                                        v-for="error of v$.name.$errors"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form__group">
                                <div class="form-control">
                                    <i class="icon-account"></i>
                                    <input
                                        type="text"
                                        class="form-control__control"
                                        @input="clearErrors('surname')"
                                        :class="{
                                            error:
                                                v$.surname.$invalid &&
                                                v$.surname.$dirty,
                                            'is-valued': !!surname
                                        }"
                                        data-action="form-control"
                                        title="Введите Фамилию"
                                        v-model="surname"
                                    />
                                    <div class="form-control__label">
                                        Фамилия
                                    </div>
                                    <div
                                        v-for="error of v$.surname.$errors"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>

                            <div class="form__group">
                                <div
                                    class="form-control has-icon"
                                    :class="{
                                        error:
                                            v$.city_id.$invalid &&
                                            v$.city_id.$dirty
                                    }"
                                >
                                    <city-input
                                        v-model="city"
                                        icon="icon-location"
                                        name="city_id"
                                    >
                                    </city-input>
                                    <div
                                        v-for="error of v$.city_id.$errors"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>

                            <div class="form__group">
                                <div class="form-control">
                                    <i class="icon-events"></i>
                                    <input
                                        type="text"
                                        class="form-control__control"
                                        @input="clearErrors('invitation')"
                                        :class="{
                                            error:
                                                v$.invitation.$invalid &&
                                                v$.invitation.$dirty,
                                            'is-valued': !!invitation
                                        }"
                                        data-action="form-control"
                                        title="Приглашение"
                                        v-model="invitation"
                                    />
                                    <div class="form-control__label">
                                        Приглашение
                                    </div>
                                    <div
                                        v-for="error of v$.invitation.$errors"
                                        :key="error.$uid"
                                        class="error"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>

                            <div class="form__footer">
                                <div class="form__group">
                                    <button
                                        type="submit"
                                        class="button"
                                        :class="{ 'is-wait': loading }"
                                    >
                                        Регистрация
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="oauth" style="margin-top: 28px">
                            <socials />
                            <button
                                data-action="modal-open"
                                data-target="#modal-send-password"
                                class="oauth__button"
                            >
                                <svg
                                    width="29"
                                    height="28"
                                    viewBox="0 0 29 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.834 4.66602H5.16732C3.88398 4.66602 2.84565 5.71602 2.84565 6.99935L2.83398 20.9993C2.83398 22.2827 3.88398 23.3327 5.16732 23.3327H23.834C25.1173 23.3327 26.1673 22.2827 26.1673 20.9993V6.99935C26.1673 5.71602 25.1173 4.66602 23.834 4.66602ZM5.16732 9.33268L14.5007 15.166L23.834 9.33268V20.9993H5.16732V9.33268ZM5.16732 6.99935L14.5007 12.8327L23.834 6.99935H5.16732Z"
                                        fill="#B3B3B3"
                                    />
                                </svg>
                                <span class="oauth__name">Email</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, maxLength } from '@/utils/i18n-validators'
import _ from 'lodash'
import vClickOutside from 'click-outside-vue3'
import CityInput from '@/components/Search/CityInput.vue'
import Socials from '@/components/Auth/Socials.vue'
import useEventsBus from '@/utils/eventBus'
import { ref, watch } from 'vue'

export default {
    name: 'ModalRegister',

    setup() {
        const { bus } = useEventsBus()
        const input = ref(null)

        watch(
            () => bus.value.get('showModalRegister'),
            () => {
                modal.open('#modal-register', () => {
                    input.value.focus()
                })
            }
        )

        return { v$: useVuelidate(), input }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    components: { Socials, CityInput },

    data() {
        return {
            email: '',
            name: '',
            surname: '',
            invitation: '',
            city: null,
            loading: false,
            city_loading: false,
            vuelidateExternalResults: {
                email: [],
                city_id: []
            },
            cities: []
        }
    },

    validations() {
        return {
            email: { required, email },
            name: { required },
            surname: { required },
            city_id: { required },
            invitation: { maxLength: maxLength(255) }
        }
    },

    computed: {
        city_id() {
            return this.city?.id ?? null
        }
    },

    methods: {
        setFocus() {
            this.$refs.input.focus()
        },
        clearErrors(error) {
            this.vuelidateExternalResults[error] = []
        },
        async handleSubmit() {
            this.v$.$clearExternalResults()
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return
            }
            try {
                this.loading = true

                const params = {}
                const url = new URL(document.location.href)
                url.searchParams.forEach((value, key) => {
                    params[key] = value
                })
                params['email'] = this.email
                params['name'] = this.name
                params['surname'] = this.surname
                params['city_id'] = this.city?.id ?? null
                params['invitation'] = this.invitation

                const { data } = await axios.post(
                    '/api/v1/auth/register',
                    params
                )
                // console.log('data', data)
                if (data.success) {
                    modal.open('#modal-login-email', () => {
                        document.getElementById('login_email').value =
                            this.email
                    })
                }
            } catch (err) {
                // console.log('err', err)
                const errors = err.response?.data?.errors
                console.log('errors', errors)
                if (errors) {
                    console.log('ddd', err, errors)
                    Object.assign(this.vuelidateExternalResults, errors)
                }
                // if (err.response?.data?.message) {
                //     let keys = Object.keys(errors)
                //     Object.assign(this.vuelidateExternalResults, {
                //         [keys[0]]: [
                //             err.response?.data?.message
                //         ]
                //     })
                // }
            } finally {
                this.loading = false
            }
        },
        handleSearch: _.debounce(function (query) {
            this.fetchCities(query)
        }, 500),
        fetchCities(query) {
            this.city_loading = true
            axios
                .get('/api/v1/search/cities', {
                    params: query
                })
                .then((res) => {
                    this.cities = res.data?.data ?? []
                })
                .catch((err) => {
                    console.log('err', err)
                })
                .finally(() => {
                    this.city_loading = false
                })
        }
    },

    mounted() {
        const url = new URL(document.location.href)
        this.invitation = url.searchParams.get('utm_campaign')
    }
}
</script>
