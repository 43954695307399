<template>
    <div class="pet">
        <div class="pet__pic">
            <a :href="`/pet/${pet.id}`" class="pet__link">
                <img :src="pet.avatar" :alt="pet.nickname" class="pet__img">
            </a>
<!--            <a :href="`/pet/${pet.id}`" class="pet__feature ">-->
<!--                <span>-->
<!--                    <i class="icon-competitor"></i>-->
<!--                </span>-->
<!--            </a>-->
        </div>
        <div class="pet__body">
            <a :href="`/pet/${pet.id}`"
               class="pet__name">{{ pet.nickname }}</a>
            <div class="pet__meta">
                <div class="pet__meta-item">
                    {{ pet.breed.title_ru }}
                </div>
                <div class="pet__meta-item">
                    <span>{{ pet.age }}</span>
                    <i :class="`icon-sex_${pet.sex}`"></i>
                </div>
                <a class="pet__meta-item pet__breed"
                   v-if="pet.community"
                   :href="`/communities/${pet.community.id}`">
                    {{ pet.community.title }}
                </a>
                <div class="pet__meta-item pet__host">
                    <a :href="`/users/${pet.user?.id}`">{{ pet.user?.full_name }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PetInList",

    props: {
        pet: {
            required: true
        }
    }
}
</script>

