<template>
    <div class="form__group" v-if="!props.loading">
        <label class="checkbox">
            <input
                type="checkbox"
                :name="props.name"
                class="checkbox__input"
                :checked="props.modelValue"
                @change="$emit('update:modelValue', $event.target.checked)"
                :value="true"
            />
            <span class="checkbox__mark"></span>
            <span class="checkbox__text" v-html="label"></span>
        </label>
        <div class="form__alert form__alert_error" v-if="props.errorMessage">
            <i class="icon-details"></i> {{ props.errorMessage }}
        </div>
    </div>
    <Skeletor v-else style="margin-top: 8px" height="30" as="div"></Skeletor>
</template>

<script setup>
import { Skeletor } from 'vue-skeletor'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    modelValue: {
        type: Boolean,
        required: true
    },
    errorMessage: {
        type: String
    },
    loading: {
        type: Boolean
    }
})
</script>
