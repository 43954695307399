<template>
    <div>
        <button
            type="button"
            class="navigation__link"
            @click="showModalAddAchievement"
        >
            <span class="navigation__icon"
                ><i class="icon-competitor"></i
            ></span>
            <span class="navigation__text">Добавить достижение</span>
        </button>

        <Teleport to="body">
            <div
                class="modal"
                id="modal-pet-achievement-add"
                v-click-outside="onClickOutside"
            >
                <div class="modal__content" data-modal="modal-content">
                    <div class="modal__bar">
                        <button
                            type="button"
                            class="modal__close"
                            data-action="modal-close"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal__inner">
                        <div class="modal__header">
                            <div class="modal__headline">
                                Добавить достижение
                            </div>
                        </div>
                        <div class="modal__body">
                            <div class="form pet-achievement-add__form">
                                <div class="form__group">
                                    <div class="form-control">
                                        <imask-input
                                            :value="year"
                                            mask="0000"
                                            @accept:masked="onAcceptYear"
                                            class="form-control__control"
                                            :class="{
                                                error:
                                                    v$.year.$invalid &&
                                                    v$.year.$dirty,
                                                'is-valued': !!year
                                            }"
                                        />

                                        <div class="form-control__label">
                                            Год
                                        </div>
                                        <div
                                            v-for="error of v$.year.$errors"
                                            :key="error.$uid"
                                            class="error"
                                        >
                                            <template
                                                v-if="
                                                    error.$validator === 'max'
                                                "
                                            >
                                                Дата участия не может быть
                                                указана в будущем, проверьте
                                                корректность введенных данных
                                            </template>
                                            <template
                                                v-else-if="
                                                    error.$validator === 'min'
                                                "
                                            >
                                                Дата участия не может быть
                                                раньше года рождения питомца,
                                                проверьте корректность введенных
                                                данных
                                            </template>
                                            <template v-else>{{
                                                error.$message
                                            }}</template>
                                        </div>
                                    </div>
                                </div>
                                <div class="form__group">
                                    <div
                                        class="form-control"
                                        :class="{
                                            error:
                                                v$.city.$invalid &&
                                                v$.city.$dirty
                                        }"
                                    >
                                        <city-input v-model="city"></city-input>
                                        <div
                                            v-for="error of v$.city.$errors"
                                            :key="error.$uid"
                                            class="error"
                                        >
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form__group">
                                    <div class="form-control">
                                        <input
                                            type="text"
                                            class="form-control__control"
                                            data-action="form-control"
                                            autocomplete="off"
                                            title="Мероприятие"
                                            :class="{
                                                error:
                                                    v$.event.$invalid &&
                                                    v$.event.$dirty,
                                                'is-valued': !!event
                                            }"
                                            v-model="event"
                                        />
                                        <div class="form-control__label">
                                            Мероприятие
                                        </div>
                                        <div
                                            v-for="error of v$.event.$errors"
                                            :key="error.$uid"
                                            class="error"
                                        >
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form__group">
                                    <div
                                        class="form-control"
                                        :class="{
                                            error:
                                                v$.achievement.$invalid &&
                                                v$.achievement.$dirty
                                        }"
                                    >
                                        <VueMultiselect
                                            v-model="achievement"
                                            placeholder="Выберите достижение"
                                            :show-labels="false"
                                            :allow-empty="false"
                                            label="name"
                                            track-by="id"
                                            :options="places"
                                        >
                                        </VueMultiselect>
                                        <div
                                            v-for="error of v$.city.$errors"
                                            :key="error.$uid"
                                            class="error"
                                        >
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <template v-if="showCropper">
                                    <cropper
                                        ref="cropper"
                                        class="cropper"
                                        :src="file"
                                        image-restriction="fit-area"
                                        @change="handleFileCrop"
                                    ></cropper>
                                    <label
                                        class="form-photo"
                                        style="margin-top: 12px"
                                    >
                                        <span class="form-photo__pic"></span>
                                        <span class="form-photo__text">
                                            Выбрать другое изображение
                                        </span>
                                        <input
                                            type="file"
                                            @change="handleFileChange"
                                            class="photo-add__input"
                                        />
                                    </label>
                                </template>
                                <div class="form__group" v-else>
                                    <label class="form-photo">
                                        <span class="form-photo__pic"></span>
                                        <span class="form-photo__text">
                                            <span class="form-photo__icon">
                                                <i
                                                    class="icon-camera"
                                                ></i> </span
                                            >Загрузить фото документа,
                                            подтверждающего титул
                                        </span>
                                        <input
                                            type="file"
                                            @change="handleFileChange"
                                            class="photo-add__input"
                                            accept="image/*"
                                        />
                                    </label>
                                </div>
                                <p class="form__extra-text">
                                    Вы можете загрузить изображение размером до
                                    5МБ в форматах .jpg и .png.
                                </p>
                                <div class="form__footer">
                                    <div class="form__controls">
                                        <!--                                        <button type="button" class="button button_bg_gray" data-action="modal-close">-->
                                        <!--                                            Отмена-->
                                        <!--                                        </button>-->
                                        <button
                                            type="button"
                                            class="button"
                                            :class="{ 'is-wait': loading }"
                                            @click="handleSubmit"
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useVuelidate } from '@vuelidate/core'
import { required, min, max } from '@/utils/i18n-validators'
import { IMaskComponent } from 'vue-imask'
import _ from 'lodash'
import VueMultiselect from 'vue-multiselect'
import vClickOutside from 'click-outside-vue3'
import CityInput from '@/components/Search/CityInput.vue'

export default {
    name: 'CreateAchievement',

    props: {
        petId: {
            type: String
        },
        minYear: {
            default: 1999
        }
    },

    setup() {
        return { v$: useVuelidate() }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    validations() {
        return {
            city: { required },
            year: {
                required,
                min: min(this.minYear),
                max: max(new Date().getFullYear())
            },
            event: { required },
            achievement: { required }
        }
    },

    data() {
        return {
            loading: false,
            file: null,
            showCropper: false,
            year: null,
            city: null,
            city_loading: false,
            result: null,
            vuelidateExternalResults: {
                year: []
            },
            cities: [],
            event: '',
            achievement: null,
            places: [
                {
                    id: 0,
                    name: 'Участник'
                },
                {
                    id: 1,
                    name: '1-е место'
                },
                {
                    id: 2,
                    name: '2-е место'
                },
                {
                    id: 3,
                    name: '3-е место'
                }
            ]
        }
    },

    components: {
        'imask-input': IMaskComponent,
        VueMultiselect,
        Cropper,
        CityInput
    },

    methods: {
        onAcceptYear(val) {
            this.year = val
        },
        fetchCities(query) {
            this.city_loading = true
            axios
                .get('/api/v1/search/cities', {
                    params: query
                })
                .then((res) => {
                    this.cities = res.data?.data ?? []
                })
                .catch((err) => {
                    console.log('err', err)
                })
                .finally(() => {
                    this.city_loading = false
                })
        },
        handleSearch: _.debounce(function (query) {
            if (!query) {
                query = { popular: true }
            } else {
                query = { city: query }
            }
            this.fetchCities(query)
        }, 500),
        showModalAddAchievement() {
            try {
                modal.open('#modal-pet-achievement-add')
                this.fetchCities({ type: 'popular' })
            } catch (err) {
                this.$notify({
                    type: 'error',
                    title: err.message ?? 'Что-то пошло не так('
                })
            }
        },
        handleFileChange(file) {
            let reader = new FileReader()
            reader.onload = (e) => {
                this.file = e.target.result
            }
            reader.readAsDataURL(file.target.files[0])
            this.showCropper = true
        },
        handleFileCrop() {
            const { canvas } = this.$refs.cropper.getResult()
            try {
                canvas.toBlob((blob) => {
                    this.result = blob
                })
            } catch (e) {
                console.log('canvas.toBlob error')
            }
        },
        async handleSubmit() {
            if (this.loading) {
                return
            }
            this.v$.$clearExternalResults()
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return
            }
            try {
                this.loading = true
                const formData = new FormData()
                formData.append('year', this.year)
                formData.append('city_id', this.city?.id)
                formData.append('event', this.event)
                formData.append('achievement', this.achievement.id)
                if (this.result) {
                    formData.append('file', this.result)
                }
                const { data } = await axios.post(
                    `/api/v1/pets/${this.petId}/achievements`,
                    formData
                )
                if (data.success || data.id) {
                    this.$notify({
                        type: 'success',
                        title: 'Достижение успешно добавлено'
                    })
                    // TODO: Делать как-то без перезагрузки
                    document.location.reload()
                } else {
                    console.log('data', data)
                    // this.vuelidateExternalResults = Object.assign({}, err.response?.data?.errors)
                }
            } catch (err) {
                console.log('err', err)
                console.log('err.response', err.response)
                const errors = err.response?.data?.errors
                this.vuelidateExternalResults = Object.assign({}, errors)
                this.$notify({
                    type: 'error',
                    title: err.message ?? 'Что-то пошло не так('
                })
            } finally {
                this.loading = false
            }
        },
        onClickOutside(event) {
            this.city = ''
            this.year = ''
            this.event = ''
            this.achievement = ''
            this.file = null
            this.showCropper = false
            this.v$.$reset()
        }
    }
}
</script>
