<script setup>
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    auth: {
        required: false
    }
})

const authUser = computed(() => {
    return props.auth
})

const businesses = ref([])

const linkToBusiness = (business) => {
    return business.status !== 'approved'
        ? '/business/create'
        : `/business/${business.id}/edit/orders`
}

const fetch = () => {
    axios.get('/api/v1/business/my').then((res) => {
        businesses.value = res?.data?.data
    })
}
onMounted(() => {
    fetch()
})
</script>

<template>
    <div class="sm-profile">
        <button
            type="button"
            class="sm-profile__label"
            data-action="dropdown-toggle"
        >
            <img
                :src="authUser.avatar ?? '/img/no-photo.png'"
                id="navbar__avatar"
                :alt="authUser.full_name"
                class="sm-profile__img"
            />
        </button>
        <div class="dropdown-drop" data-action="dropdown-drop">
            <div class="sm-profile__user">
                <div class="profile-status">
                    <div class="profile">
                        <a href="/profile" class="profile__pic">
                            <img
                                :src="authUser.avatar ?? '/img/no-photo.png'"
                                id="navbar__avatar_dropdown"
                                :alt="authUser.full_name"
                                class="profile__img"
                            />
                        </a>
                        <div class="profile__body">
                            <a href="/profile" class="profile__name"
                                >{{ authUser.name }} {{ authUser.surname }}</a
                            >
                            <div class="profile__meta">
                                <div class="profile__meta-item">
                                    Моя страница
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            <div class="sm-profile__nav">-->
            <!--                <nav class="nav">-->
            <!--                    <div class="nav__item">-->
            <!--                        <a-->
            <!--                            href="/profile/shop"-->
            <!--                            class="nav__link"-->
            <!--                            style="display: flex; align-items: center"-->
            <!--                        >-->
            <!--                            Мои объявления-->
            <!--                        </a>-->
            <!--                    </div>-->
            <!--                </nav>-->
            <!--            </div>-->
            <div class="sm-profile__nav">
                <nav class="nav">
                    <div class="nav__title">Мой бизнес</div>
                    <div
                        class="nav__item"
                        v-for="business in businesses"
                        :key="business.id"
                    >
                        <a
                            :href="linkToBusiness(business)"
                            class="nav__link"
                            style="display: flex; align-items: center"
                        >
                            <span style="position: relative">
                                <img
                                    :src="
                                        business?.avatar
                                            ? business?.avatar
                                            : '/img/organization-empty-logo.svg'
                                    "
                                    :alt="business.name"
                                    class="profile__img"
                                    style="
                                        width: 40px;
                                        height: 40px;
                                        margin-right: 8px;
                                    "
                                />
                                <span
                                    class="user-nav__notification"
                                    v-if="business.has_new_orders"
                                ></span>
                            </span>

                            <span>
                                <span>{{ business.name }}</span>
                                <span
                                    v-if="business.status !== 'approved'"
                                    style="
                                        display: block;
                                        color: var(--Gray50);
                                        font-size: 1.4rem;
                                        line-height: 1.2;
                                        font-weight: 400;
                                    "
                                >
                                    {{ business.status_text }}
                                </span>
                            </span>
                        </a>
                    </div>
                    <div class="nav__item">
                        <a
                            href="/business/create"
                            class="nav__link"
                            style="display: flex; align-items: center"
                        >
                            <span> Добавить бизнес </span>
                        </a>
                    </div>
                </nav>
            </div>
            <div class="sm-profile__nav">
                <nav class="nav">
                    <div class="nav__item">
                        <button
                            data-action="modal-open"
                            type="button"
                            data-target="#modal-logout-confirm"
                            class="nav__link"
                        >
                            Выйти
                        </button>
                    </div>
                </nav>
            </div>

            <div class="sm-profile__footer">
                <nav class="nav nav_inline">
                    <div class="nav__item">
                        <a href="/privacy" class="nav__link"
                            >Конфиденциальность</a
                        >
                    </div>
                    <div class="nav__item">
                        <a href="/rules" class="nav__link">
                            Правила пользования
                        </a>
                    </div>

                    <div class="nav__item">
                        <a href="/" class="nav__link">©2024 LAPKI.CLUB</a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
