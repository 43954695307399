<template>
    <div>
        <div class="form__group">
            <div
                v-if="loading"
                class="form__status form__status_gray form__status_wait"
                style="background-color: var(--Gray10)"
            >
                <i class="icon-checked"></i>
            </div>
            <div class="form-control">
                <input
                    type="text"
                    class="form-control__control"
                    :class="{
                        'is-valued': !!props.office_address,
                        error: props.addressErrorMessage
                    }"
                    @focus="activate"
                    v-if="!active"
                    :value="props.office_address"
                />
                <input
                    type="text"
                    v-else
                    ref="searchInput"
                    class="form-control__control"
                    @blur.prevent="deactivate"
                    @keyup.esc="deactivate"
                    @input="handleSearch($event.target.value)"
                    @keydown="loading = true"
                    v-model="query"
                />
                <ul class="autocomplete-result" v-if="query && !loading">
                    <li
                        class="autocomplete-result__item"
                        v-for="item in options"
                        @click.stop="select(item)"
                        @mousedown.stop="select(item)"
                        :key="item.id"
                    >
                        {{ item.result }}
                    </li>
                    <li
                        class="autocomplete-result__item autocomplete-result-info"
                        v-if="query.length && !options.length && !loading"
                    >
                        <span class="autocomplete-result-info__meta"
                            >Ничего не найдено</span
                        >
                    </li>
                </ul>
                <div class="form-control__label" v-if="props.addressLabel">
                    {{ props.addressLabel }}
                </div>
            </div>
        </div>
        <div class="form__group" style="margin-bottom: 8px">
            <div class="form__extra-text">
                Укажите точку на карте двойным кликом или перетащите маркер на
                нужное место
            </div>
        </div>
        <div id="map" style="height: 400px"></div>
        <TextareaInput
            style="margin-top: 16px"
            label="Как пройти, номер подъезда, этаж, квартира (необязательно)"
            v-model="office_additional"
            :error-message="props.officeAdditionalErrorMessage"
        />
    </div>
</template>

<script setup lang="ts">
import mapboxgl from 'mapbox-gl'
import { debounce, uniqBy } from 'lodash'

const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

const emit = defineEmits(['updateAddress', 'updateOfficeAdditional'])

import TextareaInput from '../inputs/TextareaInput.vue'
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl'

import { computed, nextTick, onMounted, ref } from 'vue'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

const map = ref(null)

const props = defineProps({
    coordinates: {
        type: Array
    },
    addressLabel: {
        type: String
    },
    office_address: {
        type: String
    },
    addressErrorMessage: {
        type: String
    },
    officeAdditional: {
        type: String
    },
    officeAdditionalErrorMessage: {
        type: String
    },
    loading: {
        type: Boolean
    }
})

const office_additional = computed({
    get() {
        return props.officeAdditional
    },
    set(value) {
        emit('updateOfficeAdditional', value)
    }
})

const active = ref(false)
const searchInput = ref(null)
const activate = () => {
    active.value = true
    nextTick(() => {
        searchInput.value.focus()
    })
}

const query = ref('')
const options = ref([])

const deactivate = () => {
    nextTick(() => {
        active.value = false
        query.value = ''
        options.value = []
    })
}

const center = ref(props.coordinates)

onMounted(() => {
    mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
    const coordinates = document.getElementById('coordinates')
    map.value = new mapboxgl.Map({
        container: 'map',
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: 'mapbox://styles/mapbox/streets-v12',
        center: props.coordinates,
        zoom: 8
    })

    const language = new MapboxLanguage({
        defaultLanguage: 'ru'
    })
    const controls = new mapboxgl.NavigationControl()
    map.value.addControl(language)
    map.value.addControl(controls)
    map.value.doubleClickZoom.disable()

    const canvas = map.value.getCanvasContainer()

    const geojson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: props.coordinates
                }
            }
        ]
    }

    function onMove(e) {
        const coords = e.lngLat

        // Set a UI indicator for dragging.
        canvas.style.cursor = 'grabbing'

        // Update the Point feature in `geojson` coordinates
        // and call setData to the source layer `point` on it.
        geojson.features[0].geometry.coordinates = [coords.lng, coords.lat]
        map.value.getSource('point').setData(geojson)
    }

    async function onUp(e) {
        const coords = e.lngLat

        // Print the coordinates of where the point had
        // finished being dragged to on the map.
        // coordinates.style.display = 'block'
        // coordinates.innerHTML = `Longitude: ${coords.lng}<br />Latitude: ${coords.lat}`
        // canvas.style.cursor = ''
        console.log('coords', coords)

        // Unbind mouse/touch events
        map.value.off('mousemove', onMove)
        map.value.off('touchmove', onMove)
        const q = [coords.lng, coords.lat]
        // await handleSearch(q)
        await getAddressByCoords(q)
    }

    map.value.on('load', () => {
        // Add a single point to the map.
        map.value.addSource('point', {
            type: 'geojson',
            data: geojson
        })

        map.value.addLayer({
            id: 'point',
            type: 'circle',
            source: 'point',
            paint: {
                'circle-radius': 10,
                'circle-color': '#F84C4C' // red color
            }
        })

        // When the cursor enters a feature in
        // the point layer, prepare for dragging.
        map.value.on('mouseenter', 'point', () => {
            map.value.setPaintProperty('point', 'circle-color', '#3bb2d0')
            canvas.style.cursor = 'move'
        })

        map.value.on('mouseleave', 'point', () => {
            map.value.setPaintProperty('point', 'circle-color', '#3887be')
            canvas.style.cursor = ''
        })

        map.value.on('mousedown', 'point', (e) => {
            // Prevent the default map drag behavior.
            e.preventDefault()

            canvas.style.cursor = 'grab'

            map.value.on('mousemove', onMove)
            map.value.once('mouseup', onUp)
        })

        map.value.on('touchstart', 'point', (e) => {
            if (e.points.length !== 1) return

            // Prevent the default map drag behavior.
            e.preventDefault()

            map.value.on('touchmove', onMove)
            map.value.once('touchend', onUp)
        })

        map.value.on('dblclick', (e) => {
            e.preventDefault()
            onMove(e)
            onUp(e)
        })
    })
})

const handleSearch = debounce(async (q) => {
    try {
        loading.value = true
        let query = encodeURIComponent(q)
        console.log('query', query)
        if (!query) {
            return
        }
        const { data } = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
            {
                params: {
                    access_token: accessToken,
                    language: 'ru',
                    proximity: props.coordinates.join(','),
                    types: 'address'
                }
            }
        )
        const suggestions = data.features.map((item) => {
            const country = item.context.find((ctx) =>
                ctx.id.includes('country')
            )
            const city = item.context.find((ctx) => ctx.id.includes('place'))
            const street = item.text
            const house = item.address ? `, ${item.address}` : ''
            const result = `${city.text}, ${street}${house}`
            return {
                // place_name: item.place_name,
                city_name: city.text,
                // city_id: city.id,
                country_name: country?.text,
                // country_id: country?.id,
                full_name: `${country.text}, ${city.text}`,
                name: `${country.text}, ${city.text}, ${street}`,
                center: item.center,
                result
            }
        })
        options.value = uniqBy(suggestions, 'result')
        loading.value = false
    } catch (e) {
        notify({
            type: 'error',
            text: e.message
        })
    }
}, 400)

const getAddressByCoords = (coords: Array<string>) => {
    axios
        .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.join(
                ','
            )}.json`,
            {
                params: {
                    access_token: accessToken,
                    language: 'ru',
                    proximity: props.coordinates.join(','),
                    types: 'place,address'
                }
            }
        )
        .then((res) => {
            console.log('ress', res)
            const result = res.data?.features[0]
            const city = result?.context?.find((item) =>
                item.id.includes('place')
            )
            const street = result?.text
            const house = result?.address ? `, ${result.address}` : ''
            emit('updateAddress', {
                result: `${city?.text_ru}, ${street}${house}`,
                features: res.data?.features,
                center: result.center
            })
        })
}

const loading = ref(false)

const select = (value) => {
    map.value.flyTo({
        center: value.center,
        zoom: 12
    })
    emit('updateAddress', value)
}
</script>
