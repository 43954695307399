<template>
    <div :class="props.wrapperClass">
        <MapboxMap
            v-show="!props.loading"
            :access-token="accessToken"
            style="height: 100%; width: 100%"
            :center="center"
            :zoom="zoom"
            country-label="ru"
            map-style="mapbox://styles/mapbox/streets-v11"
            @mb-created="(mapInstance) => (map = mapInstance)"
        >
            <template v-for="(mark, i) in props.markers">
                <MapboxMarker
                    v-if="mark && mark.length"
                    :key="i"
                    :lng-lat="mark"
                />
            </template>
        </MapboxMap>
        <!--        <div-->
        <!--            v-if="props.loading"-->
        <!--            style="position: relative; width: 100%; height: 100vh"-->
        <!--        >-->
        <!--            <Skeletor width="auto" height="100%" tag="div" />-->
        <!--        </div>-->
    </div>
</template>

<script setup>
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { computed, onMounted, ref } from 'vue'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import _ from 'lodash'

const map = ref(null)
const zoom = ref(10)

const accessToken =
    'pk.eyJ1IjoiZGZvbWVua28iLCJhIjoiY2xkdWFsdXdtMDN3ajNybzVqMmF4Nm9zMiJ9.DbY1xhEmRAWhikud_1n-vg'

const props = defineProps({
    markers: {
        type: Array,
        required: false
    },
    wrapperClass: {
        type: String,
        default: 'form__group'
    },
    containerClass: {
        type: String,
        default: 'place marker-wrapper'
    },
    height: {
        type: String,
        default: '100px'
    },
    loading: {
        type: Boolean
    }
})

const center = computed(() => {
    if (!props.markers.length) {
        return [37.62270581547201, 55.74987528902282]
    }
    return _.head(props.markers)
})

function scrollToCenter() {
    map.value.flyTo({
        center: center.value
    })
}

defineExpose({
    scrollToCenter
})

onMounted(() => {
    const language = new MapboxLanguage({
        defaultLanguage: 'ru'
    })
    map.value.addControl(language)
    // if (!_.head(props?.markers)?.value) {
    //     center.value = [37.62270581547201, 55.74987528902282]
    // } else {
    //     center.value = props?.markers[0]?.value
    // }
})
</script>
