<template>
    <div class="constructor">
        <div class="constructor__side">
            <div class="constructor__scroll" data-action="burger-scroll">
                <div class="constructor__header">
                    <div class="constructor__logo" v-if="!store.loading">
                        <img :src="'/img/logo-xs.svg'" alt="" />
                    </div>
                    <Skeletor
                        class="constructor__logo"
                        width="32px"
                        height="32px"
                        circle
                        v-else
                    />
                    <Modal v-if="!store.loading && store.step < 8">
                        <template #button="{ show }">
                            <button class="constructor__save" @click="show">
                                Сохранить и выйти
                            </button>
                        </template>
                        <template #default="{ close }">
                            <div class="modal-alert modal-alert_error">
                                <div class="modal-alert__headline">
                                    Вы хотите выйти. Сохранить последние
                                    изменения перед выходом?
                                </div>
                                <div class="modal-alert__controls">
                                    <a
                                        href="/profile"
                                        class="button button_size_sm button_bg_gray"
                                    >
                                        Нет
                                    </a>
                                    <button
                                        type="button"
                                        class="button button_size_sm button_bg_error"
                                        @click="emit('onExit')"
                                    >
                                        Да
                                    </button>
                                </div>
                            </div>
                        </template>
                    </Modal>
                    <Skeletor
                        width="40px"
                        height="16px"
                        pill
                        v-else-if="store.loading"
                    />
                    <div class="constructor__headline" v-if="!store.loading">
                        Добавление бизнеса
                    </div>
                    <Skeletor
                        pill
                        class="constructor__headline"
                        height="28px"
                        v-else
                    />
                </div>
                <div class="constructor-nav">
                    <div class="constructor-nav__list">
                        <div
                            class="constructor-nav__item"
                            v-for="step in store.steps"
                            :key="step.id"
                        >
                            <div
                                v-if="!store.loading"
                                class="constructor-nav__link"
                                :class="{
                                    'constructor-nav__link_current':
                                        store.step === step.id,
                                    'constructor-nav__link_complete':
                                        store.completedSteps.includes(step.id)
                                }"
                            >
                                <div class="constructor-nav__number">
                                    {{ step.id }}
                                </div>
                                <div class="constructor-nav__name">
                                    {{ step.label }}
                                </div>
                                <div class="constructor-nav__label">
                                    <i class="icon-checked"></i>
                                </div>
                            </div>
                            <div v-else class="constructor-nav__link">
                                <Skeletor
                                    class="constructor-nav__number"
                                    width="28px"
                                    height="28px"
                                    circle
                                />
                                <Skeletor
                                    class="constructor-nav__name"
                                    height="18px"
                                    width="170px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="constructor__mobilebar">
            <div class="constructor__headline">Добавить бизнес</div>
            <button
                type="button"
                class="constructor__toggle"
                data-action="burger-open"
            >
                <i class="icon-menu2"></i>
            </button>
        </div>
        <div class="constructor__main">
            <div class="constructor__topbar"></div>
            <div class="constructor__inner">
                <div class="constructor__content">
                    <div class="constructor__header" v-if="!hideHeader">
                        <div
                            class="constructor__headline"
                            v-if="!store.loading"
                        >
                            <div class="constructor__number">
                                {{ store.step }}
                            </div>
                            {{ store.stepLabel }}
                        </div>
                        <div class="constructor__headline" v-else>
                            <Skeletor height="56px" circle width="56px" />
                            <div style="width: 20px"></div>
                            <Skeletor height="42px" width="90px" />
                        </div>
                    </div>
                    <slot />
                </div>
            </div>
            <div class="constructor__footer" v-if="!props.hideFooter">
                <div class="constructor-progress">
                    <div
                        v-for="step in store.steps"
                        :key="step.id"
                        class="constructor-progress__item"
                        :class="{
                            'constructor-progress__item_complete':
                                step.id <= store.step
                        }"
                    ></div>
                </div>
                <div class="constructor__controls">
                    <div class="constructor__control">
                        <button
                            type="button"
                            class="button button_bg_gray button_size_sm"
                            @click="store.prevStep()"
                            :disabled="!store.hasPrevStep"
                            v-if="!store.loading"
                        >
                            Назад
                        </button>
                        <Skeletor v-else height="42px" width="90px" />
                    </div>
                    <div class="constructor__control">
                        <button
                            type="button"
                            class="button button_size_sm"
                            @click="emit('nextStep')"
                            :disabled="!store.hasNextStep"
                            v-if="!store.loading"
                        >
                            Далее
                        </button>
                        <Skeletor v-else height="42px" width="90px" />
                    </div>
                </div>
            </div>
        </div>
        <div class="constructor__helper" v-if="slots.helper_content">
            <div class="constructor__fixed">
                <div class="constructor__scroll">
                    <div class="constructor-section">
                        <div
                            class="constructor-section__header"
                            v-if="slots.helper_title"
                        >
                            <div
                                class="constructor-section__headline"
                                v-if="!store.loading"
                            >
                                <slot name="helper_title"></slot>
                            </div>
                            <Skeletor v-else height="23px" width="160px" pill />
                        </div>
                        <div class="constructor-section__body">
                            <slot name="helper_content"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBusinessStore } from '../../stores/BusinessStore'
import { useSlots } from 'vue'
import { Skeletor } from 'vue-skeletor'
import Modal from '../../components/Modal.vue'

const props = defineProps({
    hideHeader: {
        type: Boolean,
        default: false
    },
    hideFooter: {
        type: Boolean,
        default: false
    }
})
const store = useBusinessStore()
const slots = useSlots()

const emit = defineEmits(['nextStep', 'onExit'])
</script>
