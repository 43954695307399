<template>
    <button type="button" class="post__button toggle_favorite__button"
            data-tippy-content="Закладки"
            @click="toggleFavorite"
            :class="{'post__button_active': inFavorite}">
        <i :class="inFavorite ? 'icon-bookmarked' : 'icon-bookmark'"></i>
    </button>
</template>

<script>
export default {
    name: "Favorites",

    props: {
        id: {
            required: true,
        },
        favorited: {
            type: String,
            default: 'no'
        },
        auth_user_id: {
            default: null
        }
    },

    data() {
        return {
            inFavorite: this.favorited === 'favorited'
        }
    },

    methods: {
        toggleFavorite() {
            if (!this.auth_user_id) {
                this.$notify({
                    title: 'Необходимо войти или зарегистрироваться'
                })
                return
            }
            axios.post(`/api/v1/posts/toggle-favorite`, {
                    id: this.id
                })
                .then((res) => {
                    this.inFavorite = !this.inFavorite
                })
        }
    }
}
</script>
