<template>
    <Layout @nextStep="handleSubmit" @onExit="handleExit">
        <div class="form">
            <div class="form__set">
                <div class="form__strokes">
                    <CheckboxInput
                        label="Выезд на дом"
                        v-model="v$.home_visit.$model"
                        :loading="store.loading"
                        :errorMessage="v$.home_visit.$errors[0]?.$message"
                    />
                    <CheckboxInput
                        label="Есть офис"
                        v-model="v$.has_office.$model"
                        :loading="store.loading"
                        :errorMessage="v$.has_office.$errors[0]?.$message"
                    />
                </div>
                <CityIdInput
                    v-if="!has_office"
                    label="Город предоставления услуг"
                    :initial-city="store.initialValues?.city"
                    :loading="store.loading"
                    v-model="v$.city_id.$model"
                    :errorMessage="v$.city_id.$errors[0]?.$message"
                />
                <AddressInput
                    v-if="has_office"
                    :coordinates="coordinates"
                    @updateAddress="handleUpdateAddress"
                    :office_address="v$.office_address.$model"
                    :loading="store.loading"
                    :office-additional="v$.office_additional.$model"
                    :office-additional-error-message="
                        v$.office_additional.$errors[0]?.$message
                    "
                    @updateOfficeAdditional="handleUpdateOfficeAdditional"
                    address-label="Адрес"
                />
            </div>
            <div class="form__set">
                <div class="form__group">
                    <div class="form__extra-text" v-if="!store.loading">
                        Укажите телефон и email, используемые для контакта с
                        клиентами.
                    </div>
                </div>
                <!--                                <TextInput label="Номер телефона" name="phone"/>-->
                <!--                <PhoneInput label="Номер телефона" name="phone" />-->
                <TextInput
                    label="Email"
                    v-model="v$.email.$model"
                    :loading="store.loading"
                    :errorMessage="v$.email.$errors[0]?.$message"
                />
                <PhoneInput
                    label="Телефон"
                    v-model="v$.phone.$model"
                    :loading="store.loading"
                    :errorMessage="v$.phone.$errors[0]?.$message"
                />
                <TextInput
                    label="Веб-сайт (необязательно)"
                    v-model="v$.site.$model"
                    :loading="store.loading"
                    :errorMessage="v$.site.$errors[0]?.$message"
                />
            </div>
        </div>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { computed, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators'
import TextInput from '../inputs/TextInput.vue'
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { boolean } from '../../../rules/boolean'
import CheckboxInput from '../inputs/CheckboxInput.vue'
import CityIdInput from '../inputs/CityIdInput.vue'
import AddressInput from '../inputs/AddressInput.vue'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { requiredIfLocalized } from '../../../rules/requiredIfLocalized'
import { emailLocalized } from '../../../rules/emailLocalized'
import { maxLengthLocalized } from '../../../rules/maxLengthLocalized'
import PhoneInput from '../inputs/PhoneInput.vue'

const store = useBusinessStore()

const home_visit = ref(store.initialValues?.home_visit)
const has_office = ref(store.initialValues?.has_office)
const city_id = ref(store.initialValues?.city_id)
const email = ref(store.initialValues?.email)
const site = ref(store.initialValues?.site)
const phone = ref(store.initialValues?.phone)
const coordinates = ref(
    store.initialValues?.coordinates?.length
        ? store.initialValues?.coordinates
        : store.initialValues?.city?.center
)
const features = ref()
const office_address = ref(store.initialValues?.office_address)
const office_additional = ref(store.initialValues?.office_additional)

const rules = {
    home_visit: {
        requiredLocalized,
        boolean
    },
    has_office: {
        requiredLocalized,
        boolean
    },
    city_id: {
        requiredLocalized
    },
    phone: {
        requiredLocalized
    },
    coordinates: {
        requiredIfLocalized: requiredIfLocalized(has_office)
    },
    email: {
        requiredLocalized,
        emailLocalized
    },
    site: {
        maxLengthLocalized: maxLengthLocalized(255)
    },
    office_address: {
        maxLengthLocalized: maxLengthLocalized(500)
    },
    office_additional: {
        maxLengthLocalized: maxLengthLocalized(1000)
    }
}

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    {
        home_visit,
        has_office,
        city_id,
        email,
        site,
        office_address,
        coordinates,
        phone,
        office_additional
    },
    { $externalResults }
)

const handleSubmit = async () => {
    if (!v$.value.$anyDirty && store.completedSteps.includes(2)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    axios
        .post(`/api/v1/business/${store.initialValues.id}`, {
            step: 2,
            home_visit: home_visit.value,
            has_office: has_office.value,
            city_id: city_id.value,
            office_address: office_address.value,
            coordinates: coordinates.value,
            features: features.value,
            email: email.value,
            office_additional: office_additional.value,
            site: site.value,
            phone: phone.value.replaceAll(' ', '').replaceAll('-', '')
        })
        .then((res) => {
            console.log('res', res)
            store.setInitialValues(res.data.data, 3)
        })
        .catch((error) => {
            const errors = error.response.data.errors
            console.log('errors', errors)
            if (!errors) {
                notify({
                    type: 'error',
                    text: error.message
                })
                return
            }
            $externalResults.value = errors
        })
        .finally(() => {
            store.stopLoading()
        })
}

const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    const formData = {}
    formData['step'] = 2
    const fields = [
        'home_visit',
        'has_office',
        'city_id',
        'email',
        'site',
        'coordinates',
        'phone',
        'office_address',
        'office_additional'
    ]
    fields.forEach((field) => {
        if (eval(`${field}.value`)) {
            formData[field] = eval(`${field}.value`)
        }
    })

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            formData
        )
        // document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}

const handleUpdateAddress = (address) => {
    console.log('address', address)
    office_address.value = address.result
    coordinates.value = address.center
    features.value = address.features
}

const handleUpdateOfficeAdditional = (value) => {
    office_additional.value = value
    v$.value.office_additional.$touch()
}
</script>
