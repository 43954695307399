<template>
    <div v-if="notificationsData.length">
        <div
            v-for="notification in notificationsData"
            :key="notification.id"
            class="relative"
        >
            <template v-if="notification">
                <a
                    :href="notification.data['post_uuid']"
                    @click="handleRead(notification.id)"
                    class="notification simple__notification"
                >
                    <div class="notification__pic">
                        <img
                            :src="
                                notification.data['author_avatar'] ??
                                '/img/no-photo.png'
                            "
                            alt=""
                            class="notification__img"
                        />
                    </div>
                    <div class="notification__body">
                        <div class="notification__info">
                            <div class="notification__name">
                                {{ notification.data['author_name'] }}
                            </div>
                            <div class="notification__meta">
                                <div class="notification__date">
                                    {{ notification.created_at_formatted }}
                                </div>
                            </div>
                        </div>
                        <div class="notification__event">
                            <p
                                v-if="
                                    notification.type.includes(
                                        'NewCommentNotification'
                                    )
                                "
                            >
                                Оставлен комментарий к посту "<a
                                    class="notification__host"
                                    style="display: inline !important"
                                    :href="`/posts/${notification.data['post_id']}`"
                                    >{{
                                        notification.data[
                                            'post_title'
                                        ].substring(0, 30)
                                    }}...</a
                                >"
                            </p>
                            <p
                                v-else-if="
                                    notification.type.includes(
                                        'NewCommentReplyNotification'
                                    )
                                "
                            >
                                Оставлен ответ вашему
                                <a
                                    class="notification__host"
                                    style="display: inline !important"
                                    :href="`/posts/${notification.data['post_id']}#${notification.data['comment_id']}`"
                                    >комментарию к посту</a
                                >
                            </p>
                        </div>
                    </div>
                </a>
                <button
                    type="button"
                    class="button button_bg_secondary button_circle notification__hide"
                    style="position: absolute; right: 18px; top: 11px"
                    @click="handleClose(notification)"
                >
                    <i class="icon-close"></i>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import { notificationsMixin } from '@/components/Notifications/NotificationsMixin.js'

export default {
    name: 'OtherNotifications',

    mixins: [notificationsMixin]
}
</script>

<style scoped>
.relative {
    position: relative;
}
</style>
