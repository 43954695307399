<template>
    <div :class="$attrs['class'] ?? 'profile__pic'">
        <img :src="resultAvatar" :class="classes" alt="" class="profile__img" />
        <slot name="additional__pic"></slot>
    </div>
    <div v-if="editable === 'editable'" class="profile__photo-edit">
        <template v-if="original_avatar">
            <button
                type="button"
                class="profile__edit-toggle"
                data-action="dropdown-toggle"
            >
                <i class="icon-camera"></i>
            </button>
            <div
                class="dropdown-drop dropdown-drop_pos_center dropdown-drop_width_sm"
                data-action="dropdown-drop"
            >
                <div class="navigation">
                    <div class="navigation__item">
                        <label type="button" class="navigation__link">
                            <span class="navigation__icon">
                                <i class="icon-refresh"></i>
                            </span>
                            <span class="navigation__text"> Изменить </span>
                            <input
                                type="file"
                                accept="image/jpeg,image/png"
                                @change="handleFileChange"
                                class="photo-add__input"
                            />
                        </label>
                    </div>
                    <div class="navigation__item">
                        <button
                            type="submit"
                            class="navigation__link"
                            @click="showDeleteAvatarForm"
                        >
                            <span class="navigation__icon">
                                <i class="icon-trash"></i>
                            </span>
                            <span class="navigation__text">Удалить</span>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <label class="photo-add__add" v-else>
            <input
                type="file"
                accept="image/jpeg,image/png"
                @change="handleFileChange"
                class="photo-add__input"
            />
            <i class="icon-camera"></i>
        </label>
    </div>

    <Teleport to="body">
        <div class="modal" id="modal-profile-upload-picture">
            <div class="modal__content" data-modal="modal-content">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__close"
                        data-action="modal-close"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__inner modal__inner_wide">
                    <div class="modal__header">
                        <div class="modal__headline">
                            Загрузка изображения профиля
                        </div>
                    </div>
                    <div class="modal__body">
                        <div class="form upload-picture__form">
                            <div class="alert alert_error" v-if="errors">
                                <div class="alert__content">
                                    <div class="alert__icon">
                                        <i class="icon-error_a"></i>
                                    </div>
                                    <div class="alert__text">
                                        {{
                                            errors.image
                                                ? errors.image[0]
                                                : errors
                                        }}
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="alert__close"
                                    data-action="alert-close"
                                >
                                    <i class="icon-close"></i>
                                </button>
                            </div>
                            <template v-if="showCropper">
                                <cropper
                                    ref="cropper"
                                    class="cropper"
                                    :src="file"
                                    height="200"
                                    image-restriction="fit-area"
                                    :stencil-component="
                                        $options.components.CircleStencil
                                    "
                                    :stencil-props="{
                                        aspectRatio: 1
                                    }"
                                    @change="handleFileCrop"
                                ></cropper>
                                <p class="form__extra-text">
                                    Вы можете загрузить изображение размером до
                                    5mb в форматах .jpg и .png.
                                </p>
                                <label
                                    class="form-photo"
                                    style="margin-top: 12px"
                                >
                                    <span class="form-photo__text">
                                        Выбрать другое изображение
                                    </span>
                                    <input
                                        type="file"
                                        accept="image/jpeg,image/png"
                                        @change="handleFileChange"
                                        class="photo-add__input"
                                    />
                                </label>
                            </template>

                            <div class="form__footer">
                                <div class="form__controls" v-if="showCropper">
                                    <!--                                    <button type="button" class="button button_bg_gray" @click="cancel">-->
                                    <!--                                        Отмена-->
                                    <!--                                    </button>-->
                                    <button
                                        type="button"
                                        class="button"
                                        @click="handleSubmit"
                                        :class="{ 'is-wait': loading }"
                                    >
                                        Сохранить
                                    </button>
                                </div>
                                <div class="form__controls" v-else>
                                    <button
                                        type="button"
                                        class="button button_bg_gray"
                                        data-action="modal-close"
                                    >
                                        Отмена
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modal-profile-remove-avatar-confirmation">
            <div class="modal__content" data-modal="modal-content">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__close"
                        data-action="modal-close"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__inner">
                    <div class="modal__header" style="margin-bottom: 0">
                        <div class="modal-alert modal-alert_error">
                            <div class="modal-alert__headline">
                                Вы уверены что хотите удалить аватар?
                            </div>
                            <div class="modal-alert__controls">
                                <button
                                    type="button"
                                    class="button button_size_sm button_bg_gray"
                                    data-action="modal-close"
                                >
                                    Нет
                                </button>
                                <button
                                    type="button"
                                    class="button button_size_sm button_bg_error"
                                    @click="handleDeleteAvatar"
                                >
                                    Да, удалить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
    name: 'Avatar',

    components: {
        Cropper,
        CircleStencil
    },

    props: {
        classes: {
            default: ''
        },
        avatar: {
            type: String
        },
        original_avatar: {
            required: false
        },
        editable: {
            type: String,
            default: 'view'
        },
        url: {
            required: true
        },
        delete_url: {
            required: true
        },
        form_data: {
            required: false
        },
        update_layout: {
            default: true
        }
    },

    data() {
        return {
            file: null,
            showCropper: false,
            result: null,
            errors: null,
            loading: false,
            resultAvatar: this.avatar
        }
    },

    methods: {
        handleFileChange(file) {
            this.errors = null
            if (!this.showCropper) {
                modal.open('#modal-profile-upload-picture')
            }
            let reader = new FileReader()
            reader.onload = (e) => {
                this.file = e.target.result
            }
            reader.readAsDataURL(file.target.files[0])
            this.showCropper = true
            this.handleFileCrop()
        },
        handleFileCrop() {
            this.errors = null
            const { canvas } = this.$refs.cropper.getResult()
            try {
                canvas.toBlob((blob) => {
                    this.result = blob
                })
            } catch (e) {
                console.log('canvas.toBlob error')
            }
        },
        async handleSubmit() {
            this.loading = true
            this.errors = null
            const formData = new FormData()
            formData.append('image', this.result)
            if (this.form_data) {
                const additional_form_data = JSON.parse(this.form_data) ?? []
                additional_form_data.map((item) => {
                    formData.append(item.key, item.value)
                })
            }
            axios
                .post(this.url, formData)
                .then((res) => {
                    if (res.data?.success) {
                        this.updateAvatar(res.data?.avatar)
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 413) {
                        this.errors = 'Изображение не может быть больше 5 МБ'
                    } else {
                        this.errors =
                            err.response?.data?.errors ??
                            err.response?.data?.message
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showDeleteAvatarForm() {
            modal.open('#modal-profile-remove-avatar-confirmation')
        },
        handleDeleteAvatar() {
            axios
                .post(this.delete_url)
                .then((res) => {
                    console.log('res: ' + res)
                    if (res.data?.success) {
                        this.updateAvatar(res.data?.avatar)
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
        updateAvatar(avatar = null) {
            this.resultAvatar = avatar ?? this.avatar
            modal.close()
            this.showCropper = false
            this.resetInput()
            this.$notify({
                type: 'success',
                title: 'Аватар успешно обновлён'
            })
            if (this.update_layout) {
                document.getElementById('navbar__avatar').src =
                    this.resultAvatar
                document.getElementById('navbar__avatar_dropdown').src =
                    this.resultAvatar
            }
        },
        cancel() {
            this.showCropper = false
            this.resetInput()
        },
        resetInput() {
            this.file = null
            this.result = null
            this.errors = null
            const inputs = document.querySelectorAll('[type="file"]')
            inputs.forEach((input) => {
                input.value = null
            })
        }
    }
}
</script>

<style scoped>
.cropper {
    height: 320px;
}
</style>
