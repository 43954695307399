import './bootstrap'

import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { createPinia } from 'pinia'
const pinia = createPinia()

// Scripts
import LandingScripts from '@/components/LandingScripts.vue'

// Components
import AppHeader from './components/AppHeader.vue'

// Auth
import ModalRegister from '@/components/Auth/ModalRegister.vue'
import ModalLogin from '@/components/Auth/ModalLogin.vue'
import ModalEnterCode from '@/components/Auth/ModalEnterCode.vue'
import ModalRegisterButton from '@/components/Auth/ModalRegisterButton.vue'

// Profile
import EditProfile from '@/components/Profile/EditProfile.vue'
import PetsButtons from '@/components/Profile/PetsButtons.vue'

// Pets
import EditPet from '@/components/Pets/EditPet.vue'
import RipPet from '@/components/Pets/RipPet.vue'
import DeletePet from '@/components/Pets/DeletePet.vue'
import PetParents from '@/components/Pets/PetParents.vue'
import PetsList from '@/components/Pets/PetsList.vue'
import PetsFilter from '@/components/Pets/PetsFilter.vue'

// Achievements
import Achievements from '@/components/Achievements/Achievements.vue'
import CreateAchievement from '@/components/Achievements/CreateAchievement.vue'

// Users
import UsersList from '@/components/Users/UsersList.vue'
import DeleteFriendConfirmation from '@/components/Users/DeleteFriendConfirmation.vue'
import UserControls from '@/components/Users/UserControls.vue'
import UsersFilter from '@/components/Users/UsersFilter.vue'

// Notifications
import AppNotifications from '@/components/Notifications/AppNotifications.vue'

// Avatar
import Avatar from '@/components/Avatar.vue'

// Posts
import PostForm from '@/components/Posts/PostForm.vue'

import Notifications from '@kyvg/vue3-notification'
import Likes from '@/components/Posts/Likes.vue'
import Favorites from '@/components/Posts/Favorites.vue'
import PostsList from '@/components/Posts/PostsList.vue'

// Communitis
import SubscriptionToggler from '@/components/Communities/SubscriptionToggler.vue'
import CommunitiesList from '@/components/Communities/CommunitiesList.vue'

//
import DeleteButtonWithModalConfirmation from '@/components/DeleteButtonWithModalConfirmation.vue'

// Chat
import Chat from '@/components/Chat/Chat.vue'
import ChatButton from '@/components/Chat/ChatButton.vue'

// Communities
import MyCommunities from '@/components/Profile/MyCommunities.vue'

// Business
import BusinessCreate from '@/pages/business/Create.vue'
import BusinessShow from '@/components/Business/Show.vue'
import BusinessList from '@/components/Business/List.vue'
import BusinessEditOrders from '@/components/Business/Edit/Orders.vue'
import BusinessEditAbout from '@/components/Business/Edit/About.vue'
import BusinessEditContacts from '@/components/Business/Edit/Contacts.vue'
import BusinessEditWorkingHours from '@/components/Business/Edit/WorkingHours.vue'
import BusinessEditPhoto from '@/components/Business/Edit/Photo.vue'
import BusinessEditServiceTypes from '@/components/Business/Edit/ServiceTypes.vue'
import BusinessEditServices from '@/components/Business/Edit/Services.vue'

import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'

// Firebase Cloud Messaging
import FirebaseCloudMessaging from './components/FirebaseCloudMessaging.vue'
import ToastNotifications from './components/ToastNotifications.vue'

// Cookies
import Cookies from './components/Cookies.vue'

import FriendsList from './pages/profile/friends/FriendsList.vue'

import ProfileMenu from './components/ProfileMenu.vue'

// Referral
import Invite from './components/Referral/Invite.vue'

// Landing
import Landing from './pages/Landing.vue'

const app = createApp({
    components: {
        'app-header': AppHeader,
        'modal-register': ModalRegister,
        'modal-login': ModalLogin,
        'modal-enter-code': ModalEnterCode,
        'edit-profile': EditProfile,
        'pets-buttons': PetsButtons,
        'landing-scripts': LandingScripts,
        'edit-pet': EditPet,
        'rip-pet': RipPet,
        'delete-pet': DeletePet,
        'create-achievement': CreateAchievement,
        'users-list': UsersList,
        'pet-parents': PetParents,
        'app-notifications': AppNotifications,
        'delete-friend-confirmation': DeleteFriendConfirmation,
        avatar: Avatar,
        'user-controls': UserControls,
        'post-form': PostForm,
        'subscription-toggler': SubscriptionToggler,
        likes: Likes,
        favorites: Favorites,
        'communities-list': CommunitiesList,
        'users-filter': UsersFilter,
        'pets-list': PetsList,
        'pets-filter': PetsFilter,
        delete_button_with_modal_confirmation:
            DeleteButtonWithModalConfirmation,
        'posts-list': PostsList,
        chat: Chat,
        'chat-button': ChatButton,
        'my-communities': MyCommunities,
        achievements: Achievements,
        'modal-register-button': ModalRegisterButton,
        'business-create': BusinessCreate,
        'business-edit-orders': BusinessEditOrders,
        'business-edit-working-hours': BusinessEditWorkingHours,
        'business-edit-about': BusinessEditAbout,
        'business-edit-contacts': BusinessEditContacts,
        'business-edit-photo': BusinessEditPhoto,
        'business-edit-service_types': BusinessEditServiceTypes,
        'business-edit-services': BusinessEditServices,
        'business-show': BusinessShow,
        'business-list': BusinessList,
        'friends-list': FriendsList,
        'firebase-cloud-messaging': FirebaseCloudMessaging,
        'toast-notifications': ToastNotifications,
        'profile-menu': ProfileMenu,
        invite: Invite,
        cookies: Cookies,
        landing: Landing
    }
})
    .use(Notifications)
    .use(pinia)
    .use(OpenLayersMap)

import * as Sentry from '@sentry/vue'

// Sentry.init({
//     app,
//     dsn: 'https://67a0898633714d4eb97941a459945ca9@o279119.ingest.sentry.io/4505080048123904',
//     integrations: [new Sentry.Replay()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//
//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//         /^https:\/\/lapki\.club/,
//         /^https:\/\/dev\.lapki\.club/
//     ],
//
//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0
// })

app.mount('#app')
