<template>
    <div
        class="chat-user"
        :class="{
            'chat-user_select': room.id === chatStore.activeRoomId
        }"
        @click="handleRoomClick(room.id)"
    >
        <div class="chat-user__pic">
            <img
                :src="
                    parseInt(room.recipient_id) === parseInt(authId)
                        ? getUserAvatar(room.user?.avatar)
                        : room.recipient?.avatar
                        ? getUserAvatar(room.recipient?.avatar)
                        : room.recipient_type === 'business'
                        ? '/img/organization-empty-logo.svg'
                        : '/img/no-photo.png'
                "
                alt=""
                class="chat-user__img"
            />
        </div>
        <div class="chat-user__body">
            <div class="chat-user__info">
                <div class="chat-user__name">
                    {{ room.name }}
                </div>
                <div class="chat-user__meta">
                    <div class="chat-user__date" v-if="room.last_message">
                        {{ room.last_message.created_at_formatted }}
                        <span
                            class="unread_count"
                            v-if="room.unread_count > 0"
                            style="right: -25px"
                        >
                            {{ room.unread_count }}
                        </span>
                    </div>
                    <!--				<div class="chat-user__status chat-user__status_online"></div>-->
                </div>
            </div>
            <div class="chat-user__text">
                <span v-if="room.last_message">
                    <span
                        v-if="
                            parseInt(room.last_message.user_id) ===
                            parseInt(props.authId)
                        "
                        >Вы:
                    </span>
                    {{
                        room.last_message.message.length > 25
                            ? room.last_message.message.substring(0, 25) + '...'
                            : room.last_message.message
                    }}
                </span>
                <span v-else>Сообщений пока нет</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useChatStore } from '../../stores/ChatStore'

const props = defineProps({
    room: {
        required: true
    },
    authId: {
        required: true
    }
})

const chatStore = useChatStore()
const handleRoomClick = (id) => {
    chatStore.setActiveRoomId(id)
}

const getUserAvatar = (avatar = null) => {
    if (!avatar || avatar === '') {
        return '/img/no-photo.png'
    }
    return avatar
}
</script>
