<template>
    <div class="form__group">
        <Multiselect
            :model-value="modelValue"
            :custom-label="
                (option) => options.find((item) => item.id === option).label
            "
            :options="options.map((item) => item.id)"
            @select="handleSelect"
            placeholder="Месяц"
            :show-labels="false"
        />
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import Multiselect from 'vue-multiselect'

const props = defineProps({
    modelValue: {
        type: Number,
        required: false
    },
    options: {
        type: Array,
        default: () => {
            return [
                {
                    id: 1,
                    label: 'Январь'
                },
                {
                    id: 2,
                    label: 'Февраль'
                },
                {
                    id: 3,
                    label: 'Март'
                },
                {
                    id: 4,
                    label: 'Апрель'
                },
                {
                    id: 5,
                    label: 'Май'
                },
                {
                    id: 6,
                    label: 'Июнь'
                },
                {
                    id: 7,
                    label: 'Июль'
                },
                {
                    id: 8,
                    label: 'Август'
                },
                {
                    id: 9,
                    label: 'Сентябрь'
                },
                {
                    id: 10,
                    label: 'Октябрь'
                },
                {
                    id: 11,
                    label: 'Ноябрь'
                },
                {
                    id: 12,
                    label: 'Декабрь'
                }
            ]
        }
    }
})

const { options, modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const handleSelect = (value) => {
    emit('update:modelValue', value)
}
</script>
