<template>
    <Layout
        @nextStep="handleSubmit"
        @onExit="handleExit"
        hide-footer
        hide-header
    >
        <div class="constructor-success">
            <div class="constructor-success__icon" v-if="!store.loading">
                👍
            </div>
            <Skeletor
                v-else
                circle
                height="60"
                width="60"
                style="margin: 0 auto"
                as="div"
            ></Skeletor>
            <div class="constructor-success__header">
                <div
                    class="constructor-success__headline"
                    v-if="!store.loading"
                >
                    Круто! <br />Вы сделали это.
                </div>
                <template v-else>
                    <div style="height: 8px"></div>
                    <Skeletor height="25" width="300" as="div"></Skeletor>
                    <Skeletor height="25" width="600" as="div"></Skeletor>
                </template>
                <!--                                <a href="#" class="constructor-success__link">Показать результат</a>-->
            </div>
            <div class="constructor-success__text" v-if="!store.loading">
                Вам осталось отправить профиль своего бизнеса на модерацию.
            </div>
            <Skeletor
                v-else
                height="20"
                as="div"
                style="margin-top: 20px"
            ></Skeletor>

            <CheckboxInput
                style="text-align: left"
                :loading="store.loading"
                :label="'Я ознакомился и согласен с <a target=\'_blank\' href=\'/rules\'>Правилами пользования</a> сервисом.'"
                v-model="v$.agreement.$model"
                :errorMessage="v$.agreement.$errors[0]?.$message"
            />

            <div class="constructor-success__footer">
                <button
                    type="button"
                    class="button button_size_sm"
                    @click="handleSubmit"
                    v-if="!store.loading"
                >
                    Отправить на модерацию
                </button>
                <Skeletor v-else height="42px" width="200px" />
                <button
                    type="button"
                    class="button button_bg_gray button_size_sm"
                    @click="store.prevStep"
                    v-if="!store.loading"
                >
                    Назад
                </button>
                <Skeletor v-else height="42px" width="100px" />
            </div>
        </div>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import CheckboxInput from '../inputs/CheckboxInput.vue'
import { ref } from 'vue'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { isTrue } from '../../../rules/isTrue'
import { Skeletor } from 'vue-skeletor'

const store = useBusinessStore()

const agreement = ref(store.initialValues?.agreement)
const rules = {
    agreement: {
        isTrue: helpers.withMessage('Вы должны согласиться с условиями', isTrue)
    }
}

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    {
        agreement
    },
    {
        $externalResults
    }
)

const handleSubmit = async () => {
    v$.value.$clearExternalResults()
    if (!v$.value.$anyDirty && store.completedSteps.includes(7)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }
    store.startLoading()
    try {
        const {
            data: { data }
        } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
            step: 7,
            agreement: agreement.value
        })
        store.setInitialValues(data, 8)
    } catch (error) {
        const errors = error.response.data.errors
        console.log('errors', errors)
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}
const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            {
                step: 7,
                agreement: agreement.value
            }
        )
        document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}
</script>
