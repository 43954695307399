<script setup>
import { $vfm, VueFinalModal } from 'vue-final-modal'
import { useNotification } from '@kyvg/vue3-notification'

const props = defineProps(['phone', 'business_id'])

const preparePhone = (phone) => {
    if (!phone) {
        return 'Ошибка номера телефона'
    }
    phone = phone.replace(/\D/g, '')
    return phone.replace(
        /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
        '+$1 ($2) $3 $4-$5'
    )
}

const { notify } = useNotification()
const createNewOrder = async () => {
    try {
        const res = await axios.post('/api/v1/orders', {
            business_id: props.business_id
        })
        if (res.data) {
            $vfm.hideAll()
            notify({
                type: 'success',
                title: 'Новый заказ создан'
            })
            document.location.href = `/chat/${props.business_id}?type=business`
        }
    } catch (e) {
        notify({
            type: 'error',
            title: 'Ошибка'
        })
    }
}
</script>

<template>
    <VueFinalModal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="modal-container modal_short"
        content-class="modal__content"
        :click-to-close="false"
        :esc-to-close="true"
    >
        <div class="modal__bar">
            <button type="button" class="modal__close" @click="close">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal__header">
            <div class="modal__headline">Заказать услугу</div>
        </div>
        <div class="modal__body">
            <form class="service-booking">
                <div class="booking-card">
                    <!--                    <div class="booking-card__main">-->
                    <!--                        <div class="booking-card__pic">-->
                    <!--                            <img src="static/uploads/pet-up1.png" alt="" class="booking-card__img"/>-->
                    <!--                            <div class="booking-card__feature booking-card__feature_bg_orange">-->
                    <!--                                <i class="icon-grooming"></i>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                        <div class="booking-card__info">-->
                    <!--                            <div class="booking-card__name">Наименование</div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="booking-card__content">
                        <div class="booking-card__text">
                            Вы можете заказать услугу по телефону
                        </div>
                        <div class="booking-card__contacts">
                            <a
                                :href="`tel:${props.phone}`"
                                class="booking-card__value phone"
                            >
                                {{ preparePhone(props.phone) }}
                            </a>
                        </div>
                        <div class="booking-card__information">
                            <div class="booking-card__title">
                                Или сделать это онлайн
                            </div>
                            <!--                            <div class="booking-card__description">-->
                            <!--                                <p>-->
                            <!--                                    <i class="icon-checked"></i>Получайте дополнительные балы в свой профиль-->
                            <!--                                </p>-->
                            <!--                                <p>-->
                            <!--                                    <i class="icon-checked"></i>Выберите мастера и удобное время - а мы покажем-->
                            <!--                                    доступные даты-->
                            <!--                                </p>-->
                            <!--                                <p>-->
                            <!--                                    <i class="icon-checked"></i>Напомним о предстоящем посещении мастера-->
                            <!--                                </p>-->
                            <!--                            </div>-->
                            <button
                                type="button"
                                class="button"
                                @click="createNewOrder"
                            >
                                Заказать онлайн
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </VueFinalModal>
</template>

<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.phone:hover {
    color: var(--Base0);
}
</style>
