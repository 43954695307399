<template>
    <button
        type="button"
        @click="toggleLike"
        class="toggle_like__button post__button post__like"
        data-tippy-content="Понравилось"
        :class="{ post__button_active: inLiked }"
    >
        <i class="icon-like" :class="{ 'icon-liked': inLiked }"></i>
        <span>{{ likedCount }}</span>
    </button>
</template>

<script>
export default {
    name: 'Likes',

    props: {
        id: {
            required: true
        },
        liked: {
            type: String,
            default: 'no'
        },
        count: {
            default: 0
        },
        auth_user_id: {
            default: null
        }
    },

    data() {
        return {
            inLiked: this.liked === 'liked',
            likedCount: this.count,
            loading: false
        }
    },

    methods: {
        toggleLike() {
            if (this.loading) {
                return
            }
            this.loading = true
            if (!this.auth_user_id) {
                this.$notify({
                    title: 'Необходимо войти или зарегистрироваться'
                })
                return
            }
            axios
                .post('/api/v1/posts/toggle-like', {
                    id: this.id
                })
                .then((res) => {
                    if (res.data?.success) {
                        this.inLiked = !this.inLiked
                        this.likedCount = res.data.liked
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
