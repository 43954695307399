<script setup>
const props = defineProps({
    updates: {
        type: [Array, null]
    }
})
</script>
<template>
    <div class="rounded mt-4">
        <div class="text-gray text-xs leading-tight">
            Следущие изменения ждут одобрения от наших модераторов:
        </div>
        <div class="flex flex-col gap-3 mt-3" v-if="props.updates?.length">
            <div
                v-for="update in props.updates"
                :key="update.id"
                class="flex items-center justify-center"
            >
                {{ update.value }}
            </div>
        </div>
    </div>
</template>

<style>
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.gap-3 {
    gap: 0.75rem;
}
.border {
    border: 1px solid var(--Gray20);
}
.rounded {
    border-radius: 6px;
}
.p-4 {
    padding: 1rem;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-4 {
    margin-top: 1rem;
}
.text-gray {
    color: var(--Gray50);
}
.text-sm {
    font-size: 1.7rem;
}
.text-xs {
    font-size: 1.4rem;
}
.leading-tight {
    line-height: 1.2;
}
</style>
