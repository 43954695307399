<template>
    <div>
        <slot name="button" :show="show" />
        <VueFinalModal
            v-model="isShowing"
            :name="props.name"
            :fit-parent="false"
            :click-to-close="false"
            :esc-to-close="true"
            :content-class="['modal__content centered', props.contentClass]"
        >
            <div class="modal__inner">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__close"
                        data-action="modal-close"
                        @click="close"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__header" v-if="$slots.header">
                    <slot name="header"></slot>
                </div>
                <div class="modal__body">
                    <slot :close="close" />
                </div>
                <div class="form__footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </VueFinalModal>
    </div>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { ref } from 'vue'

const props = defineProps({
    contentClass: {
        default: ''
    },
    name: {
        default: null
    }
})

const isShowing = ref(false)

const show = () => {
    isShowing.value = true
}

const close = () => {
    isShowing.value = false
}
</script>

<style>
.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
