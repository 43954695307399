<script setup>
import ModalEnterCode from './ModalEnterCode.vue'
import { ref } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import TextInput from '../Inputs/TextInput.vue'
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyAX2QFZ-fw1elujeBtf8GzlOyMIlx2wFIU',
    authDomain: 'lapki-club.firebaseapp.com',
    projectId: 'lapki-club',
    storageBucket: 'lapki-club.appspot.com',
    messagingSenderId: '946554576978',
    appId: '1:946554576978:web:1f8c623e3792eb2b05bf8b',
    measurementId: 'G-9YF5ZS6NYZ'
}

const app = initializeApp(firebaseConfig)
const input = ref()
const modalEnterCode = ref()
const loading = ref(false)
const schema = yup.object({
    email: yup
        .string()
        .required('Надо укзать Email')
        .email('Введите Email в правильном формате')
})

const { handleSubmit, values, resetForm, setErrors, setFieldError } = useForm({
    validationSchema: schema
})

const handleShowRegisterForm = () => {
    modal.close()
    resetForm()
    modal.open('#modal-register')
}

const handleBack = () => {
    modal.back()
    resetForm()
}

const auth = getAuth()
const recaptchaVerifier = ref()
const onSubmit = async () => {
    console.log('ddd1')
    recaptchaVerifier.value = new RecaptchaVerifier(
        'sign-in-button',
        {
            size: 'normal',
            callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                console.log('res', response)
                // onSignInSubmit()
            }
        },
        auth
    )
    console.log('recaptchaVerifier.value', recaptchaVerifier.value)
    await onSignInSubmit()
}

const onSignInSubmit = async () => {
    const phoneNumber = '+1 650-555-1234'
    console.log('phone', phoneNumber)
    signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier.value)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // console.log('confirmationResult', confirmationResult)
            // modal.close()
            // resetForm()
            // modal.open('#modal-login-email', () => {
            //     document.getElementById('login_email').value = values.email
            //     modalEnterCode.value.setFocus()
            // })

            const code = '111111'
            confirmationResult
                .confirm(code)
                .then((result) => {
                    // User signed in successfully.
                    const user = result.user
                    console.log('user', user)
                    // ...
                })
                .catch((error) => {
                    // User couldn't sign in (bad verification code?)
                    // ...
                })
        })
        .catch((error) => {
            // Error; SMS not sent
            // ...
            console.log('errr', error)
        })
}

// const onSubmit = handleSubmit(async (values) => {
//     try {
//         loading.value = true
//         const { data } = await axios.post('/api/v1/auth/send-password', values)
//         console.log('data', data)
//         if (data.success) {
//             modal.close()
//             resetForm()
//             modal.open('#modal-login-email', () => {
//                 document.getElementById('login_email').value = values.email
//                 modalEnterCode.value.setFocus()
//             })
//         } else {
//             setFieldError('email', data?.message ?? 'Что-то пошло не так')
//         }
//     } catch (err) {
//         const errors = err.response?.data?.errors
//         if (errors) {
//             setErrors(errors)
//         } else {
//             let error = 'Что-то пошло не так'
//             if (err.response?.status === 429) {
//                 error = 'Слишком много попыток'
//             } else {
//                 error = err.response?.data?.message ?? error
//             }
//             setFieldError('email', error)
//         }
//     } finally {
//         loading.value = false
//     }
// })

const setFocus = () => {
    input.value.setFocus()
}
defineExpose({
    setFocus
})
</script>

<template>
    <teleport to="body">
        <div class="modal" id="modal-send-password">
            <div class="modal__content" data-modal="modal-content">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__back"
                        @click="handleBack"
                    >
                        <i class="icon-back"></i>
                    </button>
                    <button
                        type="button"
                        class="modal__close"
                        @click="handleBack"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__inner">
                    <div class="modal__header">
                        <div class="logo">
                            <img
                                :src="'/img/logo.svg'"
                                alt="Login"
                                class="logo__img"
                            />
                        </div>
                        <div class="modal__headline">Вход при помощи почты</div>
                    </div>
                    <div class="modal__body">
                        <form class="form" @submit.prevent="onSubmit">
                            <TextInput
                                name="email"
                                label="Email"
                                ref="input"
                                :loading="loading"
                            />
                            <div id="sign-in-button"></div>
                            <div class="form__footer">
                                <div class="form__group">
                                    <button
                                        type="submit"
                                        class="button"
                                        :class="{ 'is-wait': loading }"
                                    >
                                        Войти
                                    </button>
                                </div>
                                <div class="form__group">
                                    <button
                                        type="button"
                                        @click="handleShowRegisterForm"
                                        class="form__link"
                                    >
                                        Вы здесь в первый раз?
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </teleport>

    <ModalEnterCode action="/login" ref="modalEnterCode"></ModalEnterCode>
</template>
