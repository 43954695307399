import { defineStore } from 'pinia'
import { computed, ComputedRef, Ref, ref, UnwrapRef } from 'vue'
import axios from 'axios'
import _, { groupBy } from 'lodash'

export const useBusinessStore = defineStore('business', () => {
    const initialValuesState = ref(null)
    const initialValues = computed(() => initialValuesState.value)
    const setInitialValues = (value: any, goToStep = null) => {
        initialValuesState.value = value

        if (!initialValuesState.value.photos) {
            initialValuesState.value.photos =
                initialValuesState.value.media.filter(
                    (item) => item.collection_name === 'gallery'
                )
        }

        if (!goToStep) {
            setStep(initialValues.value?.step + 1)
            return
        }

        setStep(goToStep)
    }

    const stepState: Ref<number> = ref(1)
    const step: ComputedRef<number> = computed(() => stepState.value)
    const setStep = (step: number): void => {
        stepState.value = step
    }
    const prevStep = (): void => {
        if (stepState.value <= 1) {
            return
        }
        stepState.value--
    }
    const nextStep = (): void => {
        if (stepState.value >= steps.value.length + 1) {
            return
        }

        stepState.value++
    }
    const hasPrevStep: ComputedRef<boolean> = computed((): boolean => {
        return stepState.value > 1
    })
    const hasNextStep: ComputedRef<boolean> = computed((): boolean => {
        return stepState.value < steps.value.length + 1
    })
    const stepLabel = computed(() => {
        return steps.value.find((item) => item.id === stepState.value)?.label
    })
    const completedSteps = computed(() => {
        return initialValuesState.value?.completed_steps ?? []
    })

    const steps: Ref<{ id: number; label: string }[]> = ref([
        {
            id: 1,
            label: 'Основная информация'
        },
        {
            id: 2,
            label: 'Контакты'
        },
        {
            id: 3,
            label: 'Режим работы'
        },
        {
            id: 4,
            label: 'Фото'
        },
        {
            id: 5,
            label: 'Виды деятельности'
        },
        {
            id: 6,
            label: 'Список услуг'
        }
    ])

    const loading = ref(false)
    const startLoading = () => (loading.value = true)
    const stopLoading = () => (loading.value = false)

    const serviceTypesState = ref([])
    const serviceTypes = computed(() => serviceTypesState.value)
    const fetchServiceTypes = async () => {
        try {
            const { data } = await axios.get('/api/v1/business/service-types')
            console.log('fetchServiceTypes', data)
            serviceTypesState.value = data.data
        } catch (err) {
            console.log('Error fetch service types')
        }
    }

    const servicesState = ref([])
    const services = computed(() => servicesState.value)
    const servicesGrouped = computed(() => {
        let keys = []
        servicesState.value.map((item) => {
            if (!keys.includes(item.service_type_id)) {
                keys.push(item.service_type_id)
            }
        })
        const groups = groupBy(servicesState.value, 'service_type_id')
        console.log('keys', keys)
        console.log('groups', groups)
        if (!Object.keys(groups).length) {
            return
        }
        const result = []
        keys.forEach((key) => {
            let values = _.orderBy(groups[key], 'order')
            console.log('groups[key]', key)
            if (groups[key]) {
                result.push({
                    group_key: groups[key][0]?.type?.name,
                    group_values: values
                })
            }
        })
        return result
    })
    const fetchServices = async () => {
        try {
            if (
                !initialValues.value?.service_types.map((item) => item.id)
                    .length
            ) {
                return
            }
            const { data } = await axios.get('/api/v1/business/services', {
                params: {
                    service_types: initialValues.value.service_types.map(
                        (item) => item.id
                    )
                }
            })
            servicesState.value = data.data
        } catch (e) {
            console.log('err', e)
            console.log('Error fetch services')
        }
    }

    return {
        initialValues,
        setInitialValues,

        step,
        setStep,
        nextStep,
        prevStep,
        hasPrevStep,
        hasNextStep,
        stepLabel,
        completedSteps,

        steps,

        loading,
        startLoading,
        stopLoading,

        serviceTypes,
        fetchServiceTypes,
        services,
        fetchServices,
        servicesGrouped
    }
})
