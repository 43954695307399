<script setup>
import ChatButton from '@/components/Chat/ChatButton.vue'
import AppNotifications from '@/components/Notifications/AppNotifications.vue'
import ProfileMenu from '@/components/ProfileMenu.vue'
import Auth from './Auth/Auth.vue'
import { ref } from 'vue'

const props = defineProps({
    authUser: {
        required: false
    }
})

const auth = ref()
</script>

<template>
    <div>
        <div class="burger">
            <div class="burger__inner" data-action="burger-scroll">
                <div class="burger__header">
                    <a href="/" class="logo">
                        <img
                            :src="'/img/logo.svg'"
                            alt="lapki.club"
                            class="logo__img"
                        />
                    </a>
                    <button
                        class="burger__close"
                        type="button"
                        data-action="burger-close"
                    >
                        <svg viewBox="0 0 32 32">
                            <path d="M10,10 L22,22 M22,10 L10,22"></path>
                        </svg>
                    </button>
                </div>
                <div class="burger__body">
                    <template v-if="props.authUser">
                        <nav class="navigation">
                            <div class="navigation__item">
                                <a
                                    href="/"
                                    class="navigation__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text">Лента</span>
                                </a>
                            </div>
                            <div class="navigation__item">
                                <a
                                    href="/communities"
                                    class="navigation__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text">Клубы</span>
                                </a>
                            </div>
                            <div class="navigation__item">
                                <a
                                    href="/pets"
                                    class="navigation__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text"
                                        >Питомцы</span
                                    >
                                </a>
                            </div>
                            <div class="navigation__item">
                                <a
                                    href="/users"
                                    class="navigation__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text">Люди</span>
                                </a>
                            </div>
                            <div class="navigation__item">
                                <a
                                    href="/businesses"
                                    class="navigation__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text">Услуги</span>
                                </a>
                            </div>
                            <div class="navigation__item">
                                <a
                                    href="/shop"
                                    class="navigation__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text"
                                        >Барахолка</span
                                    >
                                </a>
                            </div>
                        </nav>
                        <nav class="nav">
                            <div class="nav__item">
                                <a href="/privacy" class="nav__link"
                                    >Конфиденциальность</a
                                >
                            </div>
                            <div class="nav__item">
                                <a href="/rules" class="nav__link @@active"
                                    >Правила использования</a
                                >
                            </div>
                            <div class="nav__item">
                                <a href="/" class="nav__link @@active"
                                    >©2024 LAPKI.CLUB</a
                                >
                            </div>
                        </nav>
                    </template>
                    <div class="burger__nav" v-else>
                        <button
                            class="button button_bg_white button_rounded button_size_sm"
                            type="button"
                            @click="auth.login"
                        >
                            Войти
                        </button>
                        <button
                            type="button"
                            class="button button_size_sm button_rounded"
                            @click="auth.register"
                        >
                            Присоединиться
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <header class="header">
            <div class="container">
                <div class="header__inner">
                    <div class="header__bar">
                        <a href="/" class="logo">
                            <img
                                :src="'/img/logo.svg'"
                                alt=""
                                class="logo__img"
                            />
                            <img
                                :src="'/img/logo-xs.svg'"
                                alt=""
                                class="logo__img-sm"
                            />
                        </a>
                        <button
                            type="button"
                            class="burger-open"
                            data-action="burger-open"
                        >
                            <i class="icon-menu"></i>
                        </button>
                    </div>
                    <template v-if="props.authUser">
                        <nav class="nav">
                            <div class="nav__item">
                                <a
                                    href="/"
                                    class="nav__link"
                                    :class="{ navigation__link_active: false }"
                                    >Лента</a
                                >
                            </div>
                            <div class="nav__item">
                                <a
                                    href="/communities"
                                    class="nav__link"
                                    :class="{ navigation__link_active: false }"
                                    >Клубы</a
                                >
                            </div>
                            <div class="nav__item">
                                <a
                                    href="/pets"
                                    class="nav__link"
                                    :class="{ navigation__link_active: false }"
                                    >Питомцы</a
                                >
                            </div>
                            <div class="nav__item">
                                <a
                                    href="/users"
                                    class="nav__link"
                                    :class="{ navigation__link_active: false }"
                                    >Люди</a
                                >
                            </div>
                            <div class="nav__item">
                                <a
                                    href="/businesses"
                                    class="nav__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text">Услуги</span>
                                </a>
                            </div>
                            <div class="nav__item">
                                <a
                                    href="/shop"
                                    class="nav__link"
                                    :class="{ navigation__link_active: false }"
                                >
                                    <span class="navigation__text"
                                        >Барахолка</span
                                    >
                                </a>
                            </div>
                        </nav>
                        <div class="header__bar">
                            <div class="user-nav">
                                <div class="user-nav__item">
                                    <ChatButton
                                        auth-id="{{ props.authUser?.id }}"
                                    />
                                </div>
                                <div class="user-nav__item">
                                    <AppNotifications
                                        auth-id="{{ props.authUser?.id }}"
                                    />
                                </div>
                            </div>
                            <ProfileMenu :auth="props.authUser" />
                        </div>
                        <form
                            action="#"
                            class="header-search"
                            data-action="search"
                        >
                            <span class="header-search__icon">
                                <i class="icon-search"></i>
                            </span>
                            <input
                                type="text"
                                name="search"
                                class="header-search__input"
                                placeholder="Искать на lapki.club"
                            />
                            <button
                                type="button"
                                class="header-search__close"
                                data-action="search-close"
                            >
                                <i class="icon-close"></i>
                            </button>
                        </form>
                    </template>
                    <div v-else class="header__nav">
                        <button
                            class="button button_bg_white button_rounded button_size_sm"
                            type="button"
                            @click="auth.login"
                        >
                            Войти
                        </button>
                        <button
                            type="button"
                            class="button button_size_sm button_rounded"
                            @click="auth.login"
                        >
                            Присоединиться
                        </button>
                    </div>
                </div>
            </div>
        </header>
        <Auth ref="auth" />
    </div>
</template>
