<template>
    <Layout @nextStep="handleSubmit" @onExit="handleExit">
        <ServiceInput @onAdd="handleAdd" :loading="store.loading" />

        <ServiceInput
            v-for="(service, i) in services"
            :key="service.id?.id"
            :loading="store.loading"
            :model-value="service"
            @onUpdate="handleUpdate($event, i)"
            @onDelete="handleDelete(i)"
            :is-edit="true"
        />
        <div
            class="form__alert form__alert_error"
            v-if="v$.services.$errors[0]?.$message"
        >
            <i class="icon-details"></i> {{ v$.services.$errors[0]?.$message }}
        </div>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { onMounted, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import ServiceInput from '../inputs/ServiceInput.vue'
import { Skeletor } from 'vue-skeletor'

const store = useBusinessStore()

const services = ref(store.initialValues?.services)
const rules = {
    services: {
        requiredLocalized
    }
}

onMounted(() => {
    store.fetchServices()
})

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    {
        services
    },
    {
        $externalResults,
        $scope: false
    }
)

const handleSubmit = async () => {
    v$.value.$clearExternalResults()

    if (!v$.value.$anyDirty && store.completedSteps.includes(6)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }
    store.startLoading()
    try {
        const {
            data: { data }
        } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
            step: 6,
            services: services.value.map((service) => {
                service.description =
                    service.pivot_description ?? service.pivot?.description
                service.price_from =
                    service.price_from ?? service.pivot?.price_from
                service.id = service.id.id ?? service.id
                return service
            })
        })
        store.setInitialValues(data, 7)
    } catch (error) {
        const errors = error.response.data.errors
        console.log('errors', errors)
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}
const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            {
                step: 6,
                services: services.value.map((service) => {
                    service.description =
                        service.pivot_description ?? service.pivot?.description
                    service.price_from =
                        service.price_from ?? service.pivot?.price_from
                    return service
                })
            }
        )
        document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}

const handleAdd = (value) => {
    services.value.push(value)
    v$.value.services.$touch()
}
const handleUpdate = (value, i) => {
    services.value.splice(i, 1, value)
    v$.value.services.$touch()
}
const handleDelete = (i) => {
    services.value.splice(i, 1)
    v$.value.services.$touch()
}
</script>
