<script setup>
import { onMounted, ref } from 'vue'
import { io } from 'socket.io-client'

const props = defineProps({
    authId: {
        type: [String, Number, null]
    }
})

const socket = ref(
    io(`${location.origin}:6001`, {
        cors: {
            origin: [
                'http://localhost',
                'https://lapki.club',
                'https://dev.lapki.club'
            ]
        }
    })
)

const connect = () => {
    socket.value.on('connect', () => {
        console.log(`Connected to socket: ${socket.value.id}`)
        socket.value.emit('user_connected', props.authId)
    })

    socket.value.onAny((event, ...args) => {
        console.log(`got ${event}`)
    })

    // socket.on('App\\Events\\TestEvent', (data) => {
    //     console.log('dd')
    //     console.log('test fired', data)
    // })
}

defineExpose({
    socket
})

onMounted(async () => {
    console.log('Sockets mounted')
    await connect()
})
</script>

<template>
    <slot></slot>
</template>
