<template>
    <Layout @nextStep="handleSubmit" @onExit="handleExit">
        <AvatarInput
            label="avatar"
            v-model="v$.avatar.$model"
            :loading="store.loading"
            :errorMessage="v$.avatar.$errors[0]?.$message"
            image-placeholder="/img/organization-empty-logo.svg"
            @select="handleAvatarSelect"
        />
        <TextInput
            v-model="v$.name.$model"
            label="Название бизнеса / компании"
            :loading="store.loading"
            extra-text="Введите название вашего бизнеса. Название должно быть указано без скобочек, а также формы собственности. Пример корректного названия: Доктор Вет. Не корректным будет указание ООО Доктор Вет, или “Доктор Вет”. "
            :errorMessage="v$.name.$errors[0]?.$message"
        />
        <TextareaInput
            v-model="v$.description.$model"
            label="Расскажите о бизнесе"
            :loading="store.loading"
            :limit="1000"
            extra-text="Чтобы пользователи могли с вами лучше познакомиться расскажите здесь немного о вашем бизнесе, подходе к клиентам, историю создания, преимуществах. Кто вы и как пришли к своему любимому делу. Не указывайте здесь список услуг, вы сможете детально их описать на следующих шагах."
            :errorMessage="v$.description.$errors[0]?.$message"
        />
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import TextInput from '../inputs/TextInput.vue'
import TextareaInput from '../inputs/TextareaInput.vue'
import AvatarInput from '../inputs/AvatarInput.vue'
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { maxLengthLocalized } from '../../../rules/maxLengthLocalized'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { Skeletor } from 'vue-skeletor'
import { helpers } from '@vuelidate/validators'

const store = useBusinessStore()

const avatar = ref(
    store.initialValues?.avatar === '/img/organization-empty-logo.svg'
        ? null
        : store.initialValues?.avatar
)
const name = ref(store.initialValues?.name)
const description = ref(store.initialValues?.description)

const rules = {
    avatar: {
        requiredLocalized: helpers.withMessage(
            'Необходимо загрузить аватар',
            requiredLocalized
        )
    },
    name: {
        requiredLocalized,
        minLength: maxLengthLocalized(555)
    },
    description: {
        requiredLocalized,
        maxLengthLocalized: maxLengthLocalized(100000)
    }
}

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    { name, description, avatar },
    { $externalResults }
)

const avatarFile = ref(null)
const handleAvatarSelect = (file) => {
    avatarFile.value = file
}

const handleSubmit = async () => {
    // if (!v$.value.$anyDirty && store.completedSteps.includes(1)) {
    //     store.nextStep()
    //     return
    // }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }
    store.startLoading()
    const formData = new FormData()
    formData.append('step', '1')
    if (avatarFile.value) {
        formData.append('avatar', avatarFile.value)
    }
    formData.append('name', name.value)
    formData.append('description', description.value)
    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/${store.initialValues.id}`,
            formData
        )
        store.setInitialValues(data, 2)
    } catch (error) {
        const errors = error.response.data.errors
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}

const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    const formData = new FormData()
    formData.append('step', '1')
    if (avatarFile.value) {
        formData.append('avatar', avatarFile.value)
    }
    formData.append('name', name.value)
    formData.append('description', description.value)
    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            formData
        )
        document.location.href = '/profile'
        return
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
        return
    }
}
</script>
