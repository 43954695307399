<template>
    <VueFinalModal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal__content"
                   :click-to-close="false"
                   :esc-to-close="true">
        <div class="modal__bar">
            <button type="button" class="modal__close" @click="close">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal__inner">
            <div class="modal__header" style="margin-bottom: 0;">
                <div class="modal-alert modal-alert_error">
                    <div class="modal-alert__headline">
                        Вы уверены что хотите удалить достижение?
                    </div>
                    <div class="modal-alert__controls">
                        <button type="button" class="button button_size_sm button_bg_gray"
                                @click="close">
                            Нет
                        </button>
                        <button type="button" class="button button_size_sm button_bg_error"
                        @click="emit('submit')">
                            Да, удалить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>

<script setup>
import {VueFinalModal} from 'vue-final-modal'

const emit = defineEmits(['submit'])
</script>

<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.block {
    display: block;
}
</style>
