<template>
    <Layout section="service_types">
        <div class="constructor__content">
            <div class="constructor__header">
                <div class="constructor__headline">Виды деятельности</div>
            </div>

            <MultiselectInput
                :options="store.serviceTypes"
                :model-value="v$.service_types.$model"
                @update:model-value="handleUpdate"
                :checkboxes="true"
                :close-on-select="false"
                :searchable="false"
                :loading="store.loading"
                label="Типы услуг"
                extra-text="Выберите один или несколько основных видов деятельности. Далее вы сможете детализировать список услуг, их описание и стоимость."
                :errorMessage="v$.service_types.$errors[0]?.$message"
            />

            <FormFooter
                :loading="store.loading"
                @onSubmit="handleSubmit"
                @onCancel="handleReset"
            />
        </div>
    </Layout>
</template>

<script setup>
import { onMounted, provide, ref } from 'vue'
import FormFooter from './FormFooter.vue'
import Layout from './Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import MultiselectInput from '../inputs/MultiselectInput.vue'

const store = useBusinessStore()

const props = defineProps({
    authUser: {
        required: true
    },
    business: {
        required: true
    }
})

const service_types = ref(
    props.business?.service_types ??
        store.initialValues?.service_types?.map((item) => item.id)
)

onMounted(() => {
    store.setInitialValues(props.business)
})

const rules = {
    service_types: {
        requiredLocalized
    }
}
const $externalResults = ref({})
const v$ = useVuelidate(rules, { service_types }, { $externalResults })

const handleUpdate = (value) => {
    if (service_types.value.includes(value)) {
        service_types.value.splice(
            service_types.value.findIndex((item) => item === value),
            1
        )
    } else {
        service_types.value.push(value)
    }
    v$.value.service_types.$touch()
}

onMounted(() => {
    store.fetchServiceTypes()
})
const handleSubmit = async () => {
    v$.value.$clearExternalResults()
    if (!v$.value.$anyDirty && store.completedSteps.includes(5)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }
    store.startLoading()
    try {
        const {
            data: { data }
        } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
            step: 5,
            service_types: service_types.value.map((item) => item?.id ?? item)
        })
        store.setInitialValues(data, 6)
    } catch (error) {
        const errors = error.response.data.errors
        console.log('errors', errors)
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}
const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = "/profile'"
        return
    }

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            {
                step: 5,
                service_types: service_types.value
            }
        )
        document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}

const handleReset = async () => {
    service_types.value =
        props.business?.service_types ?? store.initialValues?.service_types
    v$.value.$reset()
}
</script>
