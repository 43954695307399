<template>
    <div class="form__group">
        <div class="form-control" v-if="!loading">
            <IMaskComponent
                ref="input"
                :value="modelValue"
                :mask="mask"
                :class="{
                    'is-valued': !!modelValue,
                    error: props.errorMessage
                }"
                @accept:masked="onAcceptUnmasked"
                class="form-control__control"
            />
            <div class="form-control__label">{{ props.label }}</div>
            <div
                class="form__alert form__alert_error"
                v-if="props.errorMessage"
            >
                <i class="icon-details"></i> {{ props.errorMessage }}
            </div>
            <div
                class="form__extra-text"
                v-if="extraText"
                v-html="extraText"
            ></div>
        </div>
        <Skeletor v-else class="rounded" height="60" as="div"></Skeletor>
    </div>
</template>

<script setup>
import { Skeletor } from 'vue-skeletor'
import { ref, toRef } from 'vue'
import { IMaskComponent } from 'vue-imask'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String
    },
    icon: {
        type: String,
        required: false
    },
    loading: {
        required: false
    },
    extraText: {
        type: String
    },
    modelValue: {
        type: String
    },
    errorMessage: {
        type: String
    }
})

const loading = toRef(props, 'loading')

const input = ref()

const mask = ref([
    {
        mask: '+7 000 000 00-00',
        startsWith: '8',
        lazy: true,
        country: 'RU'
    },
    {
        mask: '+0 000 000 00-00',
        startsWith: '7',
        lazy: true,
        country: 'RU'
    },
    {
        mask: '+000 000 000-00-00',
        startsWith: '375',
        lazy: true,
        country: 'BY'
    },
    {
        mask: '+000 000 000-00-00',
        startsWith: '380',
        lazy: true,
        country: 'UA'
    },
    {
        mask: '+0 000 000-00-00',
        startsWith: '1',
        lazy: true,
        country: 'US'
    },
    // {
    // 	mask: '+00 000[00] 000-00-00',
    // 	startsWith: '49',
    // 	lazy: true,
    // 	country: 'DE'
    // },
    {
        mask: '+0 000 000-00-00',
        startsWith: '',
        country: 'unknown'
    }
])

const counter = ref(0)
const onAcceptUnmasked = (val) => {
    if (counter.value !== 0) {
        emit('update:modelValue', val)
    }
    counter.value++
}

const setFocus = () => {
    input.value.focus()
}
defineExpose({
    setFocus
})
</script>

<style scoped>
.rounded {
    border-radius: 6px;
}
</style>


<!--<template>-->
<!--    <div class="form__group">-->
<!--        <div class="form-control" v-if="!loading">-->

<!--            <MazPhoneNumberInput-->
<!--                ref="input"-->
<!--                v-model="phoneNumber"-->
<!--                show-code-on-list-->
<!--                :default-country-code="props.defaultCountryCode"-->
<!--                @update="onUpdate"-->
<!--                :translations="translations"-->
<!--                :success="results?.isValid"-->
<!--            />-->

<!--            <div class="form-control__label">{{ props.label }}</div>-->
<!--            <div-->
<!--                class="form__alert form__alert_error"-->
<!--                v-if="props.errorMessage"-->
<!--            >-->
<!--                <i class="icon-details"></i> {{ props.errorMessage }}-->
<!--            </div>-->
<!--            <div-->
<!--                class="form__extra-text"-->
<!--                v-if="extraText"-->
<!--                v-html="extraText"-->
<!--            ></div>-->
<!--        </div>-->
<!--        <Skeletor v-else class="rounded" height="60" as="div"></Skeletor>-->
<!--    </div>-->
<!--</template>-->

<!--<script setup>-->
<!--import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'-->
<!--import 'maz-ui/css/main.css'-->
<!--import {Skeletor} from 'vue-skeletor'-->
<!--import {ref, toRef} from 'vue'-->

<!--const emit = defineEmits(['update:modelValue'])-->

<!--const props = defineProps({-->
<!--    label: {-->
<!--        type: String,-->
<!--        required: true-->
<!--    },-->
<!--    name: {-->
<!--        type: String-->
<!--    },-->
<!--    icon: {-->
<!--        type: String,-->
<!--        required: false-->
<!--    },-->
<!--    loading: {-->
<!--        required: false-->
<!--    },-->
<!--    extraText: {-->
<!--        type: String-->
<!--    },-->
<!--    modelValue: {-->
<!--        type: String-->
<!--    },-->
<!--    errorMessage: {-->
<!--        type: String-->
<!--    },-->
<!--    defaultCountryCode: {-->
<!--        type: String-->
<!--    }-->
<!--})-->

<!--const loading = toRef(props, 'loading')-->

<!--const input = ref()-->

<!--const mask = ref([-->
<!--    {-->
<!--        mask: '+7 000 000 00-00',-->
<!--        startsWith: '8',-->
<!--        lazy: true,-->
<!--        country: 'RU'-->
<!--    },-->
<!--    {-->
<!--        mask: '+0 000 000 00-00',-->
<!--        startsWith: '7',-->
<!--        lazy: true,-->
<!--        country: 'RU'-->
<!--    },-->
<!--    {-->
<!--        mask: '+000 000 000-00-00',-->
<!--        startsWith: '375',-->
<!--        lazy: true,-->
<!--        country: 'BY'-->
<!--    },-->
<!--    {-->
<!--        mask: '+000 000 000-00-00',-->
<!--        startsWith: '380',-->
<!--        lazy: true,-->
<!--        country: 'UA'-->
<!--    },-->
<!--    {-->
<!--        mask: '+0 000 000-00-00',-->
<!--        startsWith: '1',-->
<!--        lazy: true,-->
<!--        country: 'US'-->
<!--    },-->
<!--    // {-->
<!--    // 	mask: '+00 000[00] 000-00-00',-->
<!--    // 	startsWith: '49',-->
<!--    // 	lazy: true,-->
<!--    // 	country: 'DE'-->
<!--    // },-->
<!--    {-->
<!--        mask: '+0 000 000-00-00',-->
<!--        startsWith: '',-->
<!--        country: 'unknown'-->
<!--    }-->
<!--])-->

<!--const counter = ref(0)-->
<!--const onAcceptUnmasked = (val) => {-->
<!--    if (counter.value !== 0) {-->
<!--        emit('update:modelValue', val)-->
<!--    }-->
<!--    counter.value++-->
<!--}-->

<!--const setFocus = () => {-->
<!--    input.value.focus()-->
<!--}-->
<!--defineExpose({-->
<!--    setFocus-->
<!--})-->

<!--const phoneNumber = ref()-->
<!--const results = ref()-->

<!--const translations = ref({-->
<!--    countrySelector: {-->
<!--        placeholder: 'Код',-->
<!--        error: 'Выберите страну',-->
<!--        searchPlaceholder: 'Поиск страны',-->
<!--    },-->
<!--    phoneInput: {-->
<!--        placeholder: 'Телефон',-->
<!--        example: 'Например:',-->
<!--    },-->
<!--})-->

<!--const onUpdate  = (data) => {-->
<!--    console.log('data', data)-->
<!--    if (data.isValid) {-->
<!--        // Workaround for this issue: https://github.com/LouisMazel/maz-ui/issues/112-->
<!--        if (input.asYouTypeNumber !== data.formatInternational) {-->
<!--            input.asYouTypeNumber = data.formatInternational-->
<!--        }-->
<!--    }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.rounded {-->
<!--    border-radius: 6px;-->
<!--}-->
<!--</style>-->

