import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'

export const useChatStore = defineStore('chat', () => {
    const roomsState = ref([])
    const lastMessagesState = ref([])

    const roomTypeState = ref('user')
    const roomType = computed(() => roomTypeState.value)
    const setRoomType = async (type: string): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
            roomTypeState.value = type
            console.log('set room type', roomTypeState.value)
            await fetchRooms(type).then(() => {
                resolve(type)
            })
        })
    }

    const fetchRooms = async (type: string = 'user') => {
        return new Promise(async (resolve, reject) => {
            axios
                .get('/api/v1/chat/rooms', {
                    params: {
                        type
                    }
                })
                .then(async (res) => {
                    setRooms(res.data?.data)
                    resolve(roomsState.value)
                })
        })
    }

    const ordersState = ref([])
    const orders = computed(() => ordersState.value)
    const fetchOrders = async (businessId: any) => {
        return new Promise(async (resolve, reject) => {
            axios.get(`/api/v1/business/${businessId}/orders`).then((res) => {
                console.log('res from orders', res)
                ordersState.value = res.data?.data
                roomsState.value = res.data?.data
                resolve(ordersState.value)
            })
        })
    }

    const fetchLastMessages = async (type: string = 'user') => {
        return new Promise(async (resolve, reject) => {
            axios
                .get('/api/v1/chat/last-messages', {
                    params: {
                        type
                    }
                })
                .then((res) => {
                    lastMessagesState.value = res.data?.data
                    fetchRooms(roomType.value)
                    fetchMessages()
                    resolve(lastMessagesState.value)
                })
        })
    }

    const rooms = computed(() => roomsState.value)
    const lastMessages = computed(() => lastMessagesState.value)

    const hasUnreadMessagesState = ref(false)
    const setHasUnreadMessagesState = (value: boolean = true) => {
        hasUnreadMessagesState.value = value
    }

    const hasUnreadMessages = computed(() => hasUnreadMessagesState.value)
    const fetchHasNewMessages = async () => {
        return new Promise(async (resolve, reject) => {
            axios.get('/api/v1/chat/has-new-messages').then((res) => {
                setHasUnreadMessagesState(res.data?.data)
                resolve(hasUnreadMessagesState.value)
            })
        })
    }

    const createRoom = async (recipientId: string | number, type: string) => {
        console.log('create room')
        return new Promise(async (resolve, reject) => {
            axios
                .post('/api/v1/chat/rooms', {
                    recipient_id: recipientId,
                    type
                })
                .then((res) => {
                    const room = res?.data.data ?? null
                    if (room) {
                        rooms.value.unshift(room)
                        // fetchRooms(type)
                        resolve(room)
                    } else {
                        reject(new Error('No room in response'))
                    }
                })
        })
    }

    const pageState = ref(1)
    const page = computed(() => pageState.value)
    const messagesState = ref([])
    const fetchMessages = async () => {
        console.log('Fetch messages in store')
        if (activeRoomId.value) {
            return new Promise(async (resolve, reject) => {
                axios
                    .get(`/api/v1/chat/${activeRoomId.value}/messages`, {
                        params: {
                            page: pageState.value
                        }
                    })
                    .then((res) => {
                        messagesState.value.push(...res.data?.data)
                        pageState.value++
                        resolve(res.data)
                    })
            })
        }
    }
    const messages = computed(() => messagesState.value)

    const activeRoomState = ref(null)
    const activeRoom = computed(() => activeRoomState.value)
    const setActiveRoomId = async (chatRoomId: string | number = null) => {
        const roomById = roomsState.value.find((room) => room.id === chatRoomId)
        if (activeRoomId?.value === roomById?.id) {
            return
        }
        activeRoomState.value = roomById
        messagesState.value = []
        pageState.value = 1
        // await fetchMessages()
    }
    const activeRoomId = computed(() => activeRoomState.value?.id ?? null)

    const sendMessage = async (message: string) => {
        return new Promise((resolve, reject) => {
            const type = activeRoomState.value?.recipient_type
            axios
                .post('/api/v1/chat/messages', {
                    chat_room_id: activeRoomId.value,
                    message,
                    business_id:
                        type === 'business'
                            ? activeRoomState.value.recipient_id
                            : null
                })
                .then((res) => {
                    const message = res.data?.data ?? null
                    if (message) {
                        unshiftMessage(message)
                        // this.messages.push(message)
                        // this.newMessage = null
                        // this.$refs.textarea.style.height = 'auto'
                        // this.$emit('newMessageEmmit')
                        fetchRooms(type === 'business' ? 'business' : null)
                        fetchLastMessages(
                            type === 'business' ? 'business' : null
                        )
                        resolve(message)
                    }
                })
            // .catch((err) => {
            //     notify({
            //         type: 'error',
            //         title: 'Ошибка',
            //         text:
            //             err.response?.data?.message ??
            //             'Ошибка при отправке соообщения'
            //     })
            // })
            //     .finally(() => {
            //         this.loading = false
            //         this.scrollToBottom()
            //     })
        })
    }

    const readMessage = async (id) => {
        await axios
            .post('/api/v1/chat/read-messages', {
                ids: [id]
            })
            .then(async (res) => {
                messagesState.value = messagesState.value.map((item) => {
                    if (item.id === id) {
                        item.read_at = res.data?.data?.read_at
                    }
                    return item
                })
                await fetchRooms(roomType.value)
            })
    }

    const setRooms = (rooms = []) => {
        roomsState.value = [...rooms]
    }

    const unshiftMessage = (message: object) => {
        messagesState.value.unshift(message)
    }

    return {
        roomsState,
        rooms,
        fetchRooms,
        lastMessages,
        fetchLastMessages,

        setHasUnreadMessagesState,
        hasUnreadMessages,
        fetchHasNewMessages,

        createRoom,
        fetchMessages,
        messages,

        setActiveRoomId,
        activeRoom,
        activeRoomId,

        sendMessage,
        page,

        roomType,
        setRoomType,

        readMessage,

        orders,
        fetchOrders,
        setRooms,
        unshiftMessage
    }
})
