<template>
    <div class="form__group">
        <div class="form-control">
            <CityInput
                v-model="value"
                placeholder=""
                @update:modelValue="handleInput"
                clearable
            />
            <div class="form-control__label">
                {{ value ? null : props.label }}
            </div>
            <div
                class="form__alert form__alert_error"
                v-if="errorMessage && meta.touched"
            >
                <i class="icon-details"></i> {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate'
import { onMounted, ref } from 'vue'
import CityInput from '../Search/CityInput.vue'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: true
    },
    authUser: {
        required: false
    }
})

const emit = defineEmits(['input'])

// const accessToken = 'pk.eyJ1IjoiZGZvbWVua28iLCJhIjoiY2xkdWFsdXdtMDN3ajNybzVqMmF4Nm9zMiJ9.DbY1xhEmRAWhikud_1n-vg'
const { errorMessage, meta, value } = useField(props.name)

const variants = ref([])
//
// const handleSearch = _.debounce((value) => {
//     let query = encodeURIComponent(value)
//     if (!query) {
//         return
//     }
//     axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${accessToken}&language=ru&types=place`)
//         .then(res => {
//             const result = res?.data?.features
//             console.log('result:', result)
//             variants.value = result.map(item => {
//                 const country = item.context.find(ctx => ctx.id.includes('country'))
//                 return {
//                     place_name: item.place_name,
//                     city_name: item.text,
//                     city_id: item.id,
//                     country_name: country?.text,
//                     country_id: country?.id,
//                     center: item.center
//                 }
//             })
//         })
// }, 500)

const handleInput = (val) => {
    console.log('ddd')
    emit('input', val)
}

onMounted(() => {
    if (value.value) {
        variants.value.push(value.value)
    } else if (props.authUser && props.authUser.city?.full_name) {
        variants.value.push(props.authUser.city.full_name)
        value.value = props.authUser.city
    }
})
</script>
