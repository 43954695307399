<template>
    <div class="service-list__sidebar">
        <form class="form" v-if="!props.loading">
            <MapBoxCityInput
                label="Местоположение"
                name="city"
                @input="handleInput"
            />
        </form>
        <Skeletor
            v-else
            width="100%"
            height="60px"
            style="border-radius: 12px"
        />
        <div class="nav-column" v-if="!props.loading">
            <div
                class="nav-column__item"
                v-for="type in service_types"
                :key="type.id"
            >
                <button
                    type="button"
                    class="nav-column__link"
                    :class="{ active: current_service_type === type.id }"
                    @click="handleSelect(type.id)"
                >
                    <span class="nav-column__icon" v-html="type.icon"></span>
                    <span class="nav-column__text">{{ type.name }}</span>
                </button>
            </div>
        </div>
        <div class="nav-column" v-else>
            <Skeletor
                class="nav-column__item nav-column__link"
                v-for="i in 6"
                :key="i"
                height="60px"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import MapBoxCityInput from '../Inputs/MapBoxCityInput.vue'
import { Skeletor } from 'vue-skeletor'

const props = defineProps({
    service_type: {
        type: [String, Number],
        default: null
    },
    loading: {
        type: Boolean
    }
})
const current_service_type = ref(props.service_type)

const service_types = ref(null)

const fetch = async () => {
    try {
        const { data } = await axios.get('/api/v1/business/service-types')
        service_types.value = data?.data ?? []
    } catch (error) {
        console.log('error', error)
    }
}

onMounted(fetch)

const emit = defineEmits(['onSetFilter'])

const handleInput = (val) => {
    console.log('vvv', val)
    emit('onSetFilter', {
        key: 'city_id',
        value: val && val.id ? val.id : null
    })
}

const handleSelect = (id) => {
    if (current_service_type.value === id) {
        current_service_type.value = null
        emit('onSetFilter', {
            key: 'service_type',
            value: null
        })
    } else {
        current_service_type.value = id
        emit('onSetFilter', {
            key: 'service_type',
            value: id
        })
    }
}
</script>

<style>
.nav-column__icon svg {
    fill: currentColor;
}
</style>
