<template>
    <vue-final-modal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="modal-container"
        content-class="modal__content"
        :click-to-close="false"
        :esc-to-close="true"
    >
        <div class="modal__bar">
            <button type="button" class="modal__close" @click="close">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal__inner">
            <div class="modal__header">
                <div class="modal__headline">Редактировать достижение</div>
            </div>
            <div class="modal__body">
                <div class="form pet-achievement-add__form" v-if="!loading">
                    <div class="form__group">
                        <div class="form-control">
                            <input
                                v-model="year"
                                :disabled="!props.isOwner"
                                ref="yearInput"
                                class="form-control__control"
                                :class="{
                                    'is-valued': !!yearImask.masked,
                                    error: v$.year.$invalid && v$.year.$dirty
                                }"
                            />
                            <div class="form-control__label">Год</div>
                            <div
                                v-for="error of v$.year.$errors"
                                :key="error.$uid"
                                class="error"
                            >
                                <template v-if="error.$validator === 'max'">
                                    Дата участия не может быть указана в
                                    будущем, проверьте корректность введенных
                                    данных
                                </template>
                                <template
                                    v-else-if="error.$validator === 'min'"
                                >
                                    Дата участия не может быть раньше года
                                    рождения питомца, проверьте корректность
                                    введенных данных
                                </template>
                                <template v-else>{{ error.$message }}</template>
                            </div>
                        </div>
                    </div>
                    <div class="form__group">
                        <div
                            class="form-control"
                            :class="{
                                error: v$.city.$invalid && v$.city.$dirty
                            }"
                        >
                            <city-input
                                v-model="city"
                                :disabled="!props.isOwner"
                            ></city-input>
                            <div
                                v-for="error of v$.city.$errors"
                                :key="error.$uid"
                                class="error"
                            >
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="form-control">
                            <input
                                type="text"
                                class="form-control__control"
                                data-action="form-control"
                                :disabled="!props.isOwner"
                                autocomplete="off"
                                title="Мероприятие"
                                :class="{
                                    'is-valued': !!event,
                                    error: v$.event.$invalid && v$.event.$dirty
                                }"
                                v-model="event"
                            />
                            <div class="form-control__label">Мероприятие</div>
                            <div
                                v-for="error of v$.event.$errors"
                                :key="error.$uid"
                                class="error"
                            >
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="form-control">
                            <VueMultiselect
                                v-model="achievement"
                                :disabled="!props.isOwner"
                                :allow-empty="false"
                                :custom-label="
                                    (option) =>
                                        places.find(
                                            (item) => item.id === option
                                        ).name
                                "
                                placeholder="Выберите достижение"
                                :show-labels="false"
                                :options="places.map((item) => item.id)"
                            >
                            </VueMultiselect>
                            <!--                            <div v-for="error of v$.city.$errors"-->
                            <!--                                 :key="error.$uid" class="error">{{ error.$message }}-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <template v-if="showCropper && props.isOwner">
                        <Cropper
                            ref="cropper"
                            class="cropper"
                            :src="file"
                            image-restriction="fit-area"
                            @change="handleFileCrop"
                        ></Cropper>
                        <label class="form-photo" style="margin-top: 12px">
                            <span class="form-photo__pic"></span>
                            <span class="form-photo__text">
                                Выбрать другое изображение
                            </span>
                            <input
                                type="file"
                                @change="handleFileChange"
                                class="photo-add__input"
                            />
                        </label>
                    </template>
                    <div class="form__group" v-else>
                        <div class="form-photo__pic" v-if="image">
                            <img :src="image" alt="" />
                        </div>
                        <label class="form-photo" v-if="props.isOwner">
                            <span class="form-photo__text">
                                <span class="form-photo__icon">
                                    <i class="icon-camera"></i>
                                </span>
                                <span v-if="image"> Выбрать другое фото </span>
                                <span v-else>
                                    Загрузить фото документа, подтверждающего
                                    титул
                                </span>
                            </span>
                            <input
                                type="file"
                                @change="handleFileChange"
                                class="photo-add__input"
                                accept="image/*"
                            />
                        </label>
                    </div>
                    <p class="form__extra-text" v-if="props.isOwner">
                        Вы можете загрузить изображение размером до 5МБ в
                        форматах .jpg и .png.
                    </p>
                    <div class="form__footer" v-if="props.isOwner">
                        <div class="form__controls">
                            <button
                                type="button"
                                class="button"
                                :class="{ 'is-wait': loading }"
                                @click="handleSubmit"
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                    <div class="form__footer-sub" v-if="props.isOwner">
                        <DeleteAchievementButton
                            :id="props.id"
                            @onDelete="handleDelete"
                        />
                    </div>
                </div>
                <div v-else>
                    <Skeletor
                        class="form__group block"
                        style="border-radius: 6px"
                        width="100%"
                        height="60px"
                    />
                    <Skeletor
                        class="form__group block"
                        style="border-radius: 6px"
                        width="100%"
                        height="60px"
                    />
                    <Skeletor
                        class="form__group block"
                        style="border-radius: 6px"
                        width="100%"
                        height="60px"
                    />
                    <Skeletor
                        class="form__group block"
                        style="border-radius: 6px"
                        width="100%"
                        height="60px"
                    />
                    <Skeletor pill width="100%" height="16px" />
                    <Skeletor
                        pill
                        width="60%"
                        style="margin-top: 8px"
                        height="16px"
                    />
                    <div class="form__footer">
                        <div class="form__controls">
                            <Skeletor
                                style="border-radius: 6px"
                                width="100%"
                                height="60px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script setup>
import { $vfm, VueFinalModal } from 'vue-final-modal'
import { computed, onMounted, reactive, ref } from 'vue'
import { useIMask } from 'vue-imask'
import CityInput from '../Search/CityInput.vue'
import VueMultiselect from 'vue-multiselect'
import { Skeletor } from 'vue-skeletor'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useVuelidate } from '@vuelidate/core'
import { required, min, max } from '../../utils/i18n-validators'
import { notify } from '@kyvg/vue3-notification'
import DeleteAchievementButton from './DeleteAchievementButton.vue'

const loading = ref(true)
const places = ref([
    {
        id: 0,
        name: 'Участник'
    },
    {
        id: 1,
        name: '1-е место'
    },
    {
        id: 2,
        name: '2-е место'
    },
    {
        id: 3,
        name: '3-е место'
    }
])

const props = defineProps({
    id: {
        required: true
    },
    isOwner: {
        default: false
    }
})

const yearImask = useIMask({
    mask: '0000'
})
const yearInput = yearImask.el

const city = ref(null)
const year = ref(null)
const event = ref(null)
const image = ref(null)
const achievement = ref(null)
const fetchAchievement = async (id = null) => {
    // if (!id) {
    //     achievement.value = null
    // }

    try {
        const { data } = await axios.get(`/api/v1/achievements/${id}`)
        console.log(data.data)
        year.value = data?.data?.year
        city.value = data?.data?.city
        yearImask.typed.value = data?.data?.year
        event.value = data?.data?.event
        image.value = data?.data?.image
        achievement.value = parseInt(data?.data?.achievement)
    } catch (e) {
        console.log('Error', e)
    } finally {
        loading.value = false
    }
}
onMounted(() => {
    fetchAchievement(props.id)
})

const rules = computed(() => ({
    year: {
        required,
        min: min(1999),
        max: max(new Date().getFullYear())
    },
    city: {
        required
    },
    event: {
        required
    },
    achievement: {
        required
    }
}))
const v$ = useVuelidate(rules, { year, city, event, achievement })

const file = ref(null)
const showCropper = ref(false)
const handleFileChange = (object) => {
    let reader = new FileReader()
    reader.onload = (e) => {
        file.value = e.target.result
    }
    reader.readAsDataURL(object.target.files[0])
    showCropper.value = true
}

const cropper = ref(null)
const result = ref(null)
const handleFileCrop = () => {
    const { canvas } = cropper.value.getResult()
    try {
        canvas.toBlob((blob) => {
            result.value = blob
        })
    } catch (e) {
        console.log('canvas.toBlob error')
    }
}

const handleSubmit = async () => {
    console.log('handleSubmit')
    if (loading.value) {
        return
    }
    // v$.value.$clearExternalResults()
    const isFormCorrect = await v$.value.$validate()
    console.log('isFormCorrect', isFormCorrect)
    if (!isFormCorrect) {
        return
    }
    try {
        loading.value = true
        const formData = new FormData()
        formData.append('year', year.value)
        formData.append('city_id', city.value.id)
        formData.append('event', event.value)
        formData.append('achievement', achievement.value)
        if (result.value) {
            formData.append('file', result.value)
        }
        const { data } = await axios.post(
            `/api/v1/achievements/${props.id}`,
            formData
        )
        emit('onUpdate')
        image.value = data?.data?.image
    } catch (err) {
        console.log('err', err)
        console.log('err.response', err.response)
        const errors = err.response?.data?.errors
        // this.vuelidateExternalResults = Object.assign({}, errors)
        notify({
            type: 'error',
            title: errors[0] ?? 'Что-то пошло не так('
        })
    } finally {
        loading.value = false
        showCropper.value = false
    }
}

const emit = defineEmits(['onDelete'])
const handleDelete = () => {
    try {
        axios
            .post(`/api/v1/achievements/${props.id}/destroy`)
            .then((res) => {
                if (res?.data?.success) {
                    emit('onDelete')
                } else {
                    throw new Error('Не удалось удалить достижение')
                }
            })
            .catch((err) => {
                notify({
                    type: 'error',
                    text: err
                })
            })
    } catch (err) {
        console.log('errrr', err)
        notify({
            type: 'error',
            text: err
        })
    }
}
</script>

<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.block {
    display: block;
}
</style>
