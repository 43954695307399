<template>
    <div>
        <slot name="button" :show="showModalDeleteDesiredConfirmation">
        </slot>
        <Teleport to="body">
            <div class="modal" :id="id">
                <div class="modal__content" data-modal="modal-content">
                    <div class="modal__bar">
                        <button type="button" class="modal__close" data-action="modal-close">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal__inner">
                        <div class="modal__header" style="margin-bottom: 0;">
                            <div class="modal-alert modal-alert_error">
                                <div class="modal-alert__headline">
                                    <slot name="text">
                                        Вы уверены что хотите удалить питомца?
                                    </slot>
                                </div>
                                <div class="modal-alert__controls">
                                    <button type="button" class="button button_size_sm button_bg_gray"
                                            data-action="modal-close">
                                        Нет
                                    </button>
                                    <button type="button" class="button button_size_sm button_bg_error"
                                            @click="handleDeleteDesired">
                                        Да, удалить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
export default {
    name: "DeletePet",

    props: {
        id: {
            default: 'modal-pet-delete-confirmation'
        },
        deletingDesiredId: {
            required: true
        },
        redirectUrl: {
            type: String,
            default: ''
        }
    },

    methods: {
        showModalDeleteDesiredConfirmation() {
            modal.open(`#${this.id}`)
        },
        handleDeleteDesired() {
            if (this.deletingDesiredId) {
                axios.post('/api/v1/pets/delete', {
                    id: this.deletingDesiredId
                })
                    .then((res) => {
                        if (res.data?.success) {
                            this.$emit('success', this.deletingDesiredId)
                            modal.close()
                        }
                        if (this.redirectUrl) {
                            document.location.href = this.redirectUrl
                        }
                    })
            }
        }
    }
}
</script>
