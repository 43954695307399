<script setup>
import { computed, ref } from 'vue'
import InfiniteLoader from '../InfiniteLoader.vue'
import PetInList from './PetInList.vue'
import PetSkeletor from './PetSkeletor.vue'
import qs from 'qs'

const pets = ref([])

let page = ref(1)
const load = async (data) => {
    pets.value.push(...data)
    page.value++
}

const url = computed(() => {
    const params = {}
    const url = new URL(document.location.href)
    url.searchParams.forEach((value, key) => {
        params[key] = value
    })
    params['page'] = page.value
    return `/api/v1/pets/?${qs.stringify(params)}`
})
</script>

<template>
    <InfiniteLoader :url="url" @onLoad="load">
        <template #default>
            <div class="search-list search-list_grid">
                <PetInList v-for="pet in pets" :key="pet.id" :pet="pet" />
            </div>
        </template>
        <template #loader>
            <div class="search-list search-list_grid" style="margin-top: 15px">
                <PetSkeletor v-for="i in 10" :key="i" />
            </div>
        </template>
        <template #error> Не удалось обновить список животных :( </template>
        <template #complete>
            <span v-if="pets.length"></span>
            <div v-else class="specialty-item">
                <div class="specialty-item__pic">
                    <img :src="'/img/special-dog2.svg'" alt="" />
                </div>
                <div class="specialty-item__text">
                    По вашему запросу животных не найдено :(
                </div>
            </div>
        </template>
    </InfiniteLoader>
</template>
