<template>
    <Layout @nextStep="handleSubmit" @onExit="handleExit">
        <MultiselectInput
            ref="input"
            :options="store.serviceTypes"
            :model-value="v$.service_types.$model"
            @update:model-value="handleUpdate"
            :checkboxes="true"
            :close-on-select="false"
            :searchable="false"
            :loading="store.loading"
            label="Типы услуг"
            :remove-confirmation="true"
            @remove="handleRemoveClick"
            extra-text="Выберите один или несколько основных видов деятельности. Далее вы сможете детализировать список услуг, их описание и стоимость."
            :errorMessage="v$.service_types.$errors[0]?.$message"
        />
        <VueFinalModal
            :model-value="!!deleting"
            :fit-parent="false"
            :click-to-close="false"
            :esc-to-close="true"
            :content-class="['modal__content centered']"
        >
            <div class="modal__inner">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__close"
                        data-action="modal-close"
                        @click="closeModal"
                    >
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__header">
                    <div class="modal-alert__headline">
                        Вы уверены что хотите удалить услугу?
                    </div>
                </div>
                <div class="modal__body">
                    Удалятся привязанные к данному типу услуги!
                </div>
                <div class="form__footer">
                    <div class="form__controls">
                        <button
                            type="button"
                            @click="closeModal"
                            class="button button_size_sm button_bg_light"
                        >
                            Отменить
                        </button>
                        <button
                            type="button"
                            @click="remove"
                            class="button button_size_sm button_bg_danger"
                        >
                            Удалить
                        </button>
                    </div>
                </div>
            </div>
        </VueFinalModal>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { onMounted, ref } from 'vue'
import MultiselectInput from '../inputs/MultiselectInput.vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { VueFinalModal } from 'vue-final-modal'
import ModalDeleteAchievement from '../../Achievements/ModalDeleteAchievement.vue'

const store = useBusinessStore()

const service_types = ref(
    store.initialValues?.service_types?.map((item) => item.id)
)

const rules = {
    service_types: {
        requiredLocalized
    }
}
const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    {
        service_types: service_types.value
    },
    { $externalResults }
)

const handleUpdate = (value) => {
    if (service_types.value.includes(value)) {
        service_types.value.splice(
            service_types.value.findIndex((item) => item === value),
            1
        )
    } else {
        service_types.value.push(value)
    }
    v$.value.service_types.$touch()
}

onMounted(() => {
    store.fetchServiceTypes()
})
const handleSubmit = async () => {
    v$.value.$clearExternalResults()
    if (!v$.value.$anyDirty && store.completedSteps.includes(5)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }
    store.startLoading()
    try {
        const {
            data: { data }
        } = await axios.post(`/api/v1/business/${store.initialValues.id}`, {
            step: 5,
            service_types: service_types.value
        })
        store.setInitialValues(data, 6)
    } catch (error) {
        const errors = error.response.data.errors
        console.log('errors', errors)
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}
const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = "/profile'"
        return
    }

    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            {
                step: 5,
                service_types: service_types.value
            }
        )
        document.location.href = '/profile'
    } catch (error) {
        const errors = error.response.data.errors
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
    }
}
const deleting = ref(null)
const input = ref()
const handleRemoveClick = (option) => {
    const hasServices = store.initialValues?.services.filter(
        (item) => item.service_type_id === option
    ).length
    if (hasServices) {
        console.log('Show modal for services')
        deleting.value = option
    } else {
        input.value.remove(option)
    }
}
const closeModal = () => {
    deleting.value = null
}
const remove = () => {
    input.value.remove(deleting.value)
    closeModal()
}
</script>
