<template>
    <div class="post__footer">
        <div class="post__nav">
            <div class="post__item">
                <likes :id="post.id"
                       :auth_user_id="authUserId"
                       :count="post.liked_count"
                       :liked="post.liked.filter(item => parseInt(item) === parseInt(authUserId)).length ? 'liked' : 'no'"
                ></likes>
            </div>
            <div class="post__item">
                <a :href="`/posts/${post.id}/#comments`" class="post__button" data-tippy-content="Комментарии">
                    <i class="icon-comment"></i>
                    <span>{{ post.comments_count }}</span>
                </a>
            </div>
        </div>
        <div class="post__nav">
            <div class="post__item">
                <favorites :id="post.id"
                           :auth_user_id="authUserId"
                           :favorited="post.favorited.filter(item => parseInt(item) === parseInt(authUserId)).length ? 'favorited' : 'no'"></favorites>
            </div>
            <div class="post__item" v-if="parseInt(authUserId) === parseInt(post.user_id)">
                <button type="button" class="post__button" data-action="dropdown-toggle">
                    <i class="icon-additional"></i>
                </button>
                <div class="dropdown-drop" data-action="dropdown-drop">
                    <nav class="navigation">
                        <div class="navigation__item">
                            <a :href="`/posts/${post.id}/edit`"
                               class="navigation__link ">
                                            <span class="navigation__icon">
                                                <i class="icon-write_post"></i>
                                            </span>
                                <span class="navigation__text">Редактировать</span>
                            </a>
                            <delete_button_with_modal_confirmation :id="post.id" :url="`/api/v1/posts/destroy`"></delete_button_with_modal_confirmation>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Likes from "@/components/Posts/Likes.vue";
import Favorites from "@/components/Posts/Favorites.vue"
import DeleteButtonWithModalConfirmation from "@/components/DeleteButtonWithModalConfirmation.vue"

export default {
    name: "PostFooter",

    components: {
        Likes,
        Favorites,
        'delete_button_with_modal_confirmation': DeleteButtonWithModalConfirmation,
    },

    props: {
        post: {
            required: true
        },
        authUserId: {
            default: null
        }
    },
}
</script>

