<template>
    <button
        class="oauth__button"
        @click="handleClick(item.id)"
        v-for="item in socials"
        :key="item.id"
    >
        <img :src="item.icon_path" :alt="item.id" />
    </button>
</template>

<script setup>
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithRedirect,
    OAuthProvider,
    getRedirectResult
} from 'firebase/auth'
import { notify } from '@kyvg/vue3-notification'
import { getMessaging, getToken } from 'firebase/messaging'
import { ref } from 'vue'
import qs from 'qs'

const socials = ref([
    {
        id: 'google',
        icon_path: '/img/icons/socials/google.svg'
    },
    // {
    //     id: 'facebook',
    //     icon_path: '/img/icons/socials/facebook.svg'
    // },
    {
        id: 'apple',
        icon_path: '/img/icons/socials/apple.svg'
    }
])

const firebaseConfig = {
    apiKey: 'AIzaSyAX2QFZ-fw1elujeBtf8GzlOyMIlx2wFIU',
    authDomain: 'lapki-club.firebaseapp.com',
    projectId: 'lapki-club',
    storageBucket: 'lapki-club.appspot.com',
    messagingSenderId: '946554576978',
    appId: '1:946554576978:web:1f8c623e3792eb2b05bf8b',
    measurementId: 'G-9YF5ZS6NYZ'
}

const app = initializeApp(firebaseConfig)

const device_token = ref(null)
const getDeviceToken = async () => {
    console.log('Mounted FirebaseCloudMessaging')

    // Initialize Firebase

    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app)

    // Add the public key generated from the console here.
    await getToken(messaging, {
        vapidKey:
            'BJVm-Un0IOuX2YabeR_0ETkT7w4FHpn91duGz2BdogS5i0ceGaFg8gQNh73P1bjiDLY4b3yb_5lXX2zwSx-rU8k'
    })
        .then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log('currentToken:', currentToken)
                device_token.value = currentToken
                // console.log('this.$refs.form', this.$refs.form.device_token.value);
            } else {
                // Show permission request UI
                console.log(
                    'No registration token available. Request permission to generate one.'
                )
                // ...
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // ...
        })
}
const auth = getAuth()

const handleClick = (social) => {
    console.log('social', social)

    let provider = null
    switch (social) {
        case 'facebook':
            provider = new FacebookAuthProvider()
            provider.addScope('email')
            break
        case 'google':
            provider = new GoogleAuthProvider()
            break
        case 'apple':
            provider = new OAuthProvider('apple.com')
            provider.addScope('email')
            provider.addScope('name')
            break
    }
    console.log('FacebookAuthProvider', provider?.getScopes())
    try {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                const user = result.user
                const token = await user.getIdToken()
                console.log('user', result)

                await getDeviceToken()

                console.log('redirect', getRedirectUrl(token))
                window.location.href = getRedirectUrl(token)
            })
            .catch((error) => {
                signInWithRedirect(auth, provider)

                console.log('Error', error)
                notify({
                    type: 'error',
                    text: error.message
                })
            })
    } catch (e) {
        console.log('Errror', e)
    }

    getRedirectResult(auth)
        .then(async function (result) {
            console.log('result', result)
            if (!result) return
            const credential = GoogleAuthProvider.credentialFromResult(result)
            if (credential) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const user = result.user
                const token = await user.getIdToken()
                console.log('user', result)

                await getDeviceToken()

                window.location.href = getRedirectUrl(token)
            }
        })
        .catch(function (error) {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // The email of the user's account used.
            const email = error.email
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential
            if (errorCode === 'auth/account-exists-with-different-credential') {
                alert(
                    'You have already signed up with a different auth provider for that email.'
                )
                // If you are using multiple auth providers on your app you should handle linking
                // the user's accounts here.
            } else {
                console.error(error)
            }
        })
}

const getRedirectUrl = (token) => {
    const url = new URL(document.location.href)
    let invitation = null
    if (url.searchParams.has('utm_campaign')) {
        invitation = url.searchParams.get('utm_campaign')
    }
    const params = {
        device_token: device_token.value,
        invitation
    }
    return `/auth/social/${token}?${qs.stringify(params)}`
}
</script>
