<template>
    <slot name="button" :handle-click="handleClick">
        <button
            type="button"
            class="navigation__link delete_post__button"
            @click="handleClick"
        >
            <span class="navigation__icon">
                <i class="icon-trash"></i>
            </span>
            <span class="navigation__text">Удалить публикацию</span>
        </button>
    </slot>

    <teleport to="body">
        <div class="modal" :id="`modal-post-remove-${id}`">
            <slot
                name="modal-content"
                :handle-submit="handleSubmit"
                :loading="loading"
            >
                <div class="modal__content" data-modal="modal-content">
                    <div class="modal__bar">
                        <button
                            type="button"
                            class="modal__close"
                            data-action="modal-close"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal__inner modal__inner_wide">
                        <div class="modal__header">
                            <div class="modal__headline">
                                Вы действительно хотите удалить пост?
                            </div>
                        </div>
                        <div class="modal__body">
                            <div class="form">
                                <div class="form__footer">
                                    <div class="form__controls">
                                        <button
                                            type="button"
                                            class="button button_bg_gray"
                                            data-action="modal-close"
                                        >
                                            Отменить
                                        </button>
                                        <button
                                            type="button"
                                            class="button"
                                            :class="{ 'is-wait': loading }"
                                            @click="handleSubmit"
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </teleport>
</template>

<script>
export default {
    name: 'DeleteButtonWithModalConfirmation',

    props: {
        id: {
            required: true
        },
        method: {
            default: 'post'
        },
        url: {
            required: true
        },
        reload: {
            default: true
        },
        successTitle: {
            type: String,
            default: 'Пост успешно удален'
        }
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        handleClick() {
            modal.open(`#modal-post-remove-${this.id}`)
        },

        handleSubmit() {
            if (this.loading) {
                return
            }
            this.loading = true
            axios[this.method](this.url, {
                id: this.id
            })
                .then((res) => {
                    if (res.data?.success) {
                        this.$notify({
                            type: 'success',
                            title: this.successTitle
                        })
                        if (res.data?.redirect_to) {
                            document.location.href = res.data?.redirect_to
                            return
                        }
                        if (this.reload) {
                            document.location.reload()
                        }
                    } else {
                        this.$notify({
                            type: 'error',
                            text: res.response?.data?.error
                        })
                    }
                })
                .catch((err) => {
                    this.$notify({
                        type: 'error',
                        text: err.response?.error
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
