<template>
    <div class="form__group">
        <div class="form-control" v-if="!loading">
            <div class="svg" v-if="props.icon" v-html="props.icon"></div>
            <slot name="icon" />
            <slot>
                <input
                    :name="props.name"
                    type="text"
                    ref="input"
                    class="form-control__control"
                    :class="{
                        'is-valued': !!value,
                        error: errorMessage && meta.touched
                    }"
                    @input="emit('onInput', $event)"
                    v-model="value"
                    data-action="form-control"
                    autocomplete="off"
                    :title="props.label"
                />
            </slot>
            <div class="form-control__label">{{ props.label }}</div>
            <div
                class="form__alert form__alert_error"
                v-if="errorMessage && meta.touched"
            >
                <i class="icon-details"></i> {{ errorMessage }}
            </div>
            <div
                class="form__extra-text"
                v-if="extraText"
                v-html="extraText"
            ></div>
        </div>
        <Skeletor v-else class="rounded" height="60" as="div"></Skeletor>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate'
import { Skeletor } from 'vue-skeletor'
import { ref, toRef } from 'vue'

const emit = defineEmits(['onInput'])

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: false
    },
    loading: {
        required: false
    },
    extraText: {
        type: String
    }
})

const { errorMessage, meta, value } = useField(props.name)
const loading = toRef(props, 'loading')

const input = ref()

const setFocus = () => {
    input.value.focus()
}
defineExpose({
    setFocus
})
</script>

<style scoped>
.rounded {
    border-radius: 6px;
}
</style>
