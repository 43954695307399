import axios from 'axios'

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['Accept'] = 'application/json'

const token = document.getElementById('token')?.value
// let token = localStorage.getItem('token')
// if (!token) {
//     token = document.getElementById('token')?.value
// }
window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

// Add a response interceptor
window.axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    function (error) {
        console.log('Error handler in interceptor', error)
        const status = error.response?.status
        if (status === 401) {
            document.location.href = '/auth/logout-redirect'
        }
        // if (status === 419) {
        //     document.location.href = '/auth/logout-redirect'
        // }
        // if (status) {
        //     console.log('Status: ', status)
        // } else {
        //     console.log('Status unknown')
        // }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)

import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()
