<template>
    <Layout section="about">
        <div class="constructor__content">
            <div class="constructor__header">
                <div class="constructor__headline">Основная информация</div>
            </div>
            <AvatarInput
                label="avatar"
                v-model="v$.avatar.$model"
                :loading="store.loading"
                :errorMessage="v$.avatar.$errors[0]?.$message"
                image-placeholder="/img/organization-empty-logo.svg"
                @select="handleAvatarSelect"
            />
            <TextInput
                v-model="v$.name.$model"
                label="Название бизнеса / компании"
                :loading="store.loading"
                extra-text="Введите название вашего бизнеса. Название должно быть указано без скобочек, а также формы собственности. "
                :errorMessage="v$.name.$errors[0]?.$message"
            >
                <template #before-label>
                    <UpdatesList
                        :updates="
                            props.business.pending_updates?.filter(
                                (item) => item.key === 'name'
                            )
                        "
                    />
                </template>
            </TextInput>

            <TextareaInput
                v-model="v$.description.$model"
                label="Расскажите о бизнесе"
                :loading="store.loading"
                extra-text="Чтобы пользователи могли с вами лучше познакомиться расскажите здесь немного о вашем бизнесе, подходе к клиентам, историю создания, преимуществах. Кто вы и как пришли к своему любимому делу. Не указывайте здесь список услуг, вы сможете детально их описать на следующих шагах. "
                :errorMessage="v$.description.$errors[0]?.$message"
            >
                <template #before-label>
                    <UpdatesList
                        :updates="
                            props.business.pending_updates?.filter(
                                (item) => item.key === 'description'
                            )
                        "
                    />
                </template>
            </TextareaInput>
            <FormFooter
                :loading="store.loading"
                @onSubmit="handleSubmit"
                @onCancel="handleReset"
            />
        </div>
    </Layout>
</template>

<script setup lang="ts">
import AvatarInput from '../inputs/AvatarInput.vue'
import TextInput from '../inputs/TextInput.vue'
import TextareaInput from '../inputs/TextareaInput.vue'
import { helpers } from '@vuelidate/validators'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { maxLengthLocalized } from '../../../rules/maxLengthLocalized'
import { onMounted, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useBusinessStore } from '../../../stores/BusinessStore'
import Layout from './Layout.vue'
import FormFooter from './FormFooter.vue'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import UpdatesList from './UpdatesList.vue'

const store = useBusinessStore()

const props = defineProps({
    authUser: {
        required: true
    },
    business: {
        required: true
    }
})

const avatar = ref(props.business?.avatar)
const name = ref(props.business?.name ?? '')
const description = ref(props.business?.description ?? '')

onMounted(() => {
    store.setInitialValues(props.business)
})

const rules = {
    avatar: {
        requiredLocalized: helpers.withMessage(
            'Необходимо загрузить аватар',
            requiredLocalized
        )
    },
    name: {
        requiredLocalized,
        minLength: maxLengthLocalized(255)
    },
    description: {
        requiredLocalized,
        maxLengthLocalized: maxLengthLocalized(1000)
    }
}

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    { name, description, avatar },
    { $externalResults }
)

const avatarFile = ref(null)
const handleAvatarSelect = (file) => {
    avatarFile.value = file
}

const handleSubmit = async () => {
    if (!v$.value.$anyDirty && store.completedSteps.includes(1)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }
    store.startLoading()
    const formData = new FormData()
    formData.append('step', '1')
    if (avatarFile.value) {
        formData.append('avatar', avatarFile.value)
    }
    formData.append('name', name.value)
    formData.append('description', description.value)
    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/${store.initialValues.id}`,
            formData
        )

        // initialValues.value = data.data
        notify({
            type: 'success',
            text: 'Бизнес успешно обновлен'
        })
        store.setInitialValues(data, 2)
    } catch (error) {
        console.log('err', error)
        const errors = error.response?.data?.errors
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}

const handleReset = async () => {
    avatar.value = props.business?.avatar ?? null
    name.value = props.business?.name ?? ''
    description.value = props.business?.description ?? ''
    v$.value.$reset()
}
</script>
