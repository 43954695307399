<template></template>

<script>
export default {
    name: 'LandingScripts',

    mounted() {
        plugins.initAll()
    }
}
</script>
