<script setup lang="ts">
import axios from 'axios'
import { nextTick, onMounted, reactive, ref } from 'vue'
import Socials from './Socials.vue'
import { Dialog } from '@headlessui/vue'
import TextInput from '../Inputs/TextInput.vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import CityInput from '../../components/Search/CityInput.vue'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

const loading = ref(false)
const isOpen = ref(false)
const activeStep = ref('')
const error = ref(null)

const firebaseConfig = {
    apiKey: 'AIzaSyAX2QFZ-fw1elujeBtf8GzlOyMIlx2wFIU',
    authDomain: 'lapki-club.firebaseapp.com',
    projectId: 'lapki-club',
    storageBucket: 'lapki-club.appspot.com',
    messagingSenderId: '946554576978',
    appId: '1:946554576978:web:1f8c623e3792eb2b05bf8b',
    measurementId: 'G-9YF5ZS6NYZ'
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

const schema = yup.object({
    input: yup.string().required('Обязательное поле')
})

const recaptchaVerifier = ref()

const form = useForm({
    validationSchema: schema
})

const auth = ref()
const email = ref()
const invitation = ref()
const surname = ref()
const name = ref()

const utm_source = ref()
const utm_medium = ref()
const utm_campaign = ref()

const setAuth = async () => {
    try {
        auth.value = getAuth()
        recaptchaVerifier.value = new RecaptchaVerifier(
            'captcha',
            {
                size: 'invisible'
            },
            auth.value
        )
    } catch (err) {
        console.log('err', err)
    }
}

const initQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    invitation.value = urlParams.get('utm_campaign') ?? null

    utm_source.value = urlParams.get('utm_source') ?? null
    utm_medium.value = urlParams.get('utm_medium') ?? null
    utm_campaign.value = urlParams.get('utm_campaign') ?? null

    email.value = urlParams.get('email') ?? null
}

onMounted(() => {
    initQuery()
    if (email.value) {
        form.values.input = email.value
        login()
    }
})
const login = async () => {
    isOpen.value = true
    activeStep.value = 'login'
    await setAuth()
}
const close = () => {
    isOpen.value = false
    activeStep.value = ''
    otp.value = ''
}
const phoneConfirmationResult = ref()
const phone = ref()
const onSubmit = async () => {
    loading.value = true
    error.value = null

    let inp = ''
    if (loginType.value === 'email') {
        inp = email.value
    } else {
        inp = phone.value
    }
    if (loginType.value === 'email') {
        try {
            const res = await axios.post('/auth/email/send-password', {
                input: inp
            })

            if (res.data.success) {
                activeStep.value = 'enterEmailCode'
                prevStep.value = 'login'
                await nextTick(() => {
                    codeInput?.value?.setFocus()
                })
            } else {
                error.value = res.data?.message ?? 'Что-то пошло не так'
            }
        } catch (e) {
            console.log('e', e)
            error.value = e?.response?.data?.message ?? 'Некорректный email'
        }
    } else {
        const phoneNumber = inp
        console.log('phone', phoneNumber)
        console.log(' recaptchaVerifier.value', recaptchaVerifier.value)
        setAuth()
        await signInWithPhoneNumber(
            auth.value,
            phoneNumber,
            recaptchaVerifier.value
        )
            .then((confirmationResult) => {
                phoneConfirmationResult.value = confirmationResult
                activeStep.value = 'enterCode'
                prevStep.value = 'login'
            })
            .catch((e) => {
                console.log('err while phone submit: ', e)
                console.log('err response: ', e?.response)
                error.value =
                    e?.code === 'auth/invalid-phone-number'
                        ? 'Введите корректный номер'
                        : 'Возникла ошибка'
            })
    }
    loading.value = false
}

const codeInput = ref()
const otp = ref()
const deviceToken = ref()
const city = ref()
const handlePasswordInput = (event) => {
    otp.value = event.target.value
}

const confirmCode = () => {
    loading.value = true
    error.value = null
    activeStep.value = 'enterCode'
    const code = otp.value
    phoneConfirmationResult.value
        .confirm(code)
        .then(async (result) => {
            // User signed in successfully.
            const user = result.user
            console.log('user', user)
            document.location.href = `/auth/social/${user.accessToken}`
        })
        .catch((e) => {
            console.log('error', e)
            error.value = 'Этот код не подходит'
        })
        .finally(() => {
            loading.value = false
        })
}

const getDeviceToken = async () => {
    console.log('Mounted FirebaseCloudMessaging')

    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app)
    // Add the public key generated from the console here.
    await getToken(messaging, {
        vapidKey:
            'BJVm-Un0IOuX2YabeR_0ETkT7w4FHpn91duGz2BdogS5i0ceGaFg8gQNh73P1bjiDLY4b3yb_5lXX2zwSx-rU8k'
    })
        .then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log('currentToken:', currentToken)
                deviceToken.value = currentToken
            } else {
                // Show permission request UI
                console.log(
                    'No registration token available. Request permission to generate one.'
                )
                // ...
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // ...
        })
}

const register = async () => {
    isOpen.value = true
    activeStep.value = 'register'
    await setAuth()
}

const loginType = ref('email')

const handleSurnameInput = (event) => {
    surname.value = event.target.value
}
const handleNameInput = (event) => {
    name.value = event.target.value
}
const handleInvitationInput = (event) => {
    invitation.value = event.target.value
}

import { MaskaDetail, vMaska } from 'maska'

const boundObject: MaskaDetail = reactive({
    masked: '',
    unmasked: '',
    completed: false
})

const handleRegister = async () => {
    // try {
    loading.value = true
    error.value = null
    let inp = ''
    if (loginType.value === 'email') {
        inp = email.value
    } else {
        inp = phone.value
    }
    const res = await axios
        .post('/api/v1/auth/register', {
            input: inp,
            name: name.value,
            surname: surname.value,
            city_id: city.value?.id,
            invitation: invitation.value,
            utm_source: utm_source.value,
            utm_medium: utm_medium.value,
            utm_campaign: utm_campaign.value
        })
        .catch((err) => {
            loading.value = false
            error.value = err?.response?.data?.message ?? 'Что-то пошло не так!'
        })
    console.log('res', res)
    if (res?.data?.success) {
        if (!!res.data.email) {
            activeStep.value = 'enterEmailCode'
            prevStep.value = 'register'
        } else {
            // const phoneNumber = '+1 650-555-1234'
            const phoneNumber = `+${res.data.phone}`
            signInWithPhoneNumber(
                auth.value,
                phoneNumber,
                recaptchaVerifier.value
            ).then((confirmationResult) => {
                phoneConfirmationResult.value = confirmationResult
                activeStep.value = 'enterCode'
                prevStep.value = 'register'
            })
            // .catch((err) => {
            //     console.log('error2: ', err)
            //     error.value = 'Некорректный телефон'
            // })
        }
    }
    loading.value = false
    // else {
    //     setFieldError('input', data?.message ?? 'Что-то пошло не так')
    // }
    // } catch (e) {
    //     setFieldError('input', 'Некорректный email')
    // }
}

const options = reactive({
    postProcess: (val: string) => {
        val = val.replace(/^\+ 8 \(/g, '+ 7 (')
        return val.replace(/^\+ 9 \(/g, '+ 7 (9')
    },
    mask: (value: string) => {
        return value.startsWith('+') || /^\d+$/.test(boundObject.unmasked)
            ? '+ # (###) ### ##-##'
            : '*'
    },
    tokens: {
        '*': { pattern: /\S/, multiple: true }
    },
    eager: true,
    onMaska: (detail: MaskaDetail) =>
        form.setValues({
            input: detail.unmasked
        })
})

const handleEmailInput = (event: any) => {
    email.value = event.target.value
}

const confirmEmailCode = async () => {
    try {
        loading.value = true
        await getDeviceToken()
        let inp = ''
        if (loginType.value === 'email') {
            inp = email.value
        } else {
            inp = phone.value
        }
        const { data } = await axios.post('/login', {
            email: inp,
            password: otp.value,
            device_token: deviceToken.value
        })
        console.log('data', data)
        if (!data.success) {
            error.value = data.message
        } else {
            localStorage.setItem('token', data.token ?? null)
            document.location.reload()
        }
    } catch (e) {
        console.log('Err', e)
    } finally {
        loading.value = false
    }
}

const handlePhoneInput = (number: String, phoneObject: Object) => {
    form.setValues({
        input: phoneObject.number
    })
}

const setLoginType = (type: string): void => {
    error.value = null
    loginType.value = type
}

const prevStep = ref(null)
const stepBack = () => {
    activeStep.value = prevStep.value
    prevStep.value = null
}

defineExpose({
    login,
    register
})
</script>
<template>
    <Dialog :open="isOpen" @close="close" :initialFocus="input">
        <div class="modal is-open">
            <div class="modal__content">
                <div class="modal__bar">
                    <button
                        type="button"
                        class="modal__back"
                        @click="stepBack"
                        v-if="prevStep"
                    >
                        <i class="icon-back"></i>
                    </button>
                    <button type="button" class="modal__close" @click="close">
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="modal__inner">
                    <div class="modal__header">
                        <div class="logo">
                            <img
                                :src="'/img/logo.svg'"
                                alt="lapki.club"
                                class="logo__img"
                            />
                        </div>
                        <div class="modal__headline">
                            {{
                                activeStep === 'register'
                                    ? 'Регистрация'
                                    : 'Вход'
                            }}
                        </div>
                    </div>
                    <div class="modal__body" v-if="activeStep === 'login'">
                        <div class="form">
                            <div
                                class="tab-nav"
                                style="
                                    margin-bottom: 12px;
                                    justify-content: center;
                                "
                            >
                                <button
                                    class="tab-nav__button"
                                    :class="{
                                        'tab-nav__button_active':
                                            loginType === 'email'
                                    }"
                                    @click="setLoginType('email')"
                                    type="button"
                                    data-action="tab-button"
                                    data-tab="email"
                                >
                                    Почта
                                </button>
                                <button
                                    class="tab-nav__button"
                                    :class="{
                                        'tab-nav__button_active':
                                            loginType === 'phone'
                                    }"
                                    @click="setLoginType('phone')"
                                    type="button"
                                    data-action="tab-button"
                                    data-tab="phone"
                                >
                                    Телефон
                                </button>
                            </div>

                            <TextInput
                                name="input"
                                label="Ваш email"
                                v-show="loginType === 'email'"
                            >
                                <input
                                    name="input"
                                    type="text"
                                    ref="input"
                                    class="form-control__control"
                                    :class="{
                                        'is-valued':
                                            !!form.values.input || !!email
                                    }"
                                    v-model="email"
                                    @keydown.enter="onSubmit"
                                    data-action="form-control"
                                    autocomplete="off"
                                    title="Ваш email"
                                />
                            </TextInput>
                            <VueTelInput
                                v-model="phone"
                                defaultCountry="ru"
                                :inputOptions="{
                                    showDialCode: true
                                }"
                                :dropdownOptions="{
                                    showSearchBox: true,
                                    showFlags: true
                                }"
                                @on-input="handlePhoneInput"
                                styleClasses="phone-input"
                                @keydown.enter="onSubmit"
                                v-show="loginType === 'phone'"
                            />
                            <div
                                class="form__alert form__alert_error"
                                v-if="error"
                            >
                                <i class="icon-details"></i> {{ error }}
                            </div>
                            <div id="captcha" style="opacity: 0"></div>
                            <div class="form__group" style="margin-top: 20px">
                                <button
                                    type="submit"
                                    @click="onSubmit"
                                    :disabled="loading"
                                    class="button"
                                >
                                    Отправить код
                                </button>
                            </div>
                        </div>
                        <div class="oauth" style="margin-top: 28px">
                            <Socials />
                        </div>
                        <div
                            class="form-control error"
                            style="display: none"
                            id="social_errors"
                        >
                            <div class="error">
                                Возникла ошибка при попытке входа через соц.
                                сети
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal__body"
                        v-else-if="activeStep === 'register'"
                    >
                        <div class="form">
                            <div id="captcha" style="opacity: 0"></div>
                            <div
                                class="tab-nav"
                                style="
                                    margin-bottom: 12px;
                                    justify-content: center;
                                "
                            >
                                <button
                                    class="tab-nav__button"
                                    :class="{
                                        'tab-nav__button_active':
                                            loginType === 'email'
                                    }"
                                    @click="loginType = 'email'"
                                    type="button"
                                    data-action="tab-button"
                                    data-tab="email"
                                >
                                    Почта
                                </button>
                                <button
                                    class="tab-nav__button"
                                    :class="{
                                        'tab-nav__button_active':
                                            loginType === 'phone'
                                    }"
                                    @click="loginType = 'phone'"
                                    type="button"
                                    data-action="tab-button"
                                    data-tab="phone"
                                >
                                    Телефон
                                </button>
                            </div>

                            <TextInput
                                name="input"
                                label="Ваш email"
                                v-show="loginType === 'email'"
                            >
                                <input
                                    name="input"
                                    type="text"
                                    ref="input"
                                    class="form-control__control"
                                    :class="{
                                        'is-valued':
                                            !!form.values.input || !!email
                                    }"
                                    data-action="form-control"
                                    v-model="email"
                                    autocomplete="off"
                                    title="Ваш email"
                                />
                            </TextInput>
                            <VueTelInput
                                v-model="phone"
                                :inputOptions="{
                                    showDialCode: true
                                }"
                                :dropdownOptions="{
                                    showSearchBox: true,
                                    showFlags: true
                                }"
                                @on-input="handlePhoneInput"
                                styleClasses="phone-input"
                                v-show="loginType === 'phone'"
                            />
                            <TextInput
                                name="name"
                                label="Имя"
                                style="margin-top: 20px"
                                @onInput="handleNameInput"
                            >
                                <template #icon>
                                    <i class="icon-account"></i>
                                </template>
                            </TextInput>
                            <TextInput
                                name="surname"
                                label="Фамилия"
                                @onInput="handleSurnameInput"
                            >
                                <template #icon>
                                    <i class="icon-account"></i>
                                </template>
                            </TextInput>

                            <div class="form__group">
                                <div class="form-control has-icon">
                                    <CityInput
                                        v-model="city"
                                        icon="icon-location"
                                        name="city_id"
                                    />
                                </div>
                            </div>

                            <TextInput
                                name="invitation"
                                label="Приглашение"
                                @onInput="handleInvitationInput"
                            >
                                <input
                                    name="invitation"
                                    type="text"
                                    class="form-control__control"
                                    :class="{
                                        'is-valued': !!invitation
                                    }"
                                    v-model="invitation"
                                    data-action="form-control"
                                    autocomplete="off"
                                    title="Приглашение"
                                />
                                <template #icon>
                                    <i class="icon-events"></i>
                                </template>
                            </TextInput>

                            <div
                                class="form__alert form__alert_error"
                                v-if="error"
                            >
                                <i class="icon-details"></i> {{ error }}
                            </div>

                            <div class="form__footer">
                                <div class="form__group">
                                    <button
                                        type="button"
                                        class="button"
                                        :disabled="loading"
                                        @click="handleRegister"
                                    >
                                        Регистрация
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="oauth" style="margin-top: 28px">
                            <Socials />
                        </div>
                        <div
                            class="form-control error"
                            style="display: none"
                            id="social_errors"
                        >
                            <div class="error">
                                Возникла ошибка при попытке входа через соц.
                                сети
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal__body"
                        v-else-if="activeStep === 'enterCode'"
                    >
                        <div class="form">
                            <TextInput
                                name="password"
                                ref="codeInput"
                                @onInput="handlePasswordInput"
                                @keydown.enter="confirmCode"
                                label="Одноразовый код"
                                :error="error"
                            />
                            <div
                                class="form__alert form__alert_error"
                                v-if="error"
                            >
                                <i class="icon-details"></i> {{ error }}
                            </div>
                            <div class="form__footer">
                                <div class="form__group">
                                    <button
                                        type="submit"
                                        class="button"
                                        :disabled="loading"
                                        @click="confirmCode"
                                    >
                                        Войти
                                    </button>
                                </div>
                                <!--                                <div class="form__group">-->
                                <!--                                    <button-->
                                <!--                                        type="button"-->
                                <!--                                        class="form__link"-->
                                <!--                                    >-->
                                <!--                                        Отправить код еще раз?-->
                                <!--                                    </button>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal__body"
                        v-if="activeStep === 'enterEmailCode'"
                    >
                        <div class="form">
                            <TextInput
                                name="password"
                                ref="codeInput"
                                @keydown.enter="confirmEmailCode"
                                @onInput="handlePasswordInput"
                                label="Одноразовый код"
                            />
                            <div
                                class="form__alert form__alert_error"
                                v-if="error"
                            >
                                <i class="icon-details"></i> {{ error }}
                            </div>
                            <div class="form__footer">
                                <div class="form__group">
                                    <button
                                        type="submit"
                                        class="button"
                                        :disabled="loading"
                                        @click="confirmEmailCode"
                                    >
                                        Войти
                                    </button>
                                </div>
                                <!--                                <div class="form__group">-->
                                <!--                                    <button-->
                                <!--                                        type="button"-->
                                <!--                                        class="form__link"-->
                                <!--                                    >-->
                                <!--                                        Отправить код еще раз?-->
                                <!--                                    </button>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="form__group" style="margin-top: 12px">
                        <button
                            type="button"
                            class="form__link"
                            @click="register"
                            v-if="activeStep === 'login'"
                        >
                            Вы здесь в первый раз?
                        </button>
                        <button
                            type="button"
                            class="form__link"
                            @click="login"
                            v-else-if="activeStep === 'register'"
                        >
                            Уже зарегистрированы?
                        </button>
                    </div>

                    <div class="modal__footer">
                        <div class="modal__text">
                            Используя приложение, <br />
                            я принимаю изложенное в следующих документах
                            <a href="/rules" target="_blank"
                                >Правила пользования</a
                            >,
                            <a href="/privacy" target="_blank"
                                >Политика конфиденциальности</a
                            >
                            LAPKI.CLUB.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>

<style>
.tab-nav__button {
    position: relative;
    display: inline-block;
    background: 0 0;
    outline: 0;
    border: 0;
    padding: 0 0 calc(var(--indent) / 2);
    color: var(--Gray70);
    cursor: pointer;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
}

.tab-nav__button:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid var(--Base0);
    opacity: 0;
}

.tab-nav__button.active,
.tab-nav__button_active {
    color: var(--Base0);
}

.tab-nav__button.active:after,
.tab-nav__button_active:after {
    opacity: 1;
}

.phone-input {
    outline: none;
    box-shadow: none !important;
    border-radius: 6px;
    font-family: var(--font-main);
    font-weight: 500;
    line-height: 1.25rem;
    color: var(--Gray85);
    border: 1px solid var(--Gray20);
    position: relative;
}

.phone-input .vti__dropdown {
    padding: 19px 8px;
    border-radius: 6px 0 0 6px;
    position: unset;
}

.phone-input .vti__input {
    border-radius: 0 6px 6px 0;
    font-weight: 500;
}

.phone-input .vti__dropdown-list {
    top: 100%;
    margin-top: 4px;
    left: 0;
    width: 100%;
    border-radius: 6px;
    z-index: 2;
}

.phone-input .vti__input {
    width: calc(100% - 4px);
    border-radius: 6px;
}

.phone-input.vue-tel-input:focus-within {
    border-color: var(--Base25);
}
</style>
