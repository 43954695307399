<script setup>
import { useNotification } from '@kyvg/vue3-notification'

const props = defineProps({
    url: {
        type: String,
        required: true
    }
})

const { notify } = useNotification()
const copy = async () => {
    try {
        navigator.clipboard.writeText(props.url).then(() => {
            notify({
                type: 'success',
                text: 'Ссылка - приглашение скопирована'
            })
        })
    } catch (e) {
        console.log(e)
    }
}
</script>

<template>
    <slot :copy="copy">
        <button class="navigation__link" @click="copy">Пригласить друга</button>
    </slot>
</template>
