<template>
    <div class="form pet-edit__form">
        <div class="form__group">
            <div class="form-control">
                <imask-input
                    :value="petData.death_date"
                    :mask="Date"
                    @accept:masked="onAcceptDate"
                    :class="{error: v$.petData.death_date.$invalid && v$.petData.death_date.$dirty, 'is-valued': !!petData.death_date}"
                    class="form-control__control"
                />
                <div class="form-control__label">Дата смерти</div>
                <div v-for="error of v$.petData.death_date.$errors"
                     :key="error.$uid" class="error">{{ error.$message }}
                </div>
            </div>
        </div>
        <div class="form__footer">
            <div class="form__controls">
                <button type="button" class="button button_bg_gray" data-action="modal-close">
                    Отмена
                </button>
                <button type="button" class="button button_bg_dark" @click="handleSubmit">
                    Изменить статус
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core"
import {required} from '@/utils/i18n-validators'
import {IMaskComponent} from 'vue-imask'

export default {
    name: "RipPet",

    setup() {
        return {v$: useVuelidate()}
    },

    validations() {
        return {
            petData: {
                death_date: {required},
            },
        }
    },

    props: {
        pet: {
            required: false
        }
    },

    data() {
        return {
            petData: JSON.parse(this.pet) ?? null,
            vuelidateExternalResults: {
                petData: {
                    death_date: [],
                }
            },
        }
    },

    methods: {
        async handleSubmit() {
            this.v$.$clearExternalResults()
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return
            }
            try {
                const {data} = await axios.post(`/api/v1/pets/${this.petData.id}/died`, {
                    death_date: this.petData.death_date
                })
                console.log('data', data)
                if (data.success) {
                    document.location.reload()
                }
            } catch (err) {
                this.vuelidateExternalResults.petData =  err.response?.data?.errors
            }
        },
        onAcceptDate(val) {
            this.petData.death_date = val
        }
    },

    components: {
        'imask-input': IMaskComponent
    },
}
</script>
