<template>
    <div>
        <button :class="props.class"
                v-cloak
                type="button"
                @click="handleClick">
            <slot>
                Присоединиться
            </slot>
        </button>
    </div>
</template>

<script setup>
import useEventsBus from '@/utils/eventBus';

const props = defineProps({
    class: {
        default: 'button button_size_sm button_rounded'
    },
})

const { emit } = useEventsBus()
const handleClick = () => {
    emit('showModalRegister')
}
</script>
