<template>
    <Step1 v-if="store.step === 1 && isLoaded" />
    <Step2 v-if="store.step === 2 && isLoaded" />
    <Step3 v-if="store.step === 3 && isLoaded" />
    <Step4 v-if="store.step === 4 && isLoaded" />
    <Step5 v-if="store.step === 5 && isLoaded" />
    <Step6 v-if="store.step === 6 && isLoaded" />
    <Step7 v-if="store.step === 7 && isLoaded" />
    <Step8 v-if="store.step === 8 && isLoaded" />

    <ModalsContainer />
</template>

<script setup lang="ts">
import { useBusinessStore } from '../../stores/BusinessStore'
import { onMounted, ref } from 'vue'
import Step1 from '../../components/Business/steps/Step1.vue'
import Step2 from '../../components/Business/steps/Step2.vue'
import Step3 from '../../components/Business/steps/Step3.vue'
import Step4 from '../../components/Business/steps/Step4.vue'
import Step5 from '../../components/Business/steps/Step5.vue'
import Step6 from '../../components/Business/steps/Step6.vue'
import Step7 from '../../components/Business/steps/Step7.vue'
import Step8 from '../../components/Business/steps/Step8.vue'
import { ModalsContainer } from 'vue-final-modal'

const props = defineProps({
    authUser: {
        required: true
    },
    business: {
        required: true
    }
})

const store = useBusinessStore()

const isLoaded = ref(false)
onMounted(async () => {
    await store.setInitialValues(props.business)
    isLoaded.value = true
})
</script>
