<template>
    <div class="form pet-edit__form">
        <div class="form__group pet-edit__sex">
            <div class="sex-select">
                <div class="sex-select__title">Пол</div>
                <div class="sex-select__list">
                    <label class="sex-select__label" style="cursor: default">
                                    <span :class="`sex-select__mark sex-select__mark_${petData.sex}`">
                                        <i :class="`icon-sex_${petData.sex}`"></i>
                                    </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form__group">
            <div class="form-control">
                <input type="text" class="form-control__control" data-action="form-control"
                       autocomplete="off" title="Введите Имя"
                       :class="{error: v$.petData.nickname.$invalid && v$.petData.nickname.$dirty, 'is-valued': !!petData.nickname}"
                       v-model="petData.nickname"/>
                <div class="form-control__label">Кличка</div>
                <div v-for="error of v$.petData.nickname.$errors"
                     :key="error.$uid" class="error">{{ error.$message }}
                </div>
            </div>
        </div>

        <div class="form__group" v-if="petData.breed">
            <div class="form-control">
                <input type="text" class="form-control__control is-valued" disabled
                       data-action="form-control"
                       autocomplete="off" :value="petData.breed.title_ru">
                <div class="form-control__label">Порода</div>
            </div>
        </div>
        <div class="form__group" v-if="petData.birth_date">
            <div class="form-control">
                <input type="text" class="form-control__control is-valued" data-action="form-control"
                       autocomplete="off" :value="petData.birth_date"
                       disabled>
                <div class="form-control__label">Дата рождения</div>
            </div>
        </div>
        <div class="form__group pet-edit__about-pet">
            <div class="form-control">
                                        <textarea class="form-control__control"
                                                  :class="{error: v$.petData.about.$invalid && v$.petData.about.$dirty, 'is-valued': !!petData.about}"
                                                  data-action="form-control"
                                                  name="about" v-model="petData.about"></textarea>
                <div class="form-control__label">О питомце</div>
            </div>
        </div>
        <div class="form__footer">
            <div class="form__group">
                <button type="button" class="button" @click="handleSubmit">Сохранить</button>
            </div>
        </div>
        <div class="form__footer-sub">
            <button type="button" class="button-link" data-action="modal-open"
                    data-target="#modal-pet-rainbow" v-if="petData.status === 'active'">
                <i class="icon-rip"></i>Ушел на радугу?
            </button>
            <delete-pet :deleting-desired-id="petData.login" id="modal-delete-pet-from-edit" @success="handleDeletePet">
                <template #button="{show}">
                    <button type="button" class="button-link" @click="show">
                        <i class="icon-trash"></i>Удалить питомца
                    </button>
                </template>
            </delete-pet>
        </div>
    </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {maxLength, required} from '@/utils/i18n-validators'
import DeletePet from "@/components/Pets/DeletePet.vue";

export default {
    name: "EditPet",

    setup() {
        return {v$: useVuelidate()}
    },

    components: {
        DeletePet
    },

    validations() {
        return {
            petData: {
                nickname: {required},
                about: {maxLength: maxLength(1000)},
            },
        }
    },

    props: {
        pet: {
            required: false
        },
        previousUrl: {
            required: true
        }
    },

    data() {
        return {
            petData: JSON.parse(this.pet) ?? null,
            vuelidateExternalResults: {
                petData: {
                    nickname: [],
                    about: [],
                }
            },
        }
    },

    methods: {
        async handleSubmit() {
            this.v$.$clearExternalResults()
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return
            }
            try {
                const {data} = await axios.post(`/api/v1/pets/${this.petData.id}/update`, {
                    nickname: this.petData.nickname,
                    about: this.petData.about,
                })
                if (data.success) {
                    document.location.reload()
                }
            } catch (err) {
                this.vuelidateExternalResults.petData = err.response?.data?.errors
            }
        },
        handleDeletePet() {
            document.location.href = this.previousUrl
        }
    }
}
</script>
