<template>
    <div class="form__group">
        <div class="form-control" v-if="!loading">
            <div class="svg" v-if="props.icon" v-html="props.icon"></div>
            <input
                :name="props.name"
                type="text"
                ref="input"
                v-if="!props.loading"
                class="form-control__control"
                :class="{
                    'is-valued': !!props.modelValue,
                    error: props.errorMessage
                }"
                @input="emit('update:modelValue', $event.target.value)"
                :value="props.modelValue"
                data-action="form-control"
                autocomplete="off"
                :title="props.label"
            />
            <Skeletor v-else class="rounded" height="60" as="div"></Skeletor>
            <slot name="before-label"></slot>
            <div class="form-control__label">{{ props.label }}</div>
            <div
                class="form__alert form__alert_error"
                v-if="props.errorMessage"
            >
                <i class="icon-details"></i> {{ props.errorMessage }}
            </div>
            <div
                class="form__extra-text"
                v-if="props.extraText"
                v-html="props.extraText"
            ></div>
        </div>
        <Skeletor v-else class="rounded" height="60" as="div"></Skeletor>
    </div>
</template>

<script setup lang="ts">
import { Skeletor } from 'vue-skeletor'
import { toRef } from 'vue'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String
    },
    icon: {
        type: String,
        required: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    extraText: {
        type: String
    },
    modelValue: {
        type: [String, Number]
    },
    errorMessage: {
        type: String
    }
})
const loading = toRef(props, 'loading')

const emit = defineEmits(['update:modelValue'])
</script>
