<template>
    <ModalsContainer />
    <div v-if="!loading && business && business.id" class="organization">
        <div class="org-sticky">
            <div class="org-sticky__info">
                <div class="org-sticky__pic">
                    <img
                        :src="getAvatar(business.avatar)"
                        :alt="business.name"
                        class="org-sticky__img"
                    />
                </div>
                <div class="org-sticky__name">{{ business.name }}</div>
            </div>
            <div class="org-contacts">
                <div class="org-contacts__list">
                    <div class="org-contacts__item" v-if="business.has_office">
                        <a
                            href="#map"
                            @click="scrollToMap"
                            class="org-contacts__contact"
                        >
                            <i class="icon-location"></i>
                            <span v-if="business.office_address">
                                {{ business.office_address }}
                            </span>
                        </a>
                    </div>
                    <div class="org-contacts__item" v-else>
                        <div class="org-contacts__contact">
                            <i class="icon-location"></i>
                            <span v-if="business.city">
                                {{ business.city.name }}
                            </span>
                        </div>
                    </div>
                    <div class="org-contacts__item">
                        <button
                            type="button"
                            class="org-contacts__contact"
                            data-action="dropdown-toggle"
                        >
                            <i class="icon-time"></i>
                            <span>{{ business.working_hours }}</span>
                        </button>
                        <div class="dropdown-drop" data-action="dropdown-drop">
                            <div
                                class="org-contacts__contact"
                                v-for="item in business.working_hours"
                            >
                                <i class="icon-time"></i>
                                <span>{{ getWorkingPeriod(item) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                    <button type="button" class="button button_rounded">Записаться</button>-->
            </div>
        </div>
        <div class="organization__info">
            <div class="org-info">
                <div class="org-info__pic">
                    <img
                        :src="getAvatar(business.avatar)"
                        :alt="business.name"
                        class="org-info__img"
                    />
                </div>
                <div class="org-info__main">
                    <div class="org-info__headline">{{ business.name }}</div>
                    <div class="org-info__tags">
                        <div class="tags">
                            <span
                                class="tags__item"
                                v-for="service_type in business.service_types"
                            >
                                {{ service_type.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="org-info__footer">
                    <div class="org-contacts">
                        <div class="org-contacts__list">
                            <div
                                class="org-contacts__item"
                                v-if="business.has_office"
                            >
                                <a
                                    href="#map"
                                    @click="scrollToMap"
                                    class="org-contacts__contact"
                                >
                                    <i class="icon-location"></i>
                                    <span v-if="business.office_address">
                                        {{ business.office_address }}
                                    </span>
                                </a>
                            </div>
                            <div class="org-contacts__item" v-else>
                                <div class="org-contacts__contact">
                                    <i class="icon-location"></i>
                                    <span v-if="business.city">
                                        {{ business.city.name }}
                                    </span>
                                </div>
                            </div>
                            <div class="org-contacts__item">
                                <button
                                    type="button"
                                    class="org-contacts__contact"
                                    data-action="dropdown-toggle"
                                >
                                    <i class="icon-time"></i>
                                    <span>{{ business.opening }}</span>
                                </button>
                                <div
                                    class="dropdown-drop"
                                    data-action="dropdown-drop"
                                >
                                    <div
                                        class="org-contacts__contact"
                                        v-for="item in business.working_hours"
                                    >
                                        <i class="icon-time"></i>
                                        <span>{{
                                            getWorkingPeriod(item)
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            class="button button_rounded"
                            @click="showModalOrder"
                            v-if="authUser && authUser.id !== business.user_id"
                        >
                            Заказать услугу
                        </button>
                        <button
                            class="button button_rounded"
                            @click="showModalYourBusiness"
                            v-else-if="
                                authUser && authUser.id === business.user_id
                            "
                        >
                            Заказать услугу
                        </button>
                        <ModalRegisterButton
                            class="button button_rounded"
                            v-else
                        >
                            Заказать услугу
                        </ModalRegisterButton>
                    </div>
                </div>
            </div>
        </div>

        <div class="organization__gallery">
            <div class="org-gallery">
                <div class="org-gallery__container" data-orgswipper>
                    <Gallery
                        class="org-gallery__wrapper"
                        :photos="business.photos"
                        :video="business.video"
                    />
                </div>
            </div>
        </div>

        <div class="organization__section">
            <div class="organization__text" v-html="business.description"></div>
        </div>
        <div class="organization__section">
            <div class="organization__title">Услуги</div>
            <div class="services">
                <div
                    type="button"
                    class="services__item"
                    v-for="service in business.services"
                >
                    <div
                        class="services__icon"
                        v-html="service.icon"
                        v-if="service.icon"
                    ></div>
                    <div class="services__main">
                        <div class="services__name">{{ service.name }}</div>
                        <div class="services__price">
                            от {{ service.price_from }}{{ currencyIcon }}
                        </div>
                        <div
                            class="services__info"
                            :data-tippy-content="service.description"
                            v-if="service.description"
                        >
                            <i class="icon-details"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="organization__section" id="map" v-if="business.has_office">
            <div class="organization__title">Расположение</div>
            <div class="place">
                <button
                    @click="scrollToCenter"
                    type="button"
                    class="place__address"
                >
                    <i class="icon-location"></i>
                    <span v-if="business.office_address">
                        {{ business.office_address }}
                    </span>
                </button>
                <MapWithMarkers
                    ref="map"
                    :markers="[business.coordinates]"
                    wrapper-class="place__map"
                />
            </div>
        </div>
        <div class="organization__section">
            <div class="form__flex">
                <div class="organization__title">Блог</div>
                <button
                    type="button"
                    class="button button_size_sm button_rounded"
                    @click="toggleSubscribe"
                >
                    {{ business.subscribed ? 'Отписаться' : 'Подписаться' }}
                </button>
            </div>
            <PostsList
                :url="`/api/v1/business/${id}/blog`"
                :auth-user-id="authUser?.id"
                :user-id="authUser?.id"
            >
                <template #complete-img>
                    <img
                        :src="'/img/special-cat3.svg'"
                        class="specialty-item__img"
                        alt=""
                    />
                </template>
                <template #complete-text> Пока здесь пусто... </template>
            </PostsList>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { $vfm, ModalsContainer } from 'vue-final-modal'
import MapWithMarkers from '../Map/MapWithMarkers.vue'
import Gallery from './Gallery.vue'
import ModalOrder from './ModalOrder.vue'
import ModalRegisterButton from '../Auth/ModalRegisterButton.vue'
import ModalYourBusiness from './ModalYourBusiness.vue'
import PostsList from '@/components/Posts/PostsList.vue'
import { notify } from '@kyvg/vue3-notification'

const business = ref(null)
const loading = ref(true)

const props = defineProps({
    id: {
        required: true
    },
    authUser: {
        required: false,
        default: null
    }
})

const scrollToMap = () => {
    document.getElementById('map').scrollIntoView({
        behavior: 'smooth'
    })
}

const parseWorkingTime = (item) => {
    let hours = Math.trunc(item / 2)
    let minutes = item % 2 === 0 ? '00' : '30'
    return `${hours}:${minutes}`
}

const parseDay = (item) => {
    switch (item) {
        case 0:
            return 'Вс'
        case 1:
            return 'Пн'
        case 2:
            return 'Вт'
        case 3:
            return 'Ср'
        case 4:
            return 'Чт'
        case 5:
            return 'Пт'
        case 6:
            return 'Сб'
    }
}

const getMaxWorkingTime = computed(() => {
    if (!business.value.working_hours) {
        return ''
    }
    let periods = business.value.working_hours.map((item) => {
        return item.time[1]
    })
    return parseWorkingTime(_.max(periods))
})

function getWorkingPeriod(period) {
    let days = period.days.map((day) => parseDay(day))

    let time_from = parseWorkingTime(period.time[0])
    let time_to = parseWorkingTime(period.time[1])
    return `${days.join(', ')}: ${time_from} - ${time_to}`
}

const fetch = async () => {
    try {
        loading.value = true
        const { data } = await axios.get(`/api/v1/business/${props.id}`)
        business.value = data?.data ?? null
        if (!business.value?.id) {
            throw new Error('Business not found')
        }
        loading.value = false
    } catch (error) {
        console.log('error', error)
        // if (error.response?.status === 404) {
        //     document.location.href = '/404'
        // }
    }
}

const map = ref(null)
const scrollToCenter = () => {
    map.value.scrollToCenter()
}

onMounted(fetch)

const showModalOrder = () => {
    $vfm.show({
        component: ModalOrder,
        bind: {
            phone: business.value.phone,
            business_id: business.value.id
        }
    })
}

const showModalYourBusiness = () => {
    $vfm.show({
        component: ModalYourBusiness,
        bind: {
            phone: business.value.phone,
            id: business.value.id
        }
    })
}

const currencyIcon = computed(() => {
    switch (business.value.currency_id) {
        case 'EUR':
            return '€'
        case 'KZT':
            return '₸'
        default:
            return '₽'
    }
})

const getAvatar = (avatar = null) => {
    if (!avatar || avatar === '') {
        return '/img/organization-empty-logo.svg'
    }
    return avatar
}

const toggleSubscribe = async () => {
    const res = await axios.post(
        `/api/v1/business/${props.id}/toggle-blog-subscription`
    )
    if (res?.data?.status === 'success') {
        notify({
            type: 'success',
            text:
                res?.data?.result === 'attached'
                    ? 'Подписка на блог бизнеса оформлена'
                    : 'Вы отписались от подписки на блог бизнеса'
        })
    }
    business.value.subscribed = res?.data?.result === 'attached'
}
</script>
