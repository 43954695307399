<template>
    <Layout @nextStep="handleSubmit" @onExit="handleExit">
        <div class="constructor-gallery">
            <div class="constructor-gallery__list">
                <div
                    class="constructor-gallery__item"
                    v-for="(image, i) in v$.images.$model"
                    :key="image.id ?? image"
                >
                    <template v-if="!store.loading">
                        <img
                            :src="image.url"
                            alt=""
                            class="constructor-gallery__img"
                        />
                        <button
                            type="button"
                            class="constructor-gallery__remove"
                            @click="handleDelete(i)"
                        >
                            <i class="icon-close"></i>
                        </button>
                    </template>
                    <Skeletor height="100%" class="constructor-gallery__item" />
                </div>
            </div>
            <div class="constructor-gallery__controls">
                <div class="constructor-gallery__control">
                    <label
                        class="constructor-gallery__button"
                        v-if="!store.loading"
                    >
                        <input
                            type="file"
                            multiple
                            @change="handleInput"
                            accept="image/png, image/jpeg"
                        />
                        <span class="constructor-gallery__icon">
                            <i class="icon-camera"></i>
                        </span>
                        <span class="constructor-gallery__label"
                            >Добавить фото</span
                        >
                        <span class="constructor-gallery__text"
                            >Добавьте качественные фото описывающие ваш бизнес
                            или услуги которые вы оказываете. Необходимо
                            загрузить не менее 4 фотографий. Изображения должны
                            быть размером до 5mb в форматах .jpg и .png.</span
                        >
                        <span
                            class="form__alert form__alert_error"
                            v-if="v$.images.$errors[0]?.$message"
                        >
                            <i class="icon-details"></i>
                            {{ v$.images.$errors[0]?.$message }}
                        </span>
                    </label>
                    <div class="constructor-gallery__button" v-else>
                        <span
                            class="constructor-gallery__icon"
                            style="background-color: #fff"
                        >
                            <Skeletor circle height="36px" width="36px" />
                        </span>
                        <span class="constructor-gallery__label"
                            ><Skeletor height="21px" width="200px" pill
                        /></span>

                        <span class="constructor-gallery__text"
                            ><Skeletor height="18px" width="400"
                        /></span>
                    </div>
                </div>
                <div class="constructor-gallery__control">
                    <Modal ref="modal">
                        <template #button="{ show }">
                            <button
                                v-if="!store.loading"
                                type="button"
                                @click="show"
                                class="constructor-gallery__button"
                            >
                                <span class="constructor-gallery__icon">
                                    <i class="icon-video"></i>
                                </span>
                                <span class="constructor-gallery__label">{{
                                    v$.video.$model ?? 'Добавить видео'
                                }}</span>
                                <span class="constructor-gallery__text"
                                    >Видео будет открыватьсь по клику на первое
                                    фото.</span
                                >
                                <button
                                    type="button"
                                    class="constructor-gallery__delete"
                                    v-if="v$.video.$model"
                                    @click.prevent.stop="clearVideo"
                                >
                                    <i class="icon-close"></i>
                                </button>
                                <span
                                    class="form__alert form__alert_error"
                                    v-if="v$.video.$errors[0]?.$message"
                                >
                                    <i class="icon-details"></i>
                                    {{ v$.video.$errors[0]?.$message }}
                                </span>
                            </button>
                            <div class="constructor-gallery__button" v-else>
                                <span
                                    class="constructor-gallery__icon"
                                    style="background-color: #fff"
                                >
                                    <Skeletor
                                        circle
                                        height="36px"
                                        width="36px"
                                    />
                                </span>
                                <span class="constructor-gallery__label"
                                    ><Skeletor height="21px" width="200px" pill
                                /></span>

                                <span class="constructor-gallery__text"
                                    ><Skeletor height="18px" width="400"
                                /></span>
                            </div>
                        </template>
                        <template #default="{ close }">
                            <div class="form">
                                <TextInput
                                    label="Адрес видео"
                                    v-model="videoState"
                                    @input="handleResetErrors"
                                    :errorMessage="
                                        v$.video.$errors[0]?.$message
                                    "
                                />
                                <div class="form__group">
                                    <div class="form__extra-text">
                                        Вставьте ссылку на ролик Youtube,
                                        Rutube, Yandex Efir
                                    </div>
                                </div>
                                <div class="form__group">
                                    <div class="form__controls">
                                        <button
                                            :disabled="
                                                v$.video.$errors.length ||
                                                !videoState
                                            "
                                            type="button"
                                            @click="handleUpdateVideo"
                                            class="button button_size_sm"
                                        >
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Modal>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script setup lang="ts">
import Layout from '../../../layouts/business/Layout.vue'
import { onMounted, ref } from 'vue'
import { requiredLocalized } from '../../../rules/requiredLocalized'
import { helpers, maxLength, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { useBusinessStore } from '../../../stores/BusinessStore'
import { forEach } from 'lodash'
import Modal from '../../Modal.vue'
import TextInput from '../inputs/TextInput.vue'
import { maxLengthLocalized } from '../../../rules/maxLengthLocalized'
import { $vfm } from 'vue-final-modal'
import { Skeletor } from 'vue-skeletor'

const store = useBusinessStore()

const images = ref(store.initialValues?.photos)
const videoState = ref(store.initialValues?.video)
const video = ref(store.initialValues?.video)

const rules = {
    images: {
        requiredLocalized: helpers.withMessage(
            'Нужно загрузить фотографии',
            requiredLocalized
        ),
        minLength: helpers.withMessage(
            `Загрузите не менее 4 фотографий`,
            minLength(4)
        ),
        maxLength: helpers.withMessage(
            `Загрузите не более 10 фотографий`,
            maxLength(10)
        )
    },
    video: {
        maxLengthLocalized: maxLengthLocalized(500)
    }
}

const $externalResults = ref({})
const v$ = useVuelidate(
    rules,
    { images, video },
    { $externalResults, $scope: false }
)

const handleInput = (event) => {
    v$.value.$clearExternalResults()
    const files = event.target.files
    let i = 1
    for (const file of files) {
        console.log('file', file)
        images.value.push({
            id: 1,
            url: URL.createObjectURL(file),
            file: new File([file], file.name, {
                type: file.type
            })
        })
        i++
    }
    v$.value.images.$touch()
}

const handleDelete = (index) => {
    images.value.splice(index, 1)
    v$.value.images.$touch()
}

const handleSubmit = async () => {
    if (!v$.value.$anyDirty && store.completedSteps.includes(4)) {
        store.nextStep()
        return
    }
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        return
    }

    store.startLoading()

    const formData = new FormData()
    formData.append('step', '4')
    for (const image of images.value) {
        if (image.file) {
            formData.append('photos[]', image.file)
        } else {
            formData.append('alreadyLoaded[]', image.id)
        }
    }
    if (video.value) {
        formData.append('video', video.value)
    }
    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/${store.initialValues.id}`,
            formData
        )
        store.setInitialValues(data, 5)
    } catch (error) {
        const errors = error.response.data.errors
        if (!errors) {
            notify({
                type: 'error',
                text: error.message
            })
            return
        }
        for (const key of Object.keys(errors)) {
            if (key.includes('photos.')) {
                errors.images = errors[key]
            }
        }
        $externalResults.value = errors
    } finally {
        store.stopLoading()
    }
}
const handleExit = async () => {
    if (!v$.value.$anyDirty) {
        document.location.href = '/profile'
        return
    }

    const formData = new FormData()
    formData.append('step', '4')
    for (const image of images.value) {
        formData.append('photos[]', image.file)
    }
    if (video.value) {
        formData.append('video', video.value)
    }
    try {
        const {
            data: { data }
        } = await axios.post(
            `/api/v1/business/save/${store.initialValues.id}`,
            formData
        )
        document.location.href = '/profile'
        return
    } catch (error) {
        const errors = error.response.data.errors
        console.log('ddd', errors)
        let text = '<ul style="padding: 0;margin: 0;">'
        for (const key of Object.keys(errors)) {
            text += `<li>${errors[key][0]}</li>`
            console.log('errr', key)
        }
        text += '<\/ul>'
        $externalResults.value = errors
        notify({
            type: 'error',
            text
        })
        return
    }
}

const modal = ref()
const handleUpdateVideo = async () => {
    v$.value.$clearExternalResults
    video.value = videoState.value
    v$.value.video.$touch()
    console.log('$invalid', v$.value.video.$invalid)
    if (!v$.value.video.$invalid) {
        await $vfm.hideAll()
    }
}
const clearVideo = () => {
    video.value = null
    videoState.value = null
    v$.value.$clearExternalResults
    v$.value.video.$reset()
    v$.value.video.$touch()
}

const handleResetErrors = () => {
    v$.value.$clearExternalResults
    v$.value.video.$reset()
}
</script>
