import {createI18n} from 'vue-i18n'

const messages = {
    ru: {
        validations: {
            required: 'Поле "{_field_}" обязательно для заполнения',
            requiredIf: 'Поле "{_field_}" обязательно для заполнения',
            email: 'Должно быть действительным электронным адресом',
            min: 'Поле "{_field_}" должно быть больше "{min}"',
            max: 'Поле "{_field_}" должно быть меньше "{max}"',
            minLength: 'Поле "{_field_}" должно быть длинее "{min}" знаков',
            maxLength: 'Поле "{_field_}" должно быть короче "{max}" знаков',
        },
        fields: {
            title: 'Название',
            email: 'Email',
            phone: 'Телефон',
            name: 'Имя',
            surname: 'Фамилия',
            city_id: 'Город',
            city: 'Город',
            nickname: 'Кличка',
            password: 'Пароль',
            birth_date: 'Дата рождения',
            death_date: 'Дата смерти',
            sex: 'Пол',
            year: 'Год',
            event: 'Мероприятие',
            breed: 'Порода',
            about_me: 'Обо мне',
            achievement: 'Достижение',
        }
    }
}

export const i18n = createI18n({
    locale: 'ru',
    messages
})
